import { lazy } from 'react';
import { Outlet, redirect } from 'react-router-dom';
import { pageLoader as GroupSettingsPageLoader } from './group/loader';
import { pageLoader as SubgroupSettingsPageLoader } from './subgroup/loader';
import { pageLoader as ProjectSettingsPageLoader } from './project/loader';
import {
    CrmMaintenanceClientPermissionSet,
    Restricted,
} from '../../../../features';

const ClientSettingsPage = lazy(() => import('./client-settings-page'));
const ClientStatusPage = lazy(() =>
    import('./client-status/client-status-page')
);
const PrefixPage = lazy(() => import('./prefix/prefix-page'));
const MaritalStatePage = lazy(() =>
    import('./marital-state/marital-state-page')
);
const RelationTypePage = lazy(() =>
    import('./relation-type/relation-type-page')
);
const LanguagePage = lazy(() => import('./language/language-page'));
const InsurerPage = lazy(() => import('./insurer/insurer-page'));
const InsuranceClassPage = lazy(() =>
    import('./insurance-class/insurance-class-page')
);
const ContractEndingReasonPage = lazy(() =>
    import('./contract-ending-reason/contract-ending-reason-page')
);
const AuthorizationStatusPage = lazy(() =>
    import('./authorization-status/authorization-status-page')
);
const GroupPage = lazy(() => import('./group/group-page'));

const GroupSettingsPage = lazy(() => import('./group/group-settings-page'));
const SubgroupPage = lazy(() => import('./subgroup/subgroup-page'));
const SubscriptionPage = lazy(() => import('./subscription/subscription-page'));

const SubgroupSettingsPage = lazy(() =>
    import('./subgroup/subgroup-settings-page')
);
const ProjectPage = lazy(() => import('./project/project-page'));
const ProjectSettingsPage = lazy(() =>
    import('./project/project-settings-page')
);
const SubprojectPage = lazy(() => import('./subproject/subproject-page'));
const DocumentsPage = lazy(() => import('./documents/documents-page'));

export const ClientSettingsRoutes = {
    path: 'client',
    element: (
        <Restricted to={CrmMaintenanceClientPermissionSet} redirect={true}>
            <Outlet />
        </Restricted>
    ),
    handle: {
        crumb: (t) => t('eba2452fffd27579f7533d27521bf481', 'Client settings'),
        documentTitleParent: true,
    },
    children: [
        {
            path: '',
            element: <ClientSettingsPage />,
            children: [
                {
                    index: true,
                    loader: () => redirect('status'),
                },
                {
                    path: 'status',
                    element: <ClientStatusPage />,
                    handle: {
                        crumb: (t) =>
                            t('9acb44549b41563697bb490144ec6258', 'Status'),
                    },
                },
                {
                    path: 'prefix',
                    element: <PrefixPage />,
                    handle: {
                        crumb: (t) =>
                            t('851f5ac9941d720844d143ed9cfcf60a', 'Prefix'),
                    },
                },
                {
                    path: 'marital-state',
                    element: <MaritalStatePage />,
                    handle: {
                        crumb: (t) =>
                            t(
                                '093c346f612429cdfa02b440d89e7a9b',
                                'Marital state'
                            ),
                    },
                },
                {
                    path: 'relation-type',
                    element: <RelationTypePage />,
                    handle: {
                        crumb: (t) =>
                            t(
                                '3a7434581e964a77c33fa7ae8931bc17',
                                'Relation type'
                            ),
                    },
                },
                {
                    path: 'language',
                    element: <LanguagePage />,
                    handle: {
                        crumb: (t) =>
                            t('8512ae7d57b1396273f76fe6ed341a23', 'Language'),
                    },
                },
                {
                    path: 'insurer',
                    element: <InsurerPage />,
                    handle: {
                        crumb: (t) =>
                            t('3020e0124c47341ca13164db169134f3', 'Insurer'),
                    },
                },
                {
                    path: 'insurance-class',
                    element: <InsuranceClassPage />,
                    handle: {
                        crumb: (t) =>
                            t(
                                'e63b7862b731ed6380ad1958796f0220',
                                'Insurance class'
                            ),
                    },
                },
                {
                    path: 'contract-ending-reason',
                    element: <ContractEndingReasonPage />,
                    handle: {
                        crumb: (t) =>
                            t(
                                '9af967f3050f41ff2cffd8536b87f8d2',
                                'Contract ending reason'
                            ),
                    },
                },
                {
                    path: 'authorization-status',
                    element: <AuthorizationStatusPage />,
                    handle: {
                        crumb: (t) =>
                            t(
                                'f0cdea2ba4f05bfa47022eed0bfb8051',
                                'Authorization status'
                            ),
                    },
                },
                {
                    path: 'group',
                    element: <GroupPage />,
                    handle: {
                        crumb: (t) =>
                            t('db0f6f37ebeb6ea09489124345af2a45', 'Group'),
                    },
                },
            ],
        },
        {
            path: 'group',
            handle: {
                crumb: (t) => t('db0f6f37ebeb6ea09489124345af2a45', 'Group'),
            },
            children: [
                {
                    path: ':groupId',
                    element: <GroupSettingsPage />,
                    loader: GroupSettingsPageLoader,
                    handle: {
                        crumb: (_t, data) => data.name,
                    },
                    children: [
                        {
                            index: true,
                            loader: () => redirect('subgroup'),
                        },
                        {
                            path: 'subgroup',
                            element: <SubgroupPage />,
                            handle: {
                                crumb: (t) =>
                                    t(
                                        'bf9547964497ba69f64f80f12f3b2439',
                                        'Subgroups'
                                    ),
                            },
                        },
                        {
                            path: 'subscription',
                            element: <SubscriptionPage />,
                            handle: {
                                crumb: (t) =>
                                    t(
                                        '2d5d14f95af035cbd8437948de61f94c',
                                        'Subscriptions'
                                    ),
                            },
                        },
                        {
                            path: 'documents',
                            element: <DocumentsPage />,
                            loader: GroupSettingsPageLoader,
                            handle: {
                                crumb: (t) =>
                                    t(
                                        '21f64da1e5792c8295b964d159a14491',
                                        'Documents'
                                    ),
                            },
                        },
                    ],
                },
                {
                    path: ':groupId',
                    loader: GroupSettingsPageLoader,
                    handle: {
                        crumb: (_t, data) => data.name,
                    },
                    children: [
                        {
                            path: 'subgroup',
                            handle: {
                                crumb: (t) =>
                                    t(
                                        'ff90800b1b198e93f60b292ace3ffb00',
                                        'Subgroup'
                                    ),
                            },
                            children: [
                                {
                                    path: ':subgroupId',
                                    element: <SubgroupSettingsPage />,
                                    loader: SubgroupSettingsPageLoader,
                                    handle: {
                                        crumb: (_t, data) => data.name,
                                    },
                                    children: [
                                        {
                                            index: true,
                                            loader: () => redirect('project'),
                                        },
                                        {
                                            path: 'project',
                                            element: <ProjectPage />,
                                            handle: {
                                                crumb: (t) =>
                                                    t(
                                                        '35a2864c314e4367eb1684bfe126a967',
                                                        'Projects'
                                                    ),
                                            },
                                        },
                                    ],
                                },
                                {
                                    path: ':subgroupId',
                                    loader: SubgroupSettingsPageLoader,
                                    handle: {
                                        crumb: (_t, data) => data.name,
                                    },
                                    children: [
                                        {
                                            path: 'project',
                                            handle: {
                                                crumb: (t) =>
                                                    t(
                                                        '46f86faa6bbf9ac94a7e459509a20ed0',
                                                        'Project'
                                                    ),
                                            },
                                            children: [
                                                {
                                                    path: ':projectId',
                                                    element: (
                                                        <ProjectSettingsPage />
                                                    ),
                                                    loader: ProjectSettingsPageLoader,
                                                    handle: {
                                                        crumb: (_t, data) =>
                                                            data.name,
                                                    },
                                                    children: [
                                                        {
                                                            index: true,
                                                            loader: () =>
                                                                redirect(
                                                                    'subproject'
                                                                ),
                                                        },
                                                        {
                                                            path: 'subproject',
                                                            element: (
                                                                <SubprojectPage />
                                                            ),
                                                            handle: {
                                                                crumb: (t) =>
                                                                    t(
                                                                        '292885084d52c12f76d931df0326c838',
                                                                        'Subprojects'
                                                                    ),
                                                            },
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
};
