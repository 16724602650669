import './eds-organization-switcher.scss';
import { Dropdown } from '@carbon/react';
import _ from 'lodash';

export function EdsOrganizationSwitcher({ organizations, ...props }) {
    // only render the component when there is an initial selected item, so when the first account has been selected, otherwise the dropdown initial item will be empty
    if (_.isUndefined(props.initialSelectedItem)) {
        return null;
    }

    return (
        <div className="eds-header-organization-switcher">
            <Dropdown
                id="organization-dropdown"
                label=""
                titleText=""
                items={organizations}
                itemToString={(item) => (item ? item.name : '')}
                {...props}
            />
        </div>
    );
}
