import {
    AccountIdApiInterceptor,
    AccountIdSelector,
    AuthProvider,
    ChannelsProvider,
    ModalProvider,
    NotificationsProvider,
    PermissionsProvider,
} from './features';

export const ProviderTree = ({ children }) => {
    return (
        <AuthProvider>
            <AccountIdSelector>
                <AccountIdApiInterceptor>
                    <PermissionsProvider>
                        <NotificationsProvider>
                            <ModalProvider>
                                <ChannelsProvider>{children}</ChannelsProvider>
                            </ModalProvider>
                        </NotificationsProvider>
                    </PermissionsProvider>
                </AccountIdApiInterceptor>
            </AccountIdSelector>
        </AuthProvider>
    );
};
