import { useRef } from 'react';
import { debounce, useNotifications } from '../../../features';
import './eds-notification.scss';
import { ActionableNotification } from '@carbon/react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

export const EdsNotification = ({ notification }) => {
    const { remove } = useNotifications();
    const { t } = useTranslation();
    const closeButtonTimeoutId = useRef(null);
    const actionButtonTimeoutId = useRef(null);

    const removeNotification = () => {
        remove(notification.id);
    };

    delete notification.ttl;

    if (notification.inline) {
        return (
            <ActionableNotification
                className="eds-notification inline"
                aria-label={t(
                    '877428730ebf153ae8ff52d3de97bd23',
                    'Closes notification'
                )}
                statusIconDescription={t(
                    '0cfd653d5d3e1e9fdbb644523d77971d',
                    'Notification'
                )}
                kind={notification.kind ?? 'info'}
                lowContrast={true}
                title={notification.title}
                hideCloseButton={notification.hideCloseButton ?? false}
                onClose={() => {
                    removeNotification();
                    if (_.isFunction(notification?.onClose)) {
                        notification.onClose();
                    }
                }}
                onCloseButtonClick={() => {
                    if (_.isFunction(notification?.onCloseButtonClick)) {
                        debounce(
                            closeButtonTimeoutId,
                            notification.onCloseButtonClick,
                            {
                                params: [removeNotification],
                            }
                        );
                    }
                }}
            >
                <div className="inline-content-wrapper">
                    <div className="inline-content">{notification.content}</div>

                    {notification.inlineaction && (
                        <div className="inline-action-button">
                            {notification.inlineaction}
                        </div>
                    )}
                </div>
            </ActionableNotification>
        );
    }

    return (
        <ActionableNotification
            className="eds-notification"
            aria-label={t(
                '877428730ebf153ae8ff52d3de97bd23',
                'Closes notification'
            )}
            statusIconDescription={t(
                '0cfd653d5d3e1e9fdbb644523d77971d',
                'Notification'
            )}
            lowContrast={true}
            {...notification}
            onClose={() => {
                removeNotification();
                if (_.isFunction(notification?.onClose)) {
                    notification.onClose();
                }
            }}
            onCloseButtonClick={() => {
                if (_.isFunction(notification?.onCloseButtonClick)) {
                    debounce(
                        closeButtonTimeoutId,
                        notification.onCloseButtonClick,
                        {
                            params: [removeNotification],
                        }
                    );
                }
            }}
            onActionButtonClick={() => {
                if (_.isFunction(notification?.onActionButtonClick)) {
                    debounce(
                        actionButtonTimeoutId,
                        notification.onActionButtonClick,
                        {
                            params: [removeNotification],
                        }
                    );
                }
            }}
        >
            {notification.content}
        </ActionableNotification>
    );
};
