import { createSearchFilterStoreFunctions, getRequest } from '../api';

const basePath = '/alarms';

export const getAlarmSearch = async (search) => {
    return getRequest(`${basePath}/search`, search);
};

export const getAlarm = async (id) => {
    return getRequest(`${basePath}/${id}`);
};

export const getAlarmActions = async (id) => {
    return getRequest(`${basePath}/${id}/alarmActions`);
};
export const getAlarmDetails = async (id) => {
    return getRequest(`${basePath}/${id}/alarmDetails`);
};

export const getAlarmReasons = async (id) => {
    return getRequest(`${basePath}/${id}/alarmReasons`);
};

export const getAlarmRenderedHelp = async (id) => {
    return getRequest(`${basePath}/${id}/alarmRenderedHelp`);
};

export const getCareRegistrations = async (id) => {
    return getRequest(`${basePath}/${id}/careRegistrations`);
};

export const getAlarmDevicePositions = async (id) => {
    return getRequest(`${basePath}/${id}/alarmDevicePositions`);
};

export const searchFilterStore = {
    ...createSearchFilterStoreFunctions(basePath, 'alarms'),
};
