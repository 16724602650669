import { useTranslation } from 'react-i18next';
import { EdsSortDirection, EdsTable } from '../../../eds';
import _ from 'lodash';
import { getAllMedicalData } from '../../../../features';
import { forwardRef } from 'react';

export const UmoSearchMedicalDataTable = forwardRef(({ ...props }, ref) => {
    const { t } = useTranslation();

    const getDataCallback = async () => {
        let medicalData = await getAllMedicalData();

        return await medicalData.filter((item) => {
            return additionalDataFilter(item);
        });
    };

    const additionalDataFilter = (item) => {
        return (
            !_.isFunction(props?.additionalDataFilterCallback) ||
            props?.additionalDataFilterCallback(item)
        );
    };

    const mappingCallback = (data) => {
        return {
            id: data.id,
            category: data.medicalDataCategory.name,
            name: data.name,
            medicalPriorityId: data.medicalPriority.id,
            medicalPriority: data.medicalPriority.name,
        };
    };

    const headers = [
        {
            key: 'category',
            header: t('c4ef352f74e502ef5e7bc98e6f4e493d', 'Category'),
            sort: 'category',
            search: 'category',
            isDefaultSort: EdsSortDirection.Ascending,
        },
        {
            key: 'name',
            header: t('b068931cc450442b63f5b3d276ea4297', 'Name'),
            sort: 'name',
            search: 'name',
        },
        {
            key: 'medicalPriority',
            header: t('b988295c268025b49dfb3df26171ddc3', 'Priority'),
            sort: 'medicalPriority',
        },
    ];

    return (
        <EdsTable
            ref={ref}
            searchTooltipKeywords={[
                t('c4ef352f74e502ef5e7bc98e6f4e493d'),
                t('b068931cc450442b63f5b3d276ea4297', 'Name'),
            ]}
            headers={headers}
            searchClientSide={true}
            getDataCallback={getDataCallback}
            mappingCallback={mappingCallback}
            {...props}
        ></EdsTable>
    );
});
UmoSearchMedicalDataTable.displayName = 'UmoSearchMedicalDataTable';
