import { redirect } from 'react-router-dom';
import { getCity } from '../../../../../features';

export const pageLoader = async ({ params }) => {
    try {
        return await getCity(params.cityId);
    } catch (ex) {
        return redirect('..');
    }
};
