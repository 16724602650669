import { Row } from '@carbon/react';
import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';
import { EdsDropdown, EdsForm, EdsFormColumn, EdsFormGroup } from '../../eds';
import { useNotifications } from '../../../features';

const UmoDefaultDeviceForm = forwardRef(
    ({ getDataCallback, postDataCallback, initValues }, ref) => {
        const { t } = useTranslation();
        const { showSuccess, showError } = useNotifications();

        const formDefinition = {
            deviceId: {
                validation: {
                    type: 'dropdown',
                    required: false,
                },
                mapping: 'id',
            },
        };

        const onSubmit = async (_event, form, isValid) => {
            if (!isValid) {
                throw false;
            }

            try {
                const response = await postDataCallback(form.deviceId ?? '');

                showSuccess({
                    title: t('536a056b710e94b16169efd17a4a657b', 'Saved'),
                    content: t(
                        '281aef713c8dc9007cabb1e65448917d',
                        "{{entity}} '{{name}}' is saved",
                        {
                            entity: 'Default device',
                            name: response.defaultDisplayedDeviceCode,
                        }
                    ),
                });

                return response;
            } catch (error) {
                showError({
                    title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                    content: t(
                        'df0a9d46baf7315909e4389a04786e3d',
                        'Oops something went wrong'
                    ),
                });

                throw error;
            }
        };

        return (
            <EdsForm
                hideDefaultSubmit={true}
                initValues={initValues}
                formDefinition={{}}
                onSubmit={onSubmit}
                ref={ref}
            >
                <EdsFormGroup formDefinition={formDefinition}>
                    <Row>
                        <EdsFormColumn>
                            <EdsDropdown
                                name={'deviceId'}
                                label={t(
                                    '57d50ee4873d42049cc021a5df38b750',
                                    'Device code'
                                )}
                                getDataCallback={getDataCallback}
                                mappingCallback={(data) => {
                                    return {
                                        id: data?.id,
                                        text: data?.deviceCode,
                                    };
                                }}
                            ></EdsDropdown>
                        </EdsFormColumn>
                    </Row>
                </EdsFormGroup>
            </EdsForm>
        );
    }
);
UmoDefaultDeviceForm.displayName = 'UmoDefaultDeviceForm';
export default UmoDefaultDeviceForm;
