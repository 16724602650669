import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    getDeviceLocations,
    getDeviceStates,
    useEffectOnMount,
} from '../../../../features';
import {
    EdsDropdown,
    EdsDropdownType,
    EdsFormColumn,
    EdsFormGroup,
    EdsWizardStep,
} from '../../../eds';
import { Row } from '@carbon/react';
import _ from 'lodash';

function AdditionalInformationStep({
    id,
    defaultDeviceState,
    hasResidence = false,
}) {
    const { t } = useTranslation();
    const [formDefinition, setFormDefinition] = useState([]);

    useEffectOnMount(async () => {
        setFormDefinition(await getFormDefinition());
    });

    const getLinkDeviceDefaultState = async () => {
        if (_.isFunction(defaultDeviceState)) {
            return await defaultDeviceState();
        }
        return {};
    };

    const LinkDeviceToList = [
        {
            id: 'client',
            text: t('62608e08adc29a8d6dbc9754e659f125', 'Client'),
        },
        {
            id: 'residence',
            text: t('cad4811efcdb1000aae7a6841d995713', 'Residence'),
        },
    ];

    const getFormDefinition = async () => {
        return {
            wizardStepId: id,
            deviceStateId: {
                value: await getLinkDeviceDefaultState(),
                validation: {
                    type: 'dropdown',
                    required: true,
                },
                mapping: 'id',
            },
            deviceLocationId: {
                validation: {
                    type: 'dropdown',
                    required: true,
                },
                mapping: 'id',
            },
            linkDeviceTo: {
                value: LinkDeviceToList[0],
                validation: {
                    type: 'dropdown',
                    required: hasResidence,
                },
                mapping: 'id',
            },
        };
    };

    return (
        <EdsWizardStep
            id={id}
            label={t(
                '4eec452dee7d15acfb78a023b487bf19',
                'Additional information'
            )}
        >
            <EdsFormGroup formDefinition={formDefinition} inStep={true}>
                <Row>
                    <EdsFormColumn>
                        <EdsDropdown
                            name={'deviceStateId'}
                            label={t(
                                '100de37890376212fdea338fb9703bb4',
                                'Device state'
                            )}
                            type={EdsDropdownType.ComboBox}
                            getDataCallback={getDeviceStates}
                        ></EdsDropdown>
                    </EdsFormColumn>
                    <EdsFormColumn>
                        <EdsDropdown
                            type={EdsDropdownType.ComboBox}
                            name={'deviceLocationId'}
                            label={t(
                                'c328b9ce4d9b140f9f441bf0d0bcc691',
                                'Device location'
                            )}
                            getDataCallback={() => {
                                return getDeviceLocations({
                                    isInactive: false,
                                });
                            }}
                        ></EdsDropdown>
                    </EdsFormColumn>
                </Row>

                {hasResidence && (
                    <Row>
                        <EdsFormColumn>
                            <EdsDropdown
                                type={EdsDropdownType.Dropdown}
                                name={'linkDeviceTo'}
                                label={t(
                                    '5034e86d33c051e793f464364e816bd8',
                                    'Link device to'
                                )}
                                items={LinkDeviceToList}
                            ></EdsDropdown>
                        </EdsFormColumn>
                    </Row>
                )}
            </EdsFormGroup>
        </EdsWizardStep>
    );
}

export default AdditionalInformationStep;
