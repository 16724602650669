import {
    createContext,
    memo,
    useCallback,
    useContext,
    useReducer,
} from 'react';
import { Socket } from 'phoenix';
import { getLogger, useAuth } from './../../features';
import { ChannelsActions, channelsReducer } from './reducer';
import authApi from './../auth/api';

const logger = getLogger('ChannelsProvider');

const ChannelsContext = createContext({
    socket: null,
    channel: null,
    loading: false,
    openSocket: () => null,
    sendSocketMessage: () => null,
});

export const ChannelsProvider = memo(({ children }) => {
    const [state, dispatch] = useReducer(channelsReducer, {
        socket: null,
        loading: false,
    });
    const { logout } = useAuth();

    const openSocket = useCallback(async () => {
        if (state.loading || (state.socket && state.channel)) {
            // Socket already opened and joined
            return;
        }

        dispatch({
            type: ChannelsActions.SetLoading,
            payload: true,
        });

        let auth = await authApi.getSocketAuth();
        if (!auth || !auth.token || !auth.session_id) {
            // No auth to open socket with
            logger.error('Invalid auth for socket:', auth);
            return;
        }
        let token = auth.token;
        let sessionId = auth.session_id;

        let socket_url =
            window.location.protocol === 'https:' ? 'wss://' : 'ws://';
        socket_url += window.location.host + '/socket';
        logger.log('Opening socket:', socket_url);

        let socket = new Socket(socket_url, {
            params: { token },
        });
        socket.onError((error) => {
            logger.error('socket::onError:', error);
        });
        socket.onClose((event) => {
            logger.error(
                'socket::onClose: code:',
                event.code,
                'reason:',
                event.reason,
                'wasClean:',
                event.wasClean
            );
        });
        socket.connect();

        let channel = socket.channel('session:' + sessionId);
        channel.onError((error) => {
            logger.error('channel::onError:', error);
        });
        channel.onClose((reason) => {
            logger.error('channel::onClose:', reason);
        });
        channel.on('logout', (payload) => {
            logger.warn('channel::on: event:logout payload:', payload);
            socket.disconnect();
            logout();
        });
        channel
            .join()
            .receive('ok', (resp) => {
                logger.log('Joined session channel successfully:', resp);
            })
            .receive('error', (resp) => {
                logger.error('Unable to join session channel:', resp);
                if (resp.code === 401) {
                    socket.disconnect();
                    logout();
                }
            });
        dispatch({
            type: ChannelsActions.OpenedSocket,
            payload: { socket, channel },
        });
    }, [state, logout]);

    const sendSocketMessage = useCallback(
        (message) => {
            if (!state.channel) {
                return;
            }

            logger.log('Sending socket message:', message);
            state.channel.push('message', message);
        },
        [state]
    );

    return (
        <ChannelsContext.Provider
            value={{
                ...state,
                openSocket,
                sendSocketMessage,
            }}
        >
            {children}
        </ChannelsContext.Provider>
    );
});
ChannelsProvider.displayName = 'ChannelsProvider';

export const useChannels = () => useContext(ChannelsContext);
export const ChannelsConsumer = ChannelsContext.Consumer;
