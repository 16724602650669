import debug from 'debug';

export const getLogger = (name) => {
    let log = debug(name);
    log.enabled = true;
    log.log = console.log.bind(console);

    let info = debug(name);
    info.enabled = true;
    info.log = console.info.bind(console);

    let warn = debug(name);
    warn.enabled = true;
    warn.log = console.warn.bind(console);

    let error = debug(name);
    error.enabled = true;
    error.log = console.error.bind(console);
    return { log, info, warn, error };
};
