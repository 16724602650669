import { forwardRef } from 'react';
import { EdsContainer } from '../eds-container/eds-container';
import { EdsTable } from '../eds-table/eds-table';

export const EdsContainerTable = forwardRef(({ children, ...props }, ref) => {
    return (
        <EdsContainer withPadding={false} {...props}>
            <EdsTable
                size={'md'}
                emptyWithImage={false}
                ref={ref}
                enableSearchParams={false}
                {...props}
            >
                {children}
            </EdsTable>
        </EdsContainer>
    );
});
EdsContainerTable.displayName = 'EdsContainerTable';
