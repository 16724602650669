import { redirect } from 'react-router-dom';
import { getScheme, getSchemeByUID } from '../../../../features';
import { validate as uuidValidate } from 'uuid';

export const pageLoader = async ({ params }) => {
    try {
        if (uuidValidate(params.schemeId)) {
            return await getSchemeByUID(params.schemeId);
        } else {
            return await getScheme(params.schemeId);
        }
    } catch (ex) {
        return redirect('..');
    }
};
