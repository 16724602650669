import { forwardRef, useState } from 'react';
import {
    EdsContainerTable,
    EdsTableCheckmark,
    EdsTableState,
    UmoLinkClient,
} from '../../..';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
    consumerStates,
    getGenderLabel,
    setEmptyFieldToNull,
    useModal,
    useNavigate,
    useNotifications,
} from '../../../../features';

export const UmoLinkedClientTable = forwardRef(
    (
        {
            getDataCallback,
            postDataCallback,
            onUnlinkClick,
            linkBaseFilter,
            ...props
        },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();
        const [showRequiresDevice] = useState(
            props.showRequiresDevice ?? false
        );
        const [title] = useState(
            props.title ??
                t('fe3d7a812dc6875690fb89b8607daaa6', 'Linked clients')
        );
        const [hideWhenNoData] = useState(props.hideWhenNoData ?? true);
        const [hasData, setHasData] = useState();
        const { showSuccess, showError } = useNotifications();
        const { showLinkModal } = useModal();

        const getData = async () => {
            let data = await getDataCallback();

            if (!_.isArray(data)) {
                data = [data];
            }

            const filteredData = await data.filter((item) => {
                return (
                    item?.clientStatus?.isVisibleAsLink === true &&
                    additionalDataFilter(item)
                );
            });
            setHasData(!_.isEmpty(filteredData));
            return filteredData;
        };

        const additionalDataFilter = (item) => {
            return (
                !_.isFunction(props?.additionalDataFilterCallback) ||
                props?.additionalDataFilterCallback(item)
            );
        };

        const linkNewClient = async (form) => {
            const postData = setEmptyFieldToNull(form);

            try {
                const response = await postDataCallback({
                    clientId: postData.selected,
                });

                showSuccess({
                    title: t('536a056b710e94b16169efd17a4a657b', 'Saved'),
                    content: t(
                        '24534bfe05c8f14ed53e9e4a1d4dc691',
                        "{{entity}} is linked to '{{name}}'",
                        {
                            entity: response.person?.combinedName,
                            name: response.combinedAddressField,
                        }
                    ),
                });

                ref.current?.fetchInitData();

                return response;
            } catch (error) {
                showError({
                    title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                    content: t(
                        'df0a9d46baf7315909e4389a04786e3d',
                        'Oops something went wrong'
                    ),
                });

                throw error;
            }
        };

        if (
            hideWhenNoData &&
            !_.isUndefined(hasData) &&
            _.isBoolean(hasData) &&
            !hasData
        ) {
            return null;
        }

        const headers = [
            {
                key: 'state',
                header: t('9ed39e2ea931586b6a985a6942ef573e', 'State'),
                sort: 'state_name',
                width: '200px',
            },
            {
                key: 'name',
                header: t('b068931cc450442b63f5b3d276ea4297', 'Name'),
                sort: 'name',
            },
            {
                key: 'gender',
                header: t('cc90f1913b83d255b95be0e0fea6d576', 'Gender'),
                sort: 'gender',
            },
            ...(showRequiresDevice
                ? [
                      {
                          key: 'requires_device',
                          header: t(
                              '048b4dbdc19ab977e806aceeeccc1208',
                              'Requires device'
                          ),
                      },
                  ]
                : []),
            {
                key: 'address',
                header: t('884d9804999fc47a3c2694e49ad2536a', 'Address'),
                sort: 'address',
            },
        ];

        const getVisibleHeaders = () => {
            if (!props.visibleHeaderKeys) {
                return headers;
            }

            return props.visibleHeaderKeys.map((key) =>
                headers.find((h) => h.key === key)
            );
        };

        return (
            <EdsContainerTable
                hidden={_.isUndefined(hasData)}
                title={title}
                ref={ref}
                onRowClick={(row) => {
                    navigate(`/crm/client/${row.id}`);
                }}
                headers={getVisibleHeaders()}
                getDataCallback={getData}
                mappingCallback={(responseData) => {
                    return {
                        id: responseData.id,
                        state_name:
                            responseData.clientStatus?.consumerState?.name,
                        state: (
                            <EdsTableState
                                validState={consumerStates.Started}
                                invalidState={consumerStates.Ended}
                                state={
                                    responseData.clientStatus?.consumerState
                                        ?.name
                                }
                                label={responseData.clientStatus?.name}
                            />
                        ),
                        name: responseData.person?.combinedName,
                        gender: responseData.person.gender
                            ? getGenderLabel(
                                  t,
                                  responseData.person.gender.toLowerCase()
                              )
                            : '',
                        requires_device: (
                            <EdsTableCheckmark
                                value={
                                    responseData.clientStatus?.deviceRequired
                                }
                                id={responseData.id}
                            />
                        ),
                        address: responseData.combinedAddressField,
                    };
                }}
                onHeaderLinkClick={
                    !_.isFunction(postDataCallback)
                        ? undefined
                        : () => {
                              showLinkModal({
                                  title: t(
                                      '0b4d7692ea982f020774f5f23b62b03f',
                                      'Link client'
                                  ),
                                  tableRef: ref,
                                  children: (
                                      <UmoLinkClient
                                          {...props}
                                          getDataCallback={getData}
                                          linkBaseFilter={linkBaseFilter}
                                          onSubmit={async (form) => {
                                              if (
                                                  !_.isFunction(
                                                      postDataCallback
                                                  )
                                              ) {
                                                  return false;
                                              }
                                              return linkNewClient(form);
                                          }}
                                      />
                                  ),
                              });
                          }
                }
                onUnlinkClick={
                    !_.isFunction(onUnlinkClick)
                        ? undefined
                        : async (row) => {
                              const data = ref.current?.getRowData(row.id);

                              if (_.isFunction(onUnlinkClick)) {
                                  onUnlinkClick({ row: row, data: data });
                              }
                          }
                }
                {...props}
            />
        );
    }
);
UmoLinkedClientTable.displayName = 'UmoLinkedClientTable';

export const UmoUnlinkLinkedClientTable = ({
    description,
    modalId,
    ...props
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { remove } = useModal();

    return (
        <>
            <div>{description}</div>
            <UmoLinkedClientTable
                {...props}
                onRowClick={(row) => {
                    navigate(`/crm/client/${row.id}`);
                    if (!_.isUndefined(modalId)) {
                        remove(modalId);
                    }
                }}
                title={t('62608e08adc29a8d6dbc9754e659f125', 'Client')}
            />
        </>
    );
};
