export const getCountryTranslations = (t) => {
    return {
        af: t('a8424b7baae263918301406bdc884e28', 'Afghanistan'),
        al: t('3303daf806aebcd0cfd114f7d267f109', 'Albania'),
        dz: t('49f4fdc64da08e0cbac132fa06062d2d', 'Algeria'),
        as: t('4941fb51fed4066ebea2f0a198cb531e', 'American Samoa'),
        ad: t('449f508e9fad3b1bd4debc02f0d2194b', 'Andorra'),
        ao: t('e5e0bd43b7f1a35aaf645f597de2ed08', 'Angola'),
        ai: t('b4e23646d009fc1162fa15d6e1c7090a', 'Anguilla'),
        ag: t('ee1124fdac2c680640490d6943923a44', 'Antigua & Barbuda'),
        ar: t('2a52adc7b1da6a4e0a7a14e4c8db1b11', 'Argentina'),
        am: t('3744c623dc4219b00a900ee527c16387', 'Armenia'),
        aw: t('2a9f708a9849ca56a428967dc6781ceb', 'Aruba'),
        ac: t('6fe07d244e25808f3b2522bb2ad0b156', 'Ascension Island'),
        au: t('fb2a54d637484c4ca8adb2343237b89f', 'Australia'),
        at: t('6cd0c266688a7a579874167d67c74a66', 'Austria'),
        az: t('620a71f90d5dbf8c9a80939d2cbc1eba', 'Azerbaijan'),
        bs: t('19f9730000abcc9fe49e6e9051d109bc', 'Bahamas'),
        bh: t('97b78c7af698a19f89c414f8ede0ddd9', 'Bahrain'),
        bd: t('b47bc9c3971a24ddbbfd211d7b692227', 'Bangladesh'),
        bb: t('8c7c9b1149e9de712b9e5abab45d700c', 'Barbados'),
        by: t('f9bcd904ecd531c125ab9b42b5be3f35', 'Belarus'),
        be: t('73fa01094ea6c89f1f8efbeb57037499', 'Belgium'),
        bz: t('48f621bb94135383a66d54914f195bfd', 'Belize'),
        bj: t('9f1bf009168d01c56794edf2d1a94f83', 'Benin'),
        bm: t('8925650ea01ef34b5fb8c451566add62', 'Bermuda'),
        bt: t('6a748bb76e4fe708a3e321c77ed0d30c', 'Bhutan'),
        bo: t('76781be1c79df46b096f0cfb71f2bf82', 'Bolivia'),
        ba: t('a4a76d0d5feef268040dffae12578724', 'Bosnia & Herzegovina'),
        bw: t('1c182d43cd8d37caf9ed0c3597fc83d9', 'Botswana'),
        br: t('6e5fa4d9c48ca921c0a2ce1e64c9ae6f', 'Brazil'),
        io: t(
            'e358efa489f58062f10dd7316b65649e',
            'British Indian Ocean Territory'
        ),
        vg: t('e3da147006bfec9f602ad67d367e34f7', 'British Virgin Islands'),
        bn: t('0be0d89a7660a0f90bc223b97924a527', 'Brunei'),
        bg: t('db5f144303047ccd07e8f149da182bc8', 'Bulgaria'),
        bf: t('eca2bbce1ba27f0a2c5e7950eaf5bfd4', 'Burkina Faso'),
        bi: t('385433f6872fe8a9fb0c31b358994e8b', 'Burundi'),
        kh: t('7bf23feba70a30ed762096c1570bc416', 'Cambodia'),
        cm: t('9621cbb93d1b8d7e12682d1237c6d8d4', 'Cameroon'),
        ca: t('c2aadac2ca30ca8aadfbe331ae180d28', 'Canada'),
        cv: t('fd23826bfd0fe303c63e6846084bced1', 'Cape Verde'),
        bq: t('ecd36cc042eec420b3075b485bd30f83', 'Caribbean Netherlands'),
        ky: t('9dba5679b314cbb06f8963fd57bf2445', 'Cayman Islands'),
        cf: t('107bc51f0f59d738ab819811b10f6db5', 'Central African Republic'),
        td: t('ede79b3fbf673a9a8e9bf3db02aeb7b2', 'Chad'),
        cl: t('fd317045cbdcd26054de08112d6031b4', 'Chile'),
        cn: t('8a7d7ba288ca0f0ea1ecf975b026e8e1', 'China'),
        cx: t('537cc5a36a9b73a62c2a67c20d5c9c93', 'Christmas Island'),
        cc: t('307f462d6eb0475127853180330d8a34', 'Cocos (Keeling) Islands'),
        co: t('25446782e2ccaf0afdb03e5d61d0fbb9', 'Colombia'),
        km: t('782d46484014d0c80ec48965c0bf91f0', 'Comoros'),
        cg: t('4730489c91b0dfde8d9118372510c8e0', 'Congo - Brazzaville'),
        cd: t('1ed15b462623c2a139793cf2bf2a2a69', 'Congo - Kinshasa'),
        ck: t('a0ae0dd4316c2dbf9eaf976006215ef6', 'Cook Islands'),
        cr: t('53bcde0ca0597e6777543b9ebf7892a4', 'Costa Rica'),
        ci: t('b5b10f76b0aea6e3fe309f23ce9a71d1', 'Côte d’Ivoire'),
        hr: t('267640588a3983481a032894a50f0be6', 'Croatia'),
        cu: t('2b84cd5da64a828bd859b18535c9345e', 'Cuba'),
        cw: t('7e21ee596953ac05ee594c0474ec2a30', 'Curaçao'),
        cy: t('6d68c7cfe5d1825d6622f3c824f8db8b', 'Cyprus'),
        cz: t('066e70e862f6b3e24f4fd2c294578634', 'Czech Republic'),
        dk: t('e9375700acbb6b42ae4101daa00b0c20', 'Denmark'),
        dj: t('a2b10e1941066f4a25a0f3e1f853987e', 'Djibouti'),
        dm: t('54f20902e0d16abe7c7bbcb99c40bf13', 'Dominica'),
        do: t('dff1ff528e0e08ff28b410c426fb89bb', 'Dominican Republic'),
        ec: t('2b4a1835a0aa73f5d53650771710fe43', 'Ecuador'),
        eg: t('4635c9536a61159732f9ce991ec4c095', 'Egypt'),
        sv: t('eedf141a7e7b1cf0217932f3f75b8cb7', 'El Salvador'),
        gq: t('89179fe8f36d91eb969a4494b58e0362', 'Equatorial Guinea'),
        er: t('41b59746d0ffb893c7b2a39cf59b6b07', 'Eritrea'),
        ee: t('5c6298b2e7597403c74346118933734c', 'Estonia'),
        sz: t('96d8561ffb85ae5ea9b9b791751cd140', 'Eswatini'),
        et: t('a2694bc1f355e2b5e6c7ab74c1bd998e', 'Ethiopia'),
        fk: t('7bc68dd5ff57ce03984995116f25ee2d', 'Falkland Islands'),
        fo: t('ed5b46dc1ded2b8024c9724bdb80a7e9', 'Faroe Islands'),
        fj: t('67ef4f44837d2f3fa5aef528f8a27e17', 'Fiji'),
        fi: t('631d44eaa1254ff71a1e11ba021d1266', 'Finland'),
        fr: t('e165d4f2174b66a7d1a95cb204d296eb', 'France'),
        gf: t('2519766edd3c9418a96b1c88641543b3', 'French Guiana'),
        pf: t('c6ff5b069f15245b5be347a276409cd2', 'French Polynesia'),
        ga: t('ee2144db632a80bb43dda38521c615e4', 'Gabon'),
        gm: t('264ce69a65a7fc125fd53bee2c19140f', 'Gambia'),
        ge: t('c0cdd82ce092b01267bdd88a8bfbb1f4', 'Georgia'),
        de: t('28ef36b35ae8cb1f542578f03408b512', 'Germany'),
        gh: t('0e6049dbb2ce3c238f721fcc472dae55', 'Ghana'),
        gi: t('ec1bb8c95cc209c9f444bb69d7ac148d', 'Gibraltar'),
        gr: t('40bdb06018487838c520ab7419d2accf', 'Greece'),
        gl: t('ad53f5fb20dcbe1aeec7aa81e0d0aed2', 'Greenland'),
        gd: t('6aca8efbb22e6444d625fb610cdf706a', 'Grenada'),
        gp: t('7d876ca0ab5802523aa43fdd60d528d5', 'Guadeloupe'),
        gu: t('8f80c74c6b23ce247d2ed39dbff2ee2b', 'Guam'),
        gt: t('ac785d6fd8e6a6820365d4de2703b389', 'Guatemala'),
        gg: t('d7211a8a6f09b60678f6b6c7877d3a26', 'Guernsey'),
        gn: t('3da0d1211a75cc3ab6a98fe3c439636f', 'Guinea'),
        gw: t('112732882039a9ac5dd6f5c0a06073ee', 'Guinea-Bissau'),
        gy: t('fa2a885d6836e849fdead62ea7819169', 'Guyana'),
        ht: t('87b190830af9de5d8c880be5fa6c5743', 'Haiti'),
        hn: t('1e28fe23fb146a883e01981724113f33', 'Honduras'),
        hk: t('db1e8c642c52e48e501a16b5783bf4ae', 'Hong Kong'),
        hu: t('3b4bbcb747288e1e6ede89221191781d', 'Hungary'),
        is: t('83166cdeb7a2d9c1d591ad9d4eff4204', 'Iceland'),
        in: t('11a98374ebec8e0c7a54751d2161804d', 'India'),
        id: t('cda2c99fbf5e19f20d331299c15a4491', 'Indonesia'),
        ir: t('6f0a7d56c2cd7a0861b17eb27bfb562b', 'Iran'),
        iq: t('d3b1cee2327cfa357dabf0289958c024', 'Iraq'),
        ie: t('a66f852274a441f4f9676b070c0c64db', 'Ireland'),
        im: t('eec21eb1ffbb751f37af4795c5188fb2', 'Isle of Man'),
        il: t('3f8454b7f2c12cebb1622b6b0dfd1021', 'Israel'),
        it: t('bab4ac375adad13f5693f24fceab7903', 'Italy'),
        jm: t('770105b98be2fdd6a6f096d29ca5632c', 'Jamaica'),
        jp: t('578ed5a4eecf5a15803abdc49f6152d6', 'Japan'),
        je: t('f48e94f82fffcdc8c712aa1b378587d2', 'Jersey'),
        jo: t('d16d377af76c99d27093abc22244b342', 'Jordan'),
        kz: t('e22cc0a14faacf60ca06cebdc972caa5', 'Kazakhstan'),
        ke: t('577e905f157abece95f454ad0b3058cf', 'Kenya'),
        ki: t('23fc580f98534d8012a20e14bd6eada0', 'Kiribati'),
        xk: t('9eb4c22bacc6d9e92741b95750710b4f', 'Kosovo'),
        kw: t('b18fa0d42ad9b3706237ef5b02434829', 'Kuwait'),
        kg: t('ae7d95378501e0f23dbbfd3434eb4720', 'Kyrgyzstan'),
        la: t('5495a2f9ee0ac16f7ee35878f502eefb', 'Laos'),
        lv: t('fdd7dad88f90fdc707b1b1aae34d9542', 'Latvia'),
        lb: t('94e5a243ffca78a7583778ec2027c69b', 'Lebanon'),
        ls: t('34c9c5ead9a4ad44797165d31620be4a', 'Lesotho'),
        lr: t('2f738a6c521838ed07e6ed03b1a58648', 'Liberia'),
        ly: t('13551ec36474766c9f6beb2a568bfeee', 'Libya'),
        li: t('5abfef79f0201629ea491351dfe984b1', 'Liechtenstein'),
        lt: t('93efe964422847bf774649a3c89daa6d', 'Lithuania'),
        lu: t('068b83b0839d8cff627bd91fdc8059fb', 'Luxembourg'),
        mo: t('9690a92f29519fbfef104011784221e7', 'Macau'),
        mg: t('756c455b0a8843bac42ebb608bd29ed2', 'Madagascar'),
        mw: t('e8c30390bee469047b22988d8557ab20', 'Malawi'),
        my: t('482afeb462daaebce1b4681e1f06b3c7', 'Malaysia'),
        mv: t('ebc234df81245f7bff973231d9393981', 'Maldives'),
        ml: t('d372d4727d7feea47b8c92f8a137c05d', 'Mali'),
        mt: t('d20a90675f790ef1dea45b40eb8a97b7', 'Malta'),
        mh: t('cb8c8339c06dc088845da10f7147de74', 'Marshall Islands'),
        mq: t('67df7e7e4507aedf9715b6e9ad589666', 'Martinique'),
        mr: t('a4969288f9dbc7882a464aca3fe27c40', 'Mauritania'),
        mu: t('5267f9a349e65086b209248732631478', 'Mauritius'),
        yt: t('05430c5db654c9e41e925a431fd0c252', 'Mayotte'),
        mx: t('4edfc924721abb774d5447bade86ea5d', 'Mexico'),
        fm: t('16fe35ae3661e12db16ebb84a314c719', 'Micronesia'),
        md: t('9aefeef9c7e53f9de9bb36f32649dc3f', 'Moldova'),
        mc: t('77f09e1229462ba78b6a10825319ba12', 'Monaco'),
        mn: t('f5b7bede50a81a6ed28a82708dcf57f4', 'Mongolia'),
        me: t('fd1c44addbb563a0740a3beb74fe825d', 'Montenegro'),
        ms: t('351e220deb101a255f2b98d8724efff7', 'Montserrat'),
        ma: t('217b6e5f0a09f648b648cc3d85d82da5', 'Morocco'),
        mz: t('ae82307ab0196bce148cae466863e4c5', 'Mozambique'),
        mm: t('168cb0cfb93c850d5ed9a9155dd27885', 'Myanmar (Burma)'),
        na: t('deb38882b3f655b79324a860073a0977', 'Namibia'),
        nr: t('c88eee44a87ce3c5b08ccdedf5a0cdef', 'Nauru'),
        np: t('45f1a4b4a2f6bdfa31838a823064389f', 'Nepal'),
        nl: t('6123486432d21179f354b970b501e518', 'Netherlands'),
        nc: t('68df735159d1fb3e575346204ddec3af', 'New Caledonia'),
        nz: t('ea14e587f5a8de7bf3975099762ce583', 'New Zealand'),
        ni: t('0043ce23d022d129c73462f7ffe3f805', 'Nicaragua'),
        ne: t('5d45b85e2b53db3e91be18a8b339e947', 'Niger'),
        ng: t('f174114ad69e0db66eff6090be60b5e5', 'Nigeria'),
        nu: t('4a7c85f977117c5cda67796fb226b09a', 'Niue'),
        nf: t('2438782b1f164c5996e5b4e5a3483844', 'Norfolk Island'),
        kp: t('fa4054535dfefe5aa24977dcb4542c37', 'North Korea'),
        mk: t('28d0d9042a7750d54b6935170d30b44f', 'North Macedonia'),
        mp: t('8e9f0cee1c720e8101484ffc8b9df593', 'Northern Mariana Islands'),
        no: t('e6e6854f6b71f43039d3994aca352307', 'Norway'),
        om: t('ad437fd2f44c7b3f8208a162604d81d0', 'Oman'),
        pk: t('4e075844d2e00e4c800c8c62716bed8c', 'Pakistan'),
        pw: t('cfcf9492e84ce715967a1bcf1a9e4850', 'Palau'),
        ps: t('b6d6ae0b8516440b56da14e3f2be5d95', 'Palestine'),
        pa: t('a0420c052acb3a75b05456fabf9093dc', 'Panama'),
        pg: t('57d05594d903f17eb3074a0121860369', 'Papua New Guinea'),
        py: t('3bafbfd8bf5ecf4c4a1b6e1df1ad8ec6', 'Paraguay'),
        pe: t('32e8419a7ecb8f918c70fdadf783e3d8', 'Peru'),
        ph: t('c894df42aa06fcb0e8e3ff55b71b33fd', 'Philippines'),
        pl: t('6f08c5ddcdd0be06d83aa3e0e3d5a09e', 'Poland'),
        pt: t('ae0eea6eb6d63f98da42de867c47a0f8', 'Portugal'),
        pr: t('e572676229e6833fd8b6ccb817444945', 'Puerto Rico'),
        qa: t('7d8ca47bbe17ba692ed007c859ba51b1', 'Qatar'),
        re: t('cd46ea289fad6a4739d702d7d81701a1', 'Réunion'),
        ro: t('89055f975d7ddbd99646d954daf3cbb5', 'Romania'),
        ru: t('1c625cc86f824660a320d185916e3c55', 'Russia'),
        rw: t('c04911d0d40595d7f4e574b24e2d0b4c', 'Rwanda'),
        ws: t('8f95c3e842391f2e794c3c8e6111d4e3', 'Samoa'),
        sm: t('d99dd03bfa4e58467f69a58fcbda8746', 'San Marino'),
        st: t('dc445dfe7a4926829db3c31d8185a5d4', 'São Tomé & Príncipe'),
        sa: t('5318efec44d163ddaa345bfa15279413', 'Saudi Arabia'),
        sn: t('531ba498ac8690f1a72034843b5fd7fd', 'Senegal'),
        rs: t('a3867434e7dc78acd072dd9558119858', 'Serbia'),
        sc: t('5e53ddefcab309fd88e79d7744f6f365', 'Seychelles'),
        sl: t('b628c9a1757c9eeb051dd9f2c10aeace', 'Sierra Leone'),
        sg: t('d3197fb64e031c6be6a9e2005a2fbe9a', 'Singapore'),
        sx: t('619afaa0ddca332070f3f5e0f2757542', 'Sint Maarten'),
        sk: t('f64a398fc678c17687a4c18476bbd3e8', 'Slovakia'),
        si: t('7f65809a80ea14772b90e9ffb2053857', 'Slovenia'),
        sb: t('3cf6980c6ac20be5f3672a4a029a46cc', 'Solomon Islands'),
        so: t('391fbc5b5081b1ae00b4faaae91d3a58', 'Somalia'),
        za: t('c814068bbc9207acd130de362b2d6ddc', 'South Africa'),
        kr: t('3c6cfbc2415aad893733819108cf892c', 'South Korea'),
        ss: t('a734cd98f57bccf3b4960896c810aead', 'South Sudan'),
        es: t('1747d4b2dd4bcd666e2df31563d22dbe', 'Spain'),
        lk: t('8ba5bbdd9e0c11077dbf5a9cc83d1c45', 'Sri Lanka'),
        bl: t('f0170b1131be46d26506d5444fc9f107', 'St Barthélemy'),
        sh: t('2e472b743f6b0a523ac96958624940aa', 'St Helena'),
        kn: t('0dc94fe1b29cb76bfc5e6d46a72d9062', 'St Kitts & Nevis'),
        lc: t('3b8cf921bba92eba23c6639c92854ae5', 'St Lucia'),
        mf: t('c1518e84c9006c0d96320fa8596272a0', 'St Martin'),
        pm: t('ba2a2352263d58a96b25644b82c02126', 'St Pierre & Miquelon'),
        vc: t('d3353ec8238b8e7c79a468afe5198962', 'St Vincent & Grenadines'),
        sd: t('dfcc27a6f3c9e017ca14cab07e4b3b0c', 'Sudan'),
        sr: t('a16da9eeac180a9492191be736dd4451', 'Suriname'),
        sj: t('edd1d43e983ae872dfd7fa9a87587bbb', 'Svalbard & Jan Mayen'),
        se: t('88e52ee42fd090896f69d0d71e339d94', 'Sweden'),
        ch: t('0d9c575f4f0260fe0274b318cd6a9e34', 'Switzerland'),
        sy: t('aae53924404be2c727479aeebd3c8f8d', 'Syria'),
        tw: t('31779ba135934ed036644deb47eb1e54', 'Taiwan'),
        tj: t('cdfe86e668f700c2f23789470df3743b', 'Tajikistan'),
        tz: t('ed07875f3aab4eb7076467cb441ba233', 'Tanzania'),
        th: t('8689391a8b93cd2d55ccf3f436eef4e2', 'Thailand'),
        tl: t('c43440cf13c5b932003103e38d055d1e', 'Timor-Leste'),
        tg: t('da6f471bab1662dafc3e6845c312dd5f', 'Togo'),
        tk: t('b9f5e2997afc6f63ea9ec3cfbe0a6315', 'Tokelau'),
        to: t('2a08a42db6678fdd393ab41dc657d5a3', 'Tonga'),
        tt: t('a210fcc07e614af68c125c6d2031c92a', 'Trinidad & Tobago'),
        tn: t('6b07fd4ea837c39e1542e1bbca01a224', 'Tunisia'),
        tr: t('db77174aa34ded1b6139455a58d0a38b', 'Turkey'),
        tm: t('963f79ea1a99aefb8a7318ee388dcdd9', 'Turkmenistan'),
        tc: t('1859515ce20d3afa276c524d7b2177a6', 'Turks & Caicos Islands'),
        tv: t('ac0e1f17968ec7ab248ab2c1beab5720', 'Tuvalu'),
        ug: t('111fa7773cce1023627b584dc8099873', 'Uganda'),
        ua: t('4b0f1559226dc5c26d97dd4ef788a85a', 'Ukraine'),
        ae: t('8882cbb50c4172662cc0d08a89a4ff29', 'United Arab Emirates'),
        gb: t('9cdc126f68260cbddf3a00a4032f0e7e', 'United Kingdom'),
        us: t('152649df347ee2891a9eacc883e07d17', 'United States'),
        uy: t('9142ac5b229c9d83163e89bc1f468607', 'Uruguay'),
        vi: t('8e0bb82c7ea0e034d1da50eb78fa4521', 'US Virgin Islands'),
        uz: t('aa1b4b6598e512e8939eaddf9ee8543d', 'Uzbekistan'),
        vu: t('d0df5ce5502e665036f444f1b4237f2f', 'Vanuatu'),
        va: t('974364f7d5798ece46d46ca425f7327e', 'Vatican City'),
        ve: t('e65a612aab8846b113341efa15bba910', 'Venezuela'),
        vn: t('5f8f9e33bb5e13848af2622b66b2308c', 'Vietnam'),
        wf: t('72da0f27daa95104fef7c06a3f59f95c', 'Wallis & Futuna'),
        eh: t('18f434487bbb6016b13df7ec9c7b8c49', 'Western Sahara'),
        ye: t('493e7a99c604de36955b21f24b528249', 'Yemen'),
        zm: t('dfa43abd63431f47d6c7d638c49e4267', 'Zambia'),
        zw: t('f5a65b13e37f89a3ab7c29e319dcda03', 'Zimbabwe'),
        ax: t('e639d6812fbd9dc779256da4465c9d5e', 'Åland Islands'),
    };
};
