import {
    createContext,
    memo,
    useCallback,
    useContext,
    useReducer,
} from 'react';
import { v4 as uuid } from 'uuid';
import { NotificationsActions, notificationsReducer } from './reducer';
import _ from 'lodash';

const NotificationsContext = createContext({
    notifications: [],
    showError: () => null,
    showWarning: () => null,
    showSuccess: () => null,
    showInfo: () => null,
    remove: () => null,
});

export const NotificationsProvider = memo(({ children }) => {
    const [state, dispatch] = useReducer(notificationsReducer, {
        notifications: [],
    });

    const remove = useCallback((id) => {
        dispatch({
            type: NotificationsActions.RemoveNotification,
            id: id,
        });
    }, []);

    /*
     * Uses the same properties as ActionableNotification:
     * https://react.carbondesignsystem.com/?path=/docs/components-notifications-actionable--overview
     */
    const showNotification = useCallback(
        (type, params) => {
            let notification = {
                id: uuid(),
                kind: type,
                ttl: params.ttl | 3000,
                ...params,
            };

            dispatch({
                type: NotificationsActions.ShowNotification,
                payload: notification,
            });

            if (_.isFunction(notification?.onShow)) {
                notification.onShow();
            }

            if (notification.ttl > 0) {
                setTimeout(() => remove(notification.id), notification.ttl);
            }
        },
        [remove]
    );

    const showError = useCallback(
        (notification) => showNotification('error', notification),
        [showNotification]
    );

    const showWarning = useCallback(
        (notification) => showNotification('warning', notification),
        [showNotification]
    );

    const showSuccess = useCallback(
        (notification) => showNotification('success', notification),
        [showNotification]
    );

    const showInfo = useCallback(
        (notification) => showNotification('info', notification),
        [showNotification]
    );

    return (
        <NotificationsContext.Provider
            value={{
                ...state,
                showError,
                showWarning,
                showSuccess,
                showInfo,
                remove,
            }}
        >
            {children}
        </NotificationsContext.Provider>
    );
});
NotificationsProvider.displayName = 'NotificationsProvider';

export const useNotifications = () => useContext(NotificationsContext);
export const NotificationsConsumer = NotificationsContext.Consumer;
