import { getRequest, postRequest, putRequest, deleteRequest } from '../api';

export const getAccountSettings = async () => {
    return getRequest('/maintenance/account/settings');
};

export const putAccountSettings = async (accountSettings) => {
    return putRequest('/maintenance/account/settings', accountSettings);
};

export const getExternalIdentifierTypes = async (params) => {
    return getRequest('/maintenance/account/external-identifier-types', params);
};

export const getExternalIdentifierType = async (id) => {
    return getRequest('/maintenance/account/external-identifier-types/' + id);
};

export const postExternalIdentifierType = async (externalIdentifierType) => {
    return postRequest(
        '/maintenance/account/external-identifier-types',
        externalIdentifierType
    );
};

export const putExternalIdentifierType = async (externalIdentifierType) => {
    return putRequest(
        '/maintenance/account/external-identifier-types/' +
            externalIdentifierType.id,
        externalIdentifierType
    );
};

export const deleteExternalIdentifierType = async (id) => {
    return deleteRequest(
        '/maintenance/account/external-identifier-types/' + id
    );
};

export const getTagCategories = async (params = {}) => {
    return getRequest('/maintenance/account/tag-categories', params);
};

export const getTagCategory = async (id) => {
    return getRequest('/maintenance/account/tag-categories/' + id);
};

export const postTagCategory = async (tagCategory) => {
    return postRequest('/maintenance/account/tag-categories', tagCategory);
};

export const putTagCategory = async (tagCategory) => {
    return putRequest(
        '/maintenance/account/tag-categories/' + tagCategory.id,
        tagCategory
    );
};

export const deleteTagCategory = async (id) => {
    return deleteRequest('/maintenance/account/tag-categories/' + id);
};

export const getTags = async (tagCategoryId, params) => {
    return getRequest(
        `/maintenance/account/tag-categories/${tagCategoryId}/tags`,
        params
    );
};

export const getTag = async (id) => {
    return getRequest('/maintenance/account/tags/' + id);
};

export const postTag = async (tag) => {
    return postRequest('/maintenance/account/tags', tag);
};

export const putTag = async (tag) => {
    return putRequest('/maintenance/account/tags/' + tag.id, tag);
};

export const deleteTag = async (id) => {
    return deleteRequest('/maintenance/account/tags/' + id);
};

export const getAbsenceReasons = async (params = {}) => {
    return getRequest(`/maintenance/account/absence-reasons`, params);
};

export const getAbsenceReason = async (id) => {
    return getRequest('/maintenance/account/absence-reasons/' + id);
};

export const postAbsenceReason = async (absenceReason) => {
    return postRequest('/maintenance/account/absence-reasons', absenceReason);
};

export const putAbsenceReason = async (absenceReason) => {
    return putRequest(
        '/maintenance/account/absence-reasons/' + absenceReason.id,
        absenceReason
    );
};

export const deleteAbsenceReason = async (id) => {
    return deleteRequest('/maintenance/account/absence-reasons/' + id);
};

export const getPhonebook = async () => {
    return getRequest('/maintenance/account/phonebook');
};

export const getPhoneNumberTypes = async (onlyE164Format) => {
    return getRequest('/maintenance/account/phone-number-type/', {
        onlyE164Format: onlyE164Format,
    });
};

export const getSelectablePhoneNumberTypes = async (onlyE164Format) => {
    const phoneNumberTypes = await getPhoneNumberTypes(onlyE164Format);
    return phoneNumberTypes.filter((item) => item.selectable) ?? {};
};

export const getDefaultPhoneNumberType = async (onlyE164Format) => {
    const phoneNumberTypes = await getPhoneNumberTypes(onlyE164Format);
    return phoneNumberTypes.find((item) => item.isDefault) ?? {};
};

export const getPhoneNumberType = async (id) => {
    return getRequest('/maintenance/account/phone-number-type/' + id);
};

export const postPhoneNumberType = async (phoneNumberType) => {
    return postRequest(
        '/maintenance/account/phone-number-type',
        phoneNumberType
    );
};

export const putPhoneNumberType = async (phoneNumberType) => {
    return putRequest(
        '/maintenance/account/phone-number-type/' + phoneNumberType.id,
        phoneNumberType
    );
};

export const deletePhoneNumberType = async (id) => {
    return deleteRequest('/maintenance/account/phone-number-type/' + id);
};

export const getSubscriptions = async (params) => {
    return getRequest('/maintenance/account/subscriptions', params);
};

export const getSubscription = async (id) => {
    return getRequest('/maintenance/account/subscriptions/' + id);
};

export const postSubscription = async (subscription) => {
    return postRequest('/maintenance/account/subscriptions', subscription);
};

export const putSubscription = async (subscription) => {
    return putRequest(
        '/maintenance/account/subscriptions/' + subscription.id,
        subscription
    );
};

export const deleteSubscription = async (id) => {
    return deleteRequest('/maintenance/account/subscriptions/' + id);
};
