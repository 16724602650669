import { redirect } from 'react-router-dom';
import { getCountry, getRegion } from '../../../../../features';

export const pageLoader = async ({ params }) => {
    try {
        return await getCountry(params.countryId);
    } catch (ex) {
        return redirect('..');
    }
};

export const cityPageLoader = async ({ params }) => {
    try {
        return await getRegion(params.regionId);
    } catch (ex) {
        return redirect('..');
    }
};
