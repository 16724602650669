import { ComboBox } from '@carbon/react';
import {
    debounce,
    getDefaultCountry,
    getLogger,
    useEffectOnMount,
} from '../../../features';
import { APIProvider, useMapsLibrary } from '@vis.gl/react-google-maps';
import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { EdsLoading } from '../..';

const logger = getLogger('EdsAutocompleteAddress');

export function EdsAutocompleteAddress({ apiKey, ...props }) {
    const [loading, setLoading] = useState(true);
    const [countryCode, setCountryCode] = useState();

    useEffectOnMount(async () => {
        const fetchDefaultCountry = async () => {
            const defaultCountry = await getDefaultCountry();
            setCountryCode(defaultCountry?.countryCode);
        };

        setLoading(true);
        fetchDefaultCountry();
    });

    useEffect(() => {
        if (countryCode !== undefined) {
            setLoading(false);
        }
    }, [countryCode]);

    if (_.isNil(apiKey)) {
        logger.log(
            'Please fill in a google Maps API key in the maintenance settings'
        );
        return;
    }

    return (
        <>
            {loading && (
                <div className="eds--loading">
                    <EdsLoading hideLabel={true} small={true} delay={true} />
                </div>
            )}

            {!loading && (
                <APIProvider
                    apiKey={apiKey}
                    language={countryCode}
                    onLoad={() => logger.log('Maps API has loaded.')}
                >
                    <EdsAutocompleteAddressComponent
                        countryCode={countryCode}
                        {...props}
                    />
                </APIProvider>
            )}
        </>
    );
}

const EdsAutocompleteAddressComponent = ({
    name,
    label = '',
    countryCode,
    onPlaceSelectCallback,
}) => {
    const inputOnChangeTimeoutId = useRef();
    const places = useMapsLibrary('places');

    // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompleteSessionToken
    const [sessionToken, setSessionToken] = useState();

    // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service
    const [autocompleteService, setAutocompleteService] = useState(null);

    // https://developers.google.com/maps/documentation/javascript/reference/places-service
    const [placesService, setPlacesService] = useState(null);

    const [items, setItems] = useState([]);

    useEffect(() => {
        if (!places) {
            logger.log(
                'Places library not loeaded, skip initialization of services'
            );
            return;
        }

        setAutocompleteService(new places.AutocompleteService());
        setPlacesService(
            new places.PlacesService(document.createElement('div'))
        );
        setSessionToken(new places.AutocompleteSessionToken());

        return () => setAutocompleteService(null);
    }, [places]);

    const fetchPredictions = async (inputValue) => {
        if (!autocompleteService || !inputValue) {
            return;
        }

        const predictionsToItems = (predictions) => {
            const items = predictions.map((item) => {
                return {
                    id: item.place_id,
                    text: item.description,
                };
            });

            return items;
        };

        const response = await autocompleteService.getPlacePredictions({
            input: inputValue,
            componentRestrictions: { country: countryCode },
            sessionToken,
        });

        setItems(predictionsToItems(response.predictions));
    };

    const handleInputChange = (value) => {
        debounce(inputOnChangeTimeoutId, () => fetchPredictions(value), {
            trailing: true,
            delay: 300,
        });
    };

    const handleOnChange = (event) => {
        if (!places) {
            logger.log('Places library not loeaded, skip handleOnChange');
            return;
        }

        const predictionPlaceId = event.selectedItem?.id;

        if (_.isUndefined(predictionPlaceId)) {
            handleOnPlaceSelect(null);
        } else {
            const detailRequestOptions = {
                placeId: predictionPlaceId,
                fields: ['address_components'],
                sessionToken,
            };

            const detailsRequestCallback = (placeDetails) => {
                handleOnPlaceSelect(placeDetails);
                setSessionToken(new places.AutocompleteSessionToken());
            };

            placesService?.getDetails(
                detailRequestOptions,
                detailsRequestCallback
            );
        }
    };

    const handleOnPlaceSelect = (placeDetails) => {
        if (_.isFunction(onPlaceSelectCallback)) {
            const getAddressComponentByType = (types, name = 'long_name') => {
                return placeDetails?.address_components.find((o) =>
                    o.types.some((type) => types.includes(type))
                )?.[name];
            };

            const address = !_.isNil(placeDetails)
                ? {
                      streetName: getAddressComponentByType(['route']),
                      houseNumber: getAddressComponentByType(['street_number']),
                      postalCode: getAddressComponentByType(['postal_code']),
                      city: getAddressComponentByType([
                          'locality',
                          'postal_town',
                      ]),
                      country: getAddressComponentByType(['country']),
                      countryCode: getAddressComponentByType(
                          ['country'],
                          'short_name'
                      ),
                  }
                : undefined;

            logger.log('placeDetails', placeDetails);
            logger.log('mapped address', address);

            onPlaceSelectCallback(address);
        }
    };

    return (
        <div className="eds-autocomplete-address">
            <ComboBox
                autoAlign={true}
                direction="bottom"
                className="eds-dropdown"
                id={name}
                name={name}
                titleText={label}
                onInputChange={handleInputChange}
                onChange={handleOnChange}
                items={items}
                itemToString={(item) => (item ? item.text : '')}
            />
        </div>
    );
};
