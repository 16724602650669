import { getRequest, postRequest } from '../uds/api';
import { getLogger } from '../utils';

const logger = getLogger('Auth');

const login = () => {
    getRequest('/auth/login')
        .then((data) => {
            logger.log(data);
            window.location.href = data.url;
        })
        .catch((error) => logger.error(error));
};

const callback = async (data) => {
    return await postRequest('/auth/callback', data, null, false);
};

const me = async () => {
    logger.log('window', window.location);
    return await getRequest(
        '/me',
        null,
        // we want to redirect to login if there is a unauth call made, but not when this is called while processing the auth callback or on login page
        ['/auth/callback', '/auth/login'].includes(
            window.location.href.pathname
        )
    );
};

const getSocketAuth = async () => {
    return await getRequest('/auth/socket');
};

const logout = async () => {
    await getRequest('/auth/logout');
};

const selectTenant = async (tenantId) => {
    return await postRequest('/tenants', {
        id: tenantId,
    });
};

const selectAccount = async (accountId) => {
    return await postRequest('/accounts', {
        id: accountId,
    });
};

export default {
    login,
    callback,
    me,
    getSocketAuth,
    logout,
    selectTenant,
    selectAccount,
};
