import { useTranslation } from 'react-i18next';
import {
    EdsFilterTag,
    EdsInlineNotificationsWrapper,
    EdsLink,
    useTableFilter,
} from '../../';
import './eds-table-filter-toolbar.scss';
import { TableToolbar, TableToolbarContent } from '@carbon/react';
import _ from 'lodash';

export function EdsFilterToolbar(props) {
    const { t } = useTranslation();
    const { filters, savedFilters } = useTableFilter();

    let combinedFilters = [...props.filters, ...filters];

    const getFilter = (filter, index) => {
        return (
            <EdsFilterTag
                title={t('e7b59a244d2579e249f51e7dc735e56b', 'Clear filter')}
                key={index}
                onClose={filter.onClose}
                text={`${filter.key} - ${filter.content}`}
            />
        );
    };

    if (combinedFilters?.length > 0) {
        return (
            <div className="eds-table-filter-toolbar">
                <TableToolbar>
                    <TableToolbarContent>
                        <div className="toolbar-label">
                            {t('b2c97ae425dd751b0e48a3acae79cf4a', 'Filter')}
                        </div>
                        {combinedFilters?.map(getFilter)}
                    </TableToolbarContent>
                </TableToolbar>
            </div>
        );
    } else if (savedFilters?.length > 0 && props.showSavedFilters) {
        return (
            <div className="eds-table-filter-toolbar">
                <EdsInlineNotificationsWrapper
                    notifications={savedFilters.map((filter) => {
                        return {
                            ...filter,
                            title: t(
                                'c5ea862c2d5d0d68ab27cf93e4f7d8d4',
                                'Continue with previous search'
                            ),
                            inlineaction: (
                                <EdsLink
                                    label={t(
                                        '09ecb1504b67de643446502f8080fb22',
                                        'Yes, use search.'
                                    )}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (_.isFunction(filter.onClick)) {
                                            filter.onClick();
                                        }
                                    }}
                                />
                            ),
                        };
                    })}
                />
            </div>
        );
    }
    return null;
}
