import { CheckmarkFilled, ErrorFilled } from '@carbon/icons-react';
import './eds-table-state.scss';
import _ from 'lodash';

export function EdsTableState({ state, label, validState, invalidState }) {
    return (
        <div className="eds-table-state">
            {!_.isUndefined(validState) && state === validState && (
                <CheckmarkFilled className="eds-success" size="20" />
            )}

            {!_.isUndefined(invalidState) && state === invalidState && (
                <ErrorFilled className="eds-danger" size="20" />
            )}
            <div>{label}</div>
        </div>
    );
}
