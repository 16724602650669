import _ from 'lodash';
import './eds-link.scss';
import { Link, useParams } from 'react-router-dom';
import { getLink } from '../../../features';

export function EdsLink(props) {
    const params = useParams();

    return (
        <Link
            onClick={(e) => e.stopPropagation()}
            {...props}
            to={getLink(props.to, params)}
            className={'eds-link'}
        >
            {props.icon && (
                <div className="link-icon">{<props.icon size="16" />}</div>
            )}
            <div>{props.label}</div>
        </Link>
    );
}

export function EdsOptionalLink(props) {
    const { id, to, label, icon, ...otherProps } = props;

    if (_.isNil(id)) {
        return null;
    }

    return <EdsLink to={to} icon={icon} label={label} {...otherProps} />;
}
