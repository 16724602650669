export const ChannelsActions = {
    OpenedSocket: 'OPENED-SOCKET',
    ClosedSocket: 'CLOSED-SOCKET',
    SetLoading: 'SET-LOADING',
};

export const channelsReducer = (state, action) => {
    switch (action.type) {
        case ChannelsActions.SetLoading:
            return {
                ...state,
                loading: !!action.payload,
            };
        case ChannelsActions.OpenedSocket:
            return {
                ...state,
                socket: action.payload?.socket,
                channel: action.payload?.channel,
                loading: false,
            };
        case ChannelsActions.ClosedSocket:
            return {
                ...state,
                socket: null,
                channel: null,
                loading: false,
            };
    }
};
