import { redirect } from 'react-router-dom';
import { getResidence, getResidenceByUID } from '../../../../features';
import { validate as uuidValidate } from 'uuid';

export const pageLoader = async ({ params }) => {
    try {
        if (uuidValidate(params.residenceId)) {
            return await getResidenceByUID(params.residenceId);
        } else {
            return await getResidence(params.residenceId);
        }
    } catch (ex) {
        return redirect('..');
    }
};
