import { OverflowMenu, OverflowMenuItem } from '@carbon/react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

export function EdsOverFlowMenu(props) {
    const { t } = useTranslation();

    return (
        <OverflowMenu
            className={props.className}
            kind={props.kind}
            iconDescription={
                props.iconDescription ??
                t('8d6ab84ca2af9fccd4e4048694176ebf', 'Menu')
            }
        >
            {_.isArray(props.items) &&
                props.items.map((item) => (
                    <OverflowMenuItem
                        key={item.name}
                        itemText={item.name}
                        onClick={item.onClick}
                    />
                ))}
        </OverflowMenu>
    );
}
