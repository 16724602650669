import { getRequest, postRequest, putRequest, deleteRequest } from '../api';

export const getAlarmSettings = async () => {
    return getRequest('/maintenance/alarm/settings');
};

export const putAlarmSettings = async (alarmSettings) => {
    return putRequest('/maintenance/alarm/settings', alarmSettings);
};

export const getAlarmReasonCategories = async () => {
    return getRequest('/maintenance/alarm/reason-categories');
};

export const getAlarmReasonCategory = async (id) => {
    return getRequest('/maintenance/alarm/reason-categories/' + id);
};

export const postAlarmReasonCategory = async (alarmReasonCategory) => {
    return postRequest(
        '/maintenance/alarm/reason-categories',
        alarmReasonCategory
    );
};

export const putAlarmReasonCategory = async (alarmReasonCategory) => {
    return putRequest(
        '/maintenance/alarm/reason-categories/' + alarmReasonCategory.id,
        alarmReasonCategory
    );
};

export const deleteAlarmReasonCategory = async (id) => {
    return deleteRequest('/maintenance/alarm/reason-categories/' + id);
};

export const getAlarmReason = async (id) => {
    return getRequest('/maintenance/alarm/reasons/' + id);
};

export const postAlarmReason = async (alarmReason) => {
    return postRequest('/maintenance/alarm/reasons', alarmReason);
};

export const putAlarmReason = async (alarmReason) => {
    return putRequest(
        '/maintenance/alarm/reasons/' + alarmReason.id,
        alarmReason
    );
};

export const deleteAlarmReason = async (id) => {
    return deleteRequest('/maintenance/alarm/reasons/' + id);
};

export const getMaintenanceAlarmDetails = async () => {
    return getRequest('/maintenance/alarm/details');
};

export const getMaintenanceAlarmDetail = async (id) => {
    return getRequest('/maintenance/alarm/details/' + id);
};

export const postAlarmDetail = async (alarmDetail) => {
    return postRequest('/maintenance/alarm/details', alarmDetail);
};

export const putAlarmDetail = async (alarmDetail) => {
    return putRequest(
        '/maintenance/alarm/details/' + alarmDetail.id,
        alarmDetail
    );
};

export const deleteAlarmDetail = async (id) => {
    return deleteRequest('/maintenance/alarm/details/' + id);
};

export const getRenderedHelpCategories = async () => {
    return getRequest('/maintenance/alarm/rendered-help-categories');
};

export const getRenderedHelpCategory = async (id) => {
    return getRequest('/maintenance/alarm/rendered-help-categories/' + id);
};

export const postRenderedHelpCategory = async (renderedHelpCategory) => {
    return postRequest(
        '/maintenance/alarm/rendered-help-categories',
        renderedHelpCategory
    );
};

export const putRenderedHelpCategory = async (renderedHelpCategory) => {
    return putRequest(
        '/maintenance/alarm/rendered-help-categories/' +
            renderedHelpCategory.id,
        renderedHelpCategory
    );
};

export const deleteRenderedHelpCategory = async (id) => {
    return deleteRequest('/maintenance/alarm/rendered-help-categories/' + id);
};

export const getRenderedHelp = async (id) => {
    return getRequest('/maintenance/alarm/rendered-help/' + id);
};

export const postRenderedHelp = async (renderedHelp) => {
    return postRequest('/maintenance/alarm/rendered-help', renderedHelp);
};

export const putRenderedHelp = async (renderedHelp) => {
    return putRequest(
        '/maintenance/alarm/rendered-help/' + renderedHelp.id,
        renderedHelp
    );
};

export const deleteRenderedHelp = async (id) => {
    return deleteRequest('/maintenance/alarm/rendered-help/' + id);
};

export const getAlarmTypes = async () => {
    return getRequest('/maintenance/alarm/types');
};

export const getAlarmType = async (id) => {
    return getRequest('/maintenance/alarm/types/' + id);
};

export const postAlarmType = async (alarmType) => {
    return postRequest('/maintenance/alarm/types', alarmType);
};

export const putAlarmType = async (alarmType) => {
    return putRequest('/maintenance/alarm/types/' + alarmType.id, alarmType);
};

export const deleteAlarmType = async (id) => {
    return deleteRequest('/maintenance/alarm/types/' + id);
};

export const getAlarmProcedures = async () => {
    return getRequest('/maintenance/alarm/procedures');
};

export const getAlarmProcedure = async (id) => {
    return getRequest('/maintenance/alarm/procedures/' + id);
};

export const postAlarmProcedure = async (alarmProcedure) => {
    return postRequest('/maintenance/alarm/procedures', alarmProcedure);
};

export const putAlarmProcedure = async (alarmProcedure) => {
    return putRequest(
        '/maintenance/alarm/procedures/' + alarmProcedure.id,
        alarmProcedure
    );
};

export const deleteAlarmProcedure = async (id) => {
    return deleteRequest('/maintenance/alarm/procedures/' + id);
};

export const getAlarmCriteria = async () => {
    return getRequest('/maintenance/alarm/criteria');
};

export const getAlarmCriterion = async (id) => {
    return getRequest('/maintenance/alarm/criteria/' + id);
};

export const putAlarmCriterion = async (alarmCriterion) => {
    return putRequest(
        '/maintenance/alarm/criteria/' + alarmCriterion.id,
        alarmCriterion
    );
};
