import './eds-search.scss';
import { EdsContainer } from '../';
import { Button, Search, TableToolbarSearch } from '@carbon/react';
import { Add, Filter } from '@carbon/icons-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { getLogger } from '../../../features';

const logger = getLogger('EdsSearch');

export function EdsSearch(props) {
    const [inToolbar] = useState(props.inToolbar ?? false);
    const [placeholder] = useState(props.placeholder ?? false);
    const { t } = useTranslation();

    const onAddButtonClick = (event) => {
        if (showAddButton()) {
            props.onAddButtonClick(event);
        }
    };

    const onInputChange = (event) => {
        if (_.isFunction(props.onInputChange)) {
            props.onInputChange(event);
        }
    };

    const showAddButton = () => {
        return _.isFunction(props.onAddButtonClick);
    };

    if (inToolbar) {
        return (
            <div className="eds-search">
                <div className="eds-search-bar">
                    <TableToolbarSearch
                        placeholder={placeholder}
                        expanded={true}
                        onChange={onInputChange}
                        value={props.value ?? ''}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="eds-search">
            <EdsContainer withPadding={false}>
                <div className="eds-search-bar">
                    <Search
                        size="lg"
                        placeholder={placeholder}
                        labelText={t(
                            '06a943c59f33a34bb5924aaf72cd2995',
                            'Search'
                        )}
                        closeButtonLabelText={t(
                            '926d9a44fcbaf77015c6d43379bcbdc1',
                            'Clear search input'
                        )}
                        id="search-1"
                        value={props.value ?? ''}
                        onChange={() => {}}
                        onKeyDown={() => {}}
                    />
                    {props.filter && (
                        <Button
                            kind="ghost"
                            hasIconOnly={true}
                            label={t(
                                '2c9885d2b0c7e26971f60a90f33cf718',
                                'Filters'
                            )}
                            renderIcon={Filter}
                            iconDescription={t(
                                'b2c97ae425dd751b0e48a3acae79cf4a',
                                'Filter'
                            )}
                            onClick={() => {
                                logger.log('Clicked on filter');
                            }}
                        />
                    )}
                    {showAddButton() && props.addButtonTitle && (
                        <Button
                            kind="ghost"
                            renderIcon={Add}
                            iconDescription={t(
                                '34ec78fcc91ffb1e54cd85e4a0924332',
                                'Add'
                            )}
                            label={props.addButtonTitle}
                            onClick={onAddButtonClick}
                        >
                            {props.addButtonTitle}
                        </Button>
                    )}
                </div>
                {props.children}
            </EdsContainer>
        </div>
    );
}
