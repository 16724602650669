import { useRef, useState } from 'react';
import { EdsWizard } from '../../../eds';

import { useModal } from '../../../../features';

import _ from 'lodash';
import AdditionalInformationStep from './additional-information-step';
import SearchStep from './search-step';

export const UmoLinkMedicine = ({
    additionalLinkDataFilterCallback,
    modalId,
    linkBaseFilter,
    onSubmit,
}) => {
    const formRef = useRef(null);
    const wizardRef = useRef(null);
    const medicalPriorityRef = useRef(null);
    const { remove } = useModal();
    const [selected, setSelected] = useState({});

    return (
        <EdsWizard
            orientation="horizontal"
            onCancelClick={() => {
                remove(modalId);
            }}
            onSubmit={async (_event, form, isValid) => {
                if (!isValid) {
                    return false;
                }

                if (_.isFunction(onSubmit)) {
                    const response = await onSubmit(
                        { ...form, selected: selected },
                        isValid
                    );
                    remove(modalId);
                    return response;
                } else {
                    remove(modalId);
                    return true;
                }
            }}
            ref={{
                formRef: formRef,
                wizardRef: wizardRef,
            }}
        >
            <SearchStep
                id="select-medicine"
                medicalPriorityRef={medicalPriorityRef}
                selectedRow={selected}
                selectedRowCallback={setSelected}
                additionalDataFilterCallback={additionalLinkDataFilterCallback}
                baseFilter={linkBaseFilter}
            />
            <AdditionalInformationStep
                id="additional-information"
                ref={medicalPriorityRef}
            />
        </EdsWizard>
    );
};
