import './eds-input-element-button.scss';
import { IconButton } from '@carbon/react';
import _ from 'lodash';

export function EdsInputElementButton(props) {
    const {
        label,
        onClickCallback,
        getInputValueCallback,
        renderedIcon,
        children,
    } = props;

    const getValue = () => {
        if (_.isFunction(getInputValueCallback)) {
            return getInputValueCallback();
        }
        return '';
    };

    return (
        <div className="eds-input-element-btn-wrapper">
            {children}
            <IconButton
                className="eds-input-element-btn"
                wrapperClasses="eds-input-element-btn-popover-wrapper"
                label={label}
                kind={props.kind ?? 'ghost'}
                size={props.size ?? 'sm'}
                onClick={(event) => {
                    onClickCallback(getValue());
                    event.stopPropagation();
                }}
            >
                {renderedIcon ?? null}
            </IconButton>
        </div>
    );
}
