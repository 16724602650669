import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

//this component is used to scroll to the top after te route changes. used in the router.jsx
export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }

        const elementToScroll = document.querySelector('.content-wrap');
        if (elementToScroll) {
            document.querySelector('.content-wrap').scrollTo({
                top: 0,
                left: 0,
                behavior: 'instant',
            });
        }
    }, [pathname]);

    return null;
}
