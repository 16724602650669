import { Navigate } from 'react-router-dom';
import { usePermissions } from './context';

export const Restricted = ({
    to,
    requiresAll = true,
    redirect = false,
    children,
}) => {
    const { isAllowed } = usePermissions();

    if (isAllowed(to, requiresAll)) {
        return <>{children}</>;
    }

    if (redirect) {
        return <Navigate to="/" />;
    }

    return null;
};
