import {
    Outlet,
    RouterProvider,
    createBrowserRouter,
    redirect,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import App from './app';
import AuthRoutes from './routes/auth';
import CrmRoutes from './routes/crm';
import { EdsErrorPage } from './components/';
import ProtectedRoute from './routes/protected-route';
import { ProviderTree } from './provider-tree';
import axios from './features/utils/axios';
import { interceptorFunction } from './features';
import ScrollToTop from './features/utils/scroll-to-top';

const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const CustomRouterProvider = () => {
    const base = {
        path: '/',
        element: (
            <ProviderTree>
                <ScrollToTop />
                <App />
            </ProviderTree>
        ),
        errorElement: <EdsErrorPage />,
        children: [
            {
                index: true,
                loader: () => redirect('/account'),
            },
            AuthRoutes,
            {
                path: 'account',
                element: <ProtectedRoute />,
            },
            {
                id: 'account/id',
                path: 'account/:accountId',
                element: (
                    <ProtectedRoute>
                        <Outlet />
                    </ProtectedRoute>
                ),
                loader: ({ params }) => {
                    // setup initial interceptor so loader calls will not fail, this interceptor will get replaced in the AccountIdApiInterceptor component
                    return axios.interceptors.request.use(
                        interceptorFunction(params)
                    );
                },
                shouldRevalidate: () => false,
                children: [
                    { index: true, loader: () => redirect('crm') },
                    CrmRoutes,
                ],
            },
        ],
    };

    return <RouterProvider router={sentryCreateBrowserRouter([base])} />;
};
