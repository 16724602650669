import { useAuth } from '../../features';
import { Button } from '@carbon/react';
import './scss/login-page.scss';
import { Navigate } from 'react-router-dom';
import { useState } from 'react';
import {
    EdsDropdown,
    EdsForm,
    EdsFormGroup,
    EdsUISimpleContainer,
    EdsUISimpleContainerButtons,
} from '../../components/';
import { useTranslation } from 'react-i18next';

function SelectTenantPage() {
    const { t } = useTranslation();
    const {
        isAuthenticated,
        isTenantSelected,
        attributes,
        selectTenant,
        getInitialTenant,
    } = useAuth();

    const [selectedTenant, setSelectedTenant] = useState(getInitialTenant());

    if (!isAuthenticated) {
        return <Navigate to="/auth/login" />;
    }

    if (isTenantSelected) {
        return <Navigate to="/" />;
    }

    return (
        <EdsUISimpleContainer logo={true} selectedTenant={selectedTenant}>
            <div className="tentantheader">
                {t('3297870e63b40d7951d8d5f39449a79a', 'Select a tenant')}
            </div>

            <EdsForm
                hideDefaultSubmit={true}
                initValues={{ tenantDropdown: attributes?.tenants[0] }}
            >
                <EdsFormGroup>
                    <EdsDropdown
                        initialSelectedItem={attributes?.tenants[0]}
                        selectedItem={selectedTenant}
                        name={'tenantDropdown'}
                        items={attributes?.tenants}
                        itemToString={(item) => (item ? item.description : '')}
                        onChange={(item) =>
                            setSelectedTenant(item?.selectedItem)
                        }
                    />
                </EdsFormGroup>
            </EdsForm>

            <EdsUISimpleContainerButtons>
                <Button
                    onClick={() => {
                        selectTenant(
                            selectedTenant?.umoTenantIdentifier ||
                                attributes?.tenants[0].umoTenantIdentifier
                        );
                    }}
                >
                    {t('99938282f04071859941e18f16efcf42', 'Select')}
                </Button>
            </EdsUISimpleContainerButtons>
        </EdsUISimpleContainer>
    );
}

export default SelectTenantPage;
