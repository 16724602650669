import { useRef, useState } from 'react';
import { useModal } from '../../../../features';
import _ from 'lodash';
import { EdsWizard, EdsWizardOrientation } from '../../../eds';
import SearchStep from './search-step';
import AdditionalInformationStep from './additional-information-step';

function UmoLinkProfessionalCaregiver({ modalId, onSubmit, filterIds }) {
    const formRef = useRef(null);
    const wizardRef = useRef(null);
    const { remove } = useModal();
    const [selected, setSelected] = useState({});

    return (
        <EdsWizard
            orientation={EdsWizardOrientation.Horizontal}
            onCancelClick={() => {
                remove(modalId);
            }}
            onSubmit={async (_event, form, isValid) => {
                if (!isValid) {
                    return false;
                }

                if (_.isFunction(onSubmit)) {
                    const response = await onSubmit(
                        { ...form, selected: selected },
                        isValid
                    );
                    remove(modalId);
                    return response;
                } else {
                    remove(modalId);
                    return true;
                }
            }}
            ref={{
                formRef: formRef,
                wizardRef: wizardRef,
            }}
        >
            <SearchStep
                id="select-professional-caregiver"
                selectedRow={selected}
                selectedRowCallback={setSelected}
                filterIds={filterIds}
            />
            <AdditionalInformationStep id="additional-information" />
        </EdsWizard>
    );
}
export default UmoLinkProfessionalCaregiver;
