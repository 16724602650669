import { lazy } from 'react';
import { Outlet, redirect } from 'react-router-dom';
import {
    pageLoader as CountrySettingsPageLoader,
    cityPageLoader as CountrySettingsCityPageLoader,
} from './country/loader';
import { pageLoader as CitySettingsPageLoader } from './city/loader';
import {
    CrmMaintenanceResidencePermissionSet,
    Restricted,
} from '../../../../features';

const ResidenceSettingsPage = lazy(() => import('./residence-settings-page'));
const CountryPage = lazy(() => import('./country/country-page'));
const KeyLocationPage = lazy(() => import('./key-location/key-location-page'));
const PointOfEntryPage = lazy(() =>
    import('./point-of-entry/point-of-entry-page')
);
const ResidenceTypePage = lazy(() => import('./type/residence-type-page'));

const CountrySettingsPage = lazy(() =>
    import('./country/country-settings-page')
);
const RegionPage = lazy(() => import('./region/region-page'));
const CityPage = lazy(() => import('./city/city-page'));

const CitySettingsPage = lazy(() => import('./city/city-settings-page'));
const DistrictPage = lazy(() => import('./district/district-page'));

export const ResidenceSettingsRoutes = {
    path: 'residence',
    element: (
        <Restricted to={CrmMaintenanceResidencePermissionSet} redirect={true}>
            <Outlet />
        </Restricted>
    ),
    handle: {
        crumb: (t) =>
            t('4b4a78a86d185ab419730c437973ed1c', 'Residence settings'),
        documentTitleParent: true,
    },
    children: [
        {
            // empty path child to set base page (outlet) for this series of routes, and not CountrySettingsPage
            path: '',
            element: <ResidenceSettingsPage />,
            children: [
                {
                    index: true,
                    loader: () => redirect('country'),
                },
                {
                    path: 'country',
                    element: <CountryPage />,
                    handle: {
                        crumb: (t) =>
                            t('e909c2d7067ea37437cf97fe11d91bd0', 'Country'),
                    },
                },
                {
                    path: 'key-location',
                    element: <KeyLocationPage />,
                    handle: {
                        crumb: (t) =>
                            t(
                                '1970fa927314617c668affa49d7c51dd',
                                'Key location'
                            ),
                    },
                },
                {
                    path: 'point-of-entry',
                    element: <PointOfEntryPage />,
                    handle: {
                        crumb: (t) =>
                            t(
                                '25aa6d135647ba9f0a08b7d6d4d35829',
                                'Point of entry'
                            ),
                    },
                },
                {
                    path: 'type',
                    element: <ResidenceTypePage />,
                    handle: {
                        crumb: (t) =>
                            t('599dcce2998a6b40b1e38e8c6006cb0a', 'Type'),
                    },
                },
            ],
        },
        {
            // dummy country path so breadcrumb will go to country tab in ResidenceSettingsPage
            path: 'country',
            handle: {
                crumb: (t) => t('e909c2d7067ea37437cf97fe11d91bd0', 'Country'),
            },
            children: [
                {
                    path: ':countryId',
                    element: <CountrySettingsPage />,
                    loader: CountrySettingsPageLoader,
                    handle: {
                        crumb: (_t, data) => data.name,
                    },
                    children: [
                        {
                            index: true,
                            loader: () => redirect('regions'),
                        },
                        {
                            path: 'regions',
                            element: <RegionPage />,
                            handle: {
                                crumb: (t) =>
                                    t(
                                        '030c55dfd4d77bca0d066354690ca628',
                                        'Regions'
                                    ),
                            },
                        },
                        {
                            path: 'regions/:regionId',
                            //ID for pulling loader data in children pages, like: useRouteLoaderData('maintenance/residence/country/region');
                            id: 'maintenance/residence/country/region',
                            loader: CountrySettingsCityPageLoader,
                            handle: {
                                crumb: (_t, data) => data.name,
                            },
                            children: [
                                {
                                    index: true,
                                    loader: () => redirect('cities'),
                                },
                                {
                                    path: 'cities',
                                    element: <CityPage />,
                                    handle: {
                                        crumb: (t) =>
                                            t(
                                                'a66b311547bf3da88f01139271d5bb50',
                                                'Cities'
                                            ),
                                    },
                                },
                            ],
                        },
                        {
                            path: 'cities',
                            element: <CityPage />,
                            handle: {
                                crumb: (t) =>
                                    t(
                                        'a66b311547bf3da88f01139271d5bb50',
                                        'Cities'
                                    ),
                            },
                        },
                    ],
                },
                {
                    path: ':countryId',
                    loader: CountrySettingsPageLoader,
                    handle: {
                        crumb: (_t, data) => data.name,
                    },
                    children: [
                        {
                            path: 'regions/:regionId',
                            loader: CountrySettingsCityPageLoader,
                            handle: {
                                crumb: (_t, data) => data.name,
                            },
                            children: [
                                {
                                    // dummy cities path so breadcrumb will go to cities tab in ResidenceSettingsPage
                                    path: 'cities',
                                    handle: {
                                        crumb: (t) =>
                                            t(
                                                'a66b311547bf3da88f01139271d5bb50',
                                                'Cities'
                                            ),
                                    },
                                    children: [
                                        {
                                            path: ':cityId',
                                            element: <CitySettingsPage />,
                                            loader: CitySettingsPageLoader,
                                            handle: {
                                                crumb: (_t, data) => data.name,
                                            },
                                            children: [
                                                {
                                                    index: true,
                                                    loader: () =>
                                                        redirect('districts'),
                                                },
                                                {
                                                    path: 'districts',
                                                    element: <DistrictPage />,
                                                    handle: {
                                                        crumb: (t) =>
                                                            t(
                                                                '53c62ae3159d2a356b6b019413fb2b02',
                                                                'Districts'
                                                            ),
                                                    },
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            // dummy cities path so breadcrumb will go to cities tab in ResidenceSettingsPage
                            path: 'cities',
                            handle: {
                                crumb: (t) =>
                                    t(
                                        'a66b311547bf3da88f01139271d5bb50',
                                        'Cities'
                                    ),
                            },
                            children: [
                                {
                                    path: ':cityId',
                                    element: <CitySettingsPage />,
                                    loader: CitySettingsPageLoader,
                                    handle: {
                                        crumb: (_t, data) => data.name,
                                    },
                                    children: [
                                        {
                                            index: true,
                                            loader: () => redirect('districts'),
                                        },
                                        {
                                            path: 'districts',
                                            element: <DistrictPage />,
                                            handle: {
                                                crumb: (t) =>
                                                    t(
                                                        '53c62ae3159d2a356b6b019413fb2b02',
                                                        'Districts'
                                                    ),
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
};
