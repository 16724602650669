import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { pageLoader as ResidencePageLoader } from './residence-page-details/loader';
import {
    ResidenceCreatePermissionSet,
    ResidenceReadPermissionSet,
    Restricted,
} from '../../../features';

const ResidencePage = lazy(() => import('./residence-page'));
const ResidencePageNew = lazy(() => import('./residence-page-new'));
const ResidencePageDetails = lazy(() => import('./residence-page-details'));

const ResidenceTab = lazy(() =>
    import('./residence-page-details/residence-tab')
);
const DevicesTab = lazy(() => import('./residence-page-details/devices-tab'));
const CaregiversTab = lazy(() =>
    import('./residence-page-details/caregivers-tab')
);
const NotesTab = lazy(() => import('./residence-page-details/notes-tab'));
const HistoryTab = lazy(() => import('./residence-page-details/history-tab'));
const LogsTab = lazy(() => import('./residence-page-details/logs-tab'));

const ResidencePageRoutes = {
    path: 'residence',
    element: (
        <Restricted to={ResidenceReadPermissionSet} redirect={true}>
            <Outlet />
        </Restricted>
    ),
    handle: {
        crumb: (t) => t('cad4811efcdb1000aae7a6841d995713', 'Residence'),
        documentTitleParent: true,
    },
    children: [
        {
            index: true,
            element: <ResidencePage />,
        },
        {
            path: 'new',
            element: (
                <Restricted to={ResidenceCreatePermissionSet} redirect={true}>
                    <ResidencePageNew />
                </Restricted>
            ),
            handle: {
                crumb: (t) => t('22af645d1859cb5ca6da0c484f1f37ea', 'New'),
            },
        },
        {
            id: 'residence/id',
            path: ':residenceId',
            element: <ResidencePageDetails />,
            loader: ResidencePageLoader,
            handle: {
                crumb: (_t, data) => data.combinedAddressField,
                documentTitleParent: true,
            },
            children: [
                {
                    index: true,
                    element: <ResidenceTab />,
                    handle: {
                        //for tabs to find that this route is the index
                        index: true,
                    },
                },
                {
                    path: 'devices',
                    element: <DevicesTab />,
                    handle: {
                        crumb: (t) =>
                            t('e0212e54ec3a2a120ca0d321b3a60c78', 'Devices'),
                    },
                },
                {
                    path: 'caregivers',
                    element: <CaregiversTab />,
                    handle: {
                        crumb: (t) =>
                            t('bc77e7fcbeea66aa5fbcd998260f66f5', 'Caregivers'),
                    },
                },
                {
                    path: 'notes',
                    element: <NotesTab />,
                    handle: {
                        crumb: (t) =>
                            t('4358b5009c67d0e31d7fbf1663fcd3bf', 'Notes'),
                    },
                },
                {
                    path: 'history',
                    element: <HistoryTab />,
                    handle: {
                        crumb: (t) =>
                            t('3cd15f8f2940aff879df34df4e5c2cd1', 'History'),
                    },
                },
                {
                    path: 'logs',
                    element: <LogsTab />,
                    handle: {
                        crumb: (t) =>
                            t('2165e4fa5bddb65a31f6a0c495c2fa37', 'Logs'),
                    },
                },
            ],
        },
    ],
};

export default ResidencePageRoutes;
