import { useEffect, useRef } from 'react';
import { useNavigate } from '../';
import { useAuth } from './context';

export const Callback = () => {
    const { oidcCallback, isLoading } = useAuth();
    const navigate = useNavigate();
    const didInitialize = useRef(false);

    useEffect(() => {
        if (didInitialize.current) {
            return;
        }
        didInitialize.current = true;

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const params = {};
        for (const [key, value] of urlParams.entries()) {
            params[key] = value;
        }
        oidcCallback(params).then((resp) => {
            // todo add error handling
            if (resp) {
                navigate('/');
            } else {
                navigate('/?error');
            }
        });
    }, [oidcCallback, isLoading, navigate]);

    return <div>Loading...</div>;
};
