import { forwardRef, useState } from 'react';
import {
    EdsForm,
    EdsFormColumn,
    EdsFormGroup,
    EdsNumberInput,
    EdsPhoneNumber,
    EdsPhoneNumberType,
    EdsPhoneNumberTypeFormat,
    EdsTextInput,
    getAllFormatsDefaultPhoneNumberType,
} from '../../../eds';
import _ from 'lodash';
import { putPhoneNumber, useNotifications } from '../../../../features';
import { useTranslation } from 'react-i18next';
import { Row } from '@carbon/react';
import { useEffectOnMount } from '../../../../features/react/hooks';

const UmoPhoneNumbersForm = forwardRef((props, ref) => {
    const [formDefinition, setFormDefinition] = useState([]);
    const { showSuccess, showError } = useNotifications();
    const { t } = useTranslation();
    // Either: 'all', 'e164' or 'short'
    const phoneTypeFormat =
        props.phoneTypeFormat ?? EdsPhoneNumberTypeFormat.All; //single or range
    const [validateE164Format, setValidateE164Format] = useState(false);

    useEffectOnMount(async () => {
        setFormDefinition(await getFormDefinition());
    });

    const getFormDefinition = async () => {
        return {
            sortIndex: {
                value: '',
                validation: {
                    type: 'number',
                    min: 0,
                    required: false,
                },
            },
            number: {
                value: '',
                validation: {
                    type: 'phone-number',
                    max: 50,
                    required: true,
                },
            },
            phoneNumberTypeId: {
                value: await getAllFormatsDefaultPhoneNumberType(
                    phoneTypeFormat
                ),
                validation: {
                    type: 'dropdown',
                    required: true,
                },
                mapping: 'id',
                dependents: ['number'],
            },
        };
    };

    const saveData = async (form) => {
        if (_.isEmpty(form.sortIndex)) {
            //sortIndex can be empty but when empty it must be set to 0 for UDS
            form.sortIndex = 0;
        }

        if (!form.id) {
            return await props.postDataCallback(form);
        } else {
            return await putPhoneNumber(form);
        }
    };

    const onSubmit = async (_event, form, isValid) => {
        if (!isValid) {
            throw false;
        }
        try {
            const response = await saveData(form);
            showSuccess({
                title: t('536a056b710e94b16169efd17a4a657b', 'Saved'),
                content: t(
                    '281aef713c8dc9007cabb1e65448917d',
                    "{{entity}} '{{name}}' is saved",
                    {
                        entity: 'Phone number',
                        name: form.number,
                    }
                ),
            });

            return response;
        } catch (error) {
            showError({
                title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                content: t(
                    'df0a9d46baf7315909e4389a04786e3d',
                    'Oops something went wrong'
                ),
            });

            throw error;
        }
    };

    return (
        <EdsForm
            hideDefaultSubmit={props.hideDefaultSubmit}
            initValues={props.initValues}
            formDefinition={formDefinition}
            onSubmit={onSubmit}
            ref={ref}
        >
            <EdsFormGroup>
                <Row>
                    <EdsFormColumn>
                        <EdsNumberInput
                            name="sortIndex"
                            label={t(
                                '70a17ffa722a3985b86d30b034ad06d7',
                                'Order'
                            )}
                        ></EdsNumberInput>
                    </EdsFormColumn>
                </Row>
                <Row>
                    <EdsFormColumn>
                        <EdsPhoneNumber
                            name="number"
                            label={t(
                                '9b88e58612797d6b989681a16621ad63',
                                'Phone number'
                            )}
                            validateE164Format={validateE164Format}
                        />
                    </EdsFormColumn>
                    <EdsFormColumn>
                        <EdsPhoneNumberType
                            name="phoneNumberTypeId"
                            phoneTypeFormat={phoneTypeFormat}
                            label={t(
                                'e1ef131918144ec7adeb7ed253dec2ea',
                                'Phone number type'
                            )}
                            onChangeCallback={(event) => {
                                if (!_.isUndefined(event)) {
                                    setValidateE164Format(
                                        event?.selectedItem
                                            ?.validateE164Format ?? true
                                    );
                                }
                            }}
                        />
                    </EdsFormColumn>
                </Row>
                <Row>
                    <EdsFormColumn>
                        <EdsTextInput
                            name="remarks"
                            label={t(
                                '911c185c8f3c475cdca2ef9cf12166da',
                                'Remark'
                            )}
                        ></EdsTextInput>
                    </EdsFormColumn>
                </Row>
            </EdsFormGroup>
        </EdsForm>
    );
});
UmoPhoneNumbersForm.displayName = 'PhoneNumbersForm';

export default UmoPhoneNumbersForm;
