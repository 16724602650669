import { Row } from '@carbon/react';
import {
    EdsForm,
    EdsFormColumn,
    EdsFormGroup,
    EdsTextInput,
} from '../../../../components';
import { forwardRef, useState } from 'react';
import { EdsAutocompleteAddress } from '../../eds-autocomplete-address/eds-autocomplete-address';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const EdsAutocompleteAddressForm = forwardRef(({ apiKey, ...props }, ref) => {
    const { t } = useTranslation();
    const [autoCompletedAddress, setAutoCompletedAddress] = useState();

    const onSubmit = async (_event, form, isValid) => {
        if (!isValid) {
            throw false;
        }

        return autoCompletedAddress;
    };

    const renderAddressComponent = (name, label) => {
        return (
            <EdsFormColumn>
                <EdsTextInput
                    name={name}
                    label={label}
                    uncontrolledValue={autoCompletedAddress[name]}
                    readOnly={true}
                />
            </EdsFormColumn>
        );
    };

    return (
        <EdsForm
            hideDefaultSubmit={props.hideDefaultSubmit}
            onSubmit={onSubmit}
            ref={ref}
        >
            <EdsFormGroup>
                <Row>
                    <EdsFormColumn fullWidth>
                        <EdsAutocompleteAddress
                            apiKey={apiKey}
                            name="autocomplete-address"
                            label={t(
                                '3308f74d4e51dd802a6167a9c509523f',
                                'Lookup address'
                            )}
                            onPlaceSelectCallback={(address) => {
                                setAutoCompletedAddress(address);
                            }}
                        />
                    </EdsFormColumn>
                </Row>
                {!_.isUndefined(autoCompletedAddress) && (
                    <EdsFormGroup label={'Address preview'}>
                        <Row>
                            {renderAddressComponent(
                                'streetName',
                                t('1f4a9685e37e4f940d07a9f6b43dc83c', 'Street')
                            )}
                            {renderAddressComponent(
                                'houseNumber',
                                t(
                                    '61a0a33cf99a58d9cad0d5ed6deae269',
                                    'House number'
                                )
                            )}
                        </Row>
                        <Row>
                            {renderAddressComponent(
                                'postalCode',
                                t(
                                    'c8a03a9875d7fc4c023e1bebb23d07af',
                                    'Postal code'
                                )
                            )}
                            {renderAddressComponent(
                                'city',
                                t('4ed5d2eaed1a1fadcc41ad1d58ed603e', 'City')
                            )}
                        </Row>
                        <Row>
                            {renderAddressComponent(
                                'country',
                                t('e909c2d7067ea37437cf97fe11d91bd0', 'Country')
                            )}
                        </Row>
                    </EdsFormGroup>
                )}
            </EdsFormGroup>
        </EdsForm>
    );
});

EdsAutocompleteAddressForm.displayName = 'EdsAutocompleteAddressForm';
export default EdsAutocompleteAddressForm;
