import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { pageLoader as ClientPageLoader } from './details/loader';
import {
    AlarmReadPermissionSet,
    CareProviderReadPermissionSet,
    ClientCreatePermissionSet,
    ClientExtensionReadPermissionSet,
    ClientReadPermissionSet,
    getClientTitle,
    NotesReadPermissionSet,
    Restricted,
} from '../../../features';

const ClientPage = lazy(() => import('./client-page'));
const ClientPageNew = lazy(() => import('./new'));
const ClientPageDetails = lazy(() => import('./details'));

const ClientTab = lazy(() => import('./details/client-tab'));
const NotesTab = lazy(() => import('./details/notes-tab'));
const FinanceTab = lazy(() => import('./details/finance-tab'));
const CaregiversTab = lazy(() => import('./details/caregivers-tab'));
const DevicesTab = lazy(() => import('./details/devices-tab'));
const MedicalTab = lazy(() => import('./details/medical-tab'));
const DocumentsTab = lazy(() => import('./details/documents-tab'));
const HistoryTab = lazy(() => import('./details/history-tab'));
const LogsTab = lazy(() => import('./details/logs-tab'));

const ClientPageRoutes = {
    path: 'client',
    element: (
        <Restricted to={ClientReadPermissionSet} redirect={true}>
            <Outlet />
        </Restricted>
    ),
    handle: {
        crumb: (t) => t('62608e08adc29a8d6dbc9754e659f125', 'Client'),
        documentTitleParent: true,
    },
    children: [
        {
            index: true,
            element: <ClientPage />,
        },
        {
            path: 'new',
            element: (
                <Restricted to={ClientCreatePermissionSet} redirect={true}>
                    <ClientPageNew />
                </Restricted>
            ),
            handle: {
                crumb: (t) => t('22af645d1859cb5ca6da0c484f1f37ea', 'New'),
            },
        },
        {
            id: 'client/id',
            path: ':clientId',
            element: <ClientPageDetails />,
            loader: ClientPageLoader,
            handle: {
                crumb: (_t, data) => getClientTitle(data),
                documentTitleParent: true,
            },
            children: [
                {
                    index: true,
                    element: <ClientTab />,
                    handle: {
                        //for tabs to find that this route is the index
                        index: true,
                    },
                },
                {
                    path: 'notes',
                    element: (
                        <Restricted
                            to={[
                                NotesReadPermissionSet,
                                ClientExtensionReadPermissionSet,
                            ]}
                            requiresAll={false}
                            redirect={true}
                        >
                            <NotesTab />
                        </Restricted>
                    ),
                    handle: {
                        crumb: (t) =>
                            t('4358b5009c67d0e31d7fbf1663fcd3bf', 'Notes'),
                    },
                },
                {
                    path: 'finance',
                    element: <FinanceTab />,
                    handle: {
                        crumb: (t) =>
                            t('57336afd1f4b40dfd9f5731e35302fe5', 'Finance'),
                    },
                },
                {
                    path: 'caregivers',
                    element: (
                        <Restricted
                            to={CareProviderReadPermissionSet}
                            redirect={true}
                        >
                            <CaregiversTab />
                        </Restricted>
                    ),
                    handle: {
                        crumb: (t) =>
                            t('bc77e7fcbeea66aa5fbcd998260f66f5', 'Caregivers'),
                    },
                },
                {
                    path: 'devices',
                    element: (
                        <Restricted
                            to={ClientExtensionReadPermissionSet}
                            redirect={true}
                        >
                            <DevicesTab />
                        </Restricted>
                    ),
                    handle: {
                        crumb: (t) =>
                            t('e0212e54ec3a2a120ca0d321b3a60c78', 'Devices'),
                    },
                },
                {
                    path: 'medical',
                    element: <MedicalTab />,
                    handle: {
                        crumb: (t) =>
                            t('7cbdd4e997c3b8e759f8d579bb30f6f1', 'Medical'),
                    },
                },
                {
                    path: 'documents',
                    element: <DocumentsTab />,
                    handle: {
                        crumb: (t) =>
                            t('21f64da1e5792c8295b964d159a14491', 'Documents'),
                    },
                },
                {
                    path: 'history',
                    element: (
                        <Restricted to={AlarmReadPermissionSet} redirect={true}>
                            <HistoryTab />
                        </Restricted>
                    ),
                    handle: {
                        crumb: (t) =>
                            t('3cd15f8f2940aff879df34df4e5c2cd1', 'History'),
                    },
                },
                {
                    path: 'logs',
                    element: <LogsTab />,
                    handle: {
                        crumb: (t) =>
                            t('2165e4fa5bddb65a31f6a0c495c2fa37', 'Logs'),
                    },
                },
            ],
        },
    ],
};

export default ClientPageRoutes;
