import './eds-toggle.scss';
import { Toggle } from '@carbon/react';
import { useForm, useFormGroup } from '../eds-form';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

export function EdsToggle(props) {
    const { t } = useTranslation();
    const { name, onChange, labelText, labelA, labelB } = props;
    const { handleFormChange, getFormValue, formatLabel } = useForm();
    const { prefix, usePrefix } = useFormGroup();
    let prefixedName = usePrefix(name) ?? name;

    const getValue = () => {
        const value = getFormValue(name, prefix),
            defaultReturnValue = true;
        let returnValue;

        if (!_.isUndefined(value) && !_.isNull(value)) {
            if (!_.isObject(value)) {
                returnValue = value;
            } else if ('value' in value) {
                returnValue = value[value];
            }
        }
        return _.isBoolean(returnValue) ? returnValue : defaultReturnValue;
    };

    const handleOnChange = (toggled) => {
        const event = {
            target: {
                name: prefixedName,
                value: toggled,
                type: 'toggle',
            },
        };
        handleFormChange(event);

        if (_.isFunction(onChange)) {
            onChange(toggled, event);
        }
    };

    return (
        <Toggle
            className="eds-toggle"
            labelText={formatLabel(labelText, name, prefix)}
            labelA={labelA ?? t('32651a703ec04fc83ad9ae7be5983237', 'no')}
            labelB={labelB ?? t('dbb5eba8ef5cc7bde33928963b207f6e', 'yes')}
            id={prefixedName}
            name={prefixedName}
            toggled={getValue()}
            defaultToggled={true}
            onToggle={handleOnChange}
        />
    );
}
