import { getRequest, putRequest } from '../api';

export const getTenantSettings = async () => {
    return getRequest('/maintenance/tenant/settings');
};

export const putTenantSettings = async (tenantSettings) => {
    return putRequest('/maintenance/tenant/settings', tenantSettings);
};

export const getTenantGoogleMapsSettings = async () => {
    return getRequest('/maintenance/tenant/settings/googleMaps');
};

export const putTenantGoogleMapsSettings = async (tenantGoogleMapsSettings) => {
    return putRequest(
        '/maintenance/tenant/settings/googleMaps',
        tenantGoogleMapsSettings
    );
};
