import { lazy } from 'react';
import { Outlet, redirect } from 'react-router-dom';
import { pageLoader as MedicalDataCategorySettingsPageLoader } from './medical-data-category/loader';
import {
    CrmMaintenanceMedicalPermissionSet,
    Restricted,
} from '../../../../features';

const MedicalSettingsPage = lazy(() => import('./medical-settings-page'));
const CaregiverTypePage = lazy(() =>
    import('./caregiver-type/caregiver-type-page')
);
const MedicalDataCategoryPage = lazy(() =>
    import('./medical-data-category/medical-data-category-page')
);
const MedicinePage = lazy(() => import('./medicine/medicine-page'));
const MedicalPriorityPage = lazy(() =>
    import('./medical-priority/medical-priority-page')
);

const MedicalDataCategorySettingsPage = lazy(() =>
    import('./medical-data-category/medical-data-category-settings-page')
);
const MedicalDataPage = lazy(() => import('./medical-data/medical-data-page'));

export const MedicalSettingsRoutes = {
    path: 'medical',
    element: (
        <Restricted to={CrmMaintenanceMedicalPermissionSet} redirect={true}>
            <Outlet />
        </Restricted>
    ),
    handle: {
        crumb: (t) => t('ed3fdaecbc1a438a59485a72986a6b3f', 'Medical settings'),
        documentTitleParent: true,
    },
    children: [
        {
            path: '',
            element: <MedicalSettingsPage />,
            children: [
                {
                    index: true,
                    loader: () => redirect('caregiver-type'),
                },
                {
                    path: 'caregiver-type',
                    element: <CaregiverTypePage />,
                    handle: {
                        crumb: (t) =>
                            t(
                                'f610124a07c580056177271d6664e137',
                                'Caregiver type'
                            ),
                    },
                },
                {
                    path: 'data-category',
                    element: <MedicalDataCategoryPage />,
                    handle: {
                        crumb: (t) =>
                            t(
                                '24e9f5543ccf581ff0afa6627e8e083d',
                                'Data category'
                            ),
                    },
                },
                {
                    path: 'medicine',
                    element: <MedicinePage />,
                    handle: {
                        crumb: (t) =>
                            t('d9e5d212320e7d96e921831554be696d', 'Medicine'),
                    },
                },
                {
                    path: 'priority',
                    element: <MedicalPriorityPage />,
                    handle: {
                        crumb: (t) =>
                            t('b988295c268025b49dfb3df26171ddc3', 'Priority'),
                    },
                },
            ],
        },
        {
            // dummy data-category path so breadcrumb will go to data-category tab in MedicalSettingsPage
            path: 'data-category',
            handle: {
                crumb: (t) =>
                    t('24e9f5543ccf581ff0afa6627e8e083d', 'Data category'),
            },
            children: [
                {
                    path: ':medicalDataCategoryId',
                    element: <MedicalDataCategorySettingsPage />,
                    loader: MedicalDataCategorySettingsPageLoader,
                    handle: {
                        crumb: (_t, data) => data.name,
                    },
                    children: [
                        {
                            index: true,
                            loader: () => redirect('data'),
                        },
                        {
                            path: 'data',
                            element: <MedicalDataPage />,
                            handle: {
                                crumb: (t) =>
                                    t(
                                        '8d777f385d3dfec8815d20f7496026dc',
                                        'Data'
                                    ),
                            },
                        },
                    ],
                },
            ],
        },
    ],
};
