import { Checkbox } from '@carbon/react';
import { EdsTooltipCheckbox } from '../';
import { useForm, useFormGroup } from '../eds-form';
import _ from 'lodash';

export function EdsCheckbox(props) {
    const {
        name,
        labelText,
        readOnly,
        readOnlyLabel,
        disabled,
        onChangeCallback,
    } = props;
    const { getFormValue, handleFormChange } = useForm();
    const { prefix, usePrefix } = useFormGroup();
    let prefixedName = usePrefix(name) ?? name;

    const getValue = () => {
        if (!_.isUndefined(props?.uncontrolledValue)) {
            return props?.uncontrolledValue;
        }

        let defaultReturnValue = false;
        let returnValue = defaultReturnValue;
        const value = getFormValue(name, prefix);

        if (!_.isUndefined(value)) {
            if (_.isPlainObject(value) && 'value' in value) {
                returnValue = value[value];
            } else {
                returnValue = value;
            }

            if (!_.isBoolean(returnValue)) {
                returnValue = defaultReturnValue;
            }
        }
        return returnValue;
    };

    const handleOnChange = (event) => {
        handleFormChange(event);

        if (_.isFunction(onChangeCallback)) {
            onChangeCallback(event);
        }
    };

    return (
        <EdsTooltipCheckbox
            label={readOnlyLabel}
            hidden={!readOnly || !readOnlyLabel}
        >
            <Checkbox
                checked={getValue(name, prefix)}
                labelText={labelText}
                id={prefixedName}
                name={prefixedName}
                onChange={handleOnChange}
                readOnly={readOnly ?? false}
                disabled={disabled ?? false}
            />
        </EdsTooltipCheckbox>
    );
}
