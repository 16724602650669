import { redirect } from 'react-router-dom';
import { getRenderedHelpCategory } from '../../../../../features';

export const pageLoader = async ({ params }) => {
    try {
        return await getRenderedHelpCategory(params.renderedHelpCategoryId);
    } catch (ex) {
        return redirect('..');
    }
};
