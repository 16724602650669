import { useRef, useState } from 'react';
import { EdsContainerTable, EdsTableCheckmark, EdsTableDate } from '../../..';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
    deleteClientSubscription,
    getClientSubscription,
    getClientSubscriptions,
    getLogger,
    useModal,
} from '../../../../features';
import UmoSubscriptionForm from './umo-subscription-form.jsx';

let logger = getLogger('UmoLinkedSubscriptionsTable');

export const UmoLinkedSubscriptionsTable = ({
    clientId,
    groupId,
    ...props
}) => {
    const { t } = useTranslation();
    const formRef = useRef(null);
    const tableRef = useRef(null);
    const { showDeleteModal, showFormModal } = useModal();
    const [title] = useState(
        props.title ?? t('2d5d14f95af035cbd8437948de61f94c', 'Subscriptions')
    );

    const [hideWhenNoData] = useState(props.hideWhenNoData ?? true);
    const [hasData, setHasData] = useState();

    const getData = async () => {
        let data = await getClientSubscriptions(clientId);
        setHasData(!_.isEmpty(data));
        return data;
    };

    if (
        hideWhenNoData &&
        !_.isUndefined(hasData) &&
        _.isBoolean(hasData) &&
        !hasData
    ) {
        return null;
    }

    const mappingCallback = (responseData) => {
        return {
            id: responseData.id,
            subscription: responseData?.subscription?.name,
            description: responseData?.subscription?.description,
            validFrom: <EdsTableDate value={responseData.startDate} />,
            validTo: <EdsTableDate value={responseData.endDate} />,
            remark: responseData?.remark,
            isOneTimeCost: (
                <EdsTableCheckmark
                    value={responseData?.subscription?.isOneTimeCost}
                />
            ),
            cost: responseData?.cost,
        };
    };

    const showDataModal = (clientSubscription = {}) => {
        showFormModal({
            newTitle: t('c32ef9a27e41e16834e6cdc11c37f38a', 'New subscription'),
            editTitle: t(
                '4f288caef72a849b333906ca7425dd2e',
                'Edit subscription'
            ),
            formData: clientSubscription,
            formRef,
            tableRef,
            onSubmitFormResponse: async (response, removeModal) => {
                if (response) {
                    tableRef.current?.fetchInitData();
                }
                removeModal();
            },
            children: (
                <UmoSubscriptionForm
                    ref={formRef}
                    initValues={clientSubscription}
                    hideDefaultSubmit={true}
                    clientId={clientId}
                    groupId={groupId}
                />
            ),
        });
    };

    const getDataRow = async (row) => {
        try {
            let clientSubscription = await getClientSubscription(
                clientId,
                row.id
            );
            showDataModal({
                id: {
                    value: clientSubscription.id,
                },

                subscriptionId: {
                    id: clientSubscription.subscription.id,
                    text: clientSubscription.subscription.name,
                    description: clientSubscription.subscription.description,
                    remark: clientSubscription.remark,
                    isOneTimeCost:
                        clientSubscription.subscription.isOneTimeCost,
                },
                startDate: {
                    value: clientSubscription.startDate,
                },
                endDate: {
                    value: clientSubscription.endDate,
                },
                remark: {
                    value: clientSubscription.remark,
                },
                cost: {
                    value: clientSubscription.cost,
                },
            });
        } catch (ex) {
            logger.warn('getExternalIdentifier UDS Exception:', ex);
        }
    };

    return (
        <EdsContainerTable
            hidden={_.isUndefined(hasData)}
            title={title}
            ref={tableRef}
            headers={[
                {
                    key: 'subscription',
                    header: t(
                        'b48b13e73a6ac2a86dc54425dd24d9ff',
                        'Subscription'
                    ),
                },
                {
                    key: 'description',
                    header: t(
                        '67daf92c833c41c95db874e18fcb2786',
                        'Description'
                    ),
                },
                {
                    key: 'validFrom',
                    header: t('167b2d7828511b4bdbb6d642029bdaff', 'Valid from'),
                },
                {
                    key: 'validTo',
                    header: t('6e1f5c87ac9e0d969abb679a0603b54d', 'Valid to'),
                },
                {
                    key: 'remark',
                    header: t('911c185c8f3c475cdca2ef9cf12166da', 'Remark'),
                },
                {
                    key: 'isOneTimeCost',
                    header: t(
                        'ac8e0cbd39060b0c224fc2394da64275',
                        'One-time cost'
                    ),
                },
                {
                    key: 'cost',
                    header: t('4e1566f0798fb3d6f350720cacd74446', 'Cost'),
                },
            ]}
            getDataCallback={getData}
            mappingCallback={mappingCallback}
            onEditClick={(row) => {
                getDataRow(row);
            }}
            onDeleteClick={(row) => {
                let data = tableRef.current?.getRowData(row.id);
                showDeleteModal({
                    name: data?.subscription,
                    tableRef,
                    row,
                    deleteHandler: async () => {
                        await deleteClientSubscription(clientId, row.id);
                    },
                });
            }}
            onHeaderAddClick={() => showDataModal()}
            {...props}
        />
    );
};
