import { FileUploaderDropContainer, FileUploaderItem } from '@carbon/react';
import './eds-file-upload.scss';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useFormGroup } from '../eds-form';
import _ from 'lodash';

export const FileUploadStatus = Object.freeze({
    Uploading: 'uploading',
    Edit: 'edit',
    Complete: 'complete',
});

export function EdsFileUpload(props) {
    const {
        name,
        label,
        description,
        forceRequired,
        filenameStatus,
        accept,
        multiple,
        disabled,
    } = props;
    const { updateFormValues, formatLabel, isInvalid, getInvalidText } =
        useForm();
    const { prefix, usePrefix } = useFormGroup();
    let prefixedName = usePrefix(name) ?? name;
    const { t } = useTranslation();
    const [labelText] = useState(
        label ?? t('f79fb7bb1b4b2ac44b7d7b6cf17d9037', 'Upload file')
    );

    const [files, setFiles] = useState([]);

    const updateFiles = (updatedFiles) => {
        setFiles(updatedFiles);
        updateFormValues({ [prefixedName]: updatedFiles });
    };

    return (
        <div className="cds--file-container">
            <p className="cds--file--label">
                {formatLabel(labelText, name, prefix, forceRequired)}
            </p>
            <p className="cds--label-description">{description}</p>
            {(multiple || _.isEmpty(files)) && (
                <FileUploaderDropContainer
                    labelText={t(
                        '19c42f3f455e3bc97c0e95ea76f2efd8',
                        'Drag and drop files here or click to upload'
                    )}
                    accept={accept}
                    multiple={multiple}
                    disabled={disabled}
                    name={prefixedName}
                    onChange={(event) => {
                        event.name = prefixedName;
                        if (event.target?.files) {
                            let updatedFiles = Array.from(event.target.files);
                            updateFiles(updatedFiles);
                        }
                    }}
                    onAddFiles={(event) => {
                        event.name = prefixedName;

                        let eventFiles =
                            event.dataTransfer?.files || event.target?.files;

                        if (eventFiles) {
                            let updatedFiles = files;
                            let newFiles = Array.from(eventFiles);
                            for (let index in newFiles) {
                                let newFile = newFiles[index];
                                updatedFiles.push(newFile);
                            }
                            updateFiles(updatedFiles);
                        }
                    }}
                />
            )}
            {files.map((file, index) => {
                return (
                    <FileUploaderItem
                        key={index}
                        name={file.name}
                        status={filenameStatus}
                        errorSubject={getInvalidText(prefixedName)}
                        invalid={isInvalid(prefixedName)}
                        iconDescription={t(
                            '17b07089821ce823967324d1058ab7c9',
                            'Delete file'
                        )}
                        onDelete={() => {
                            let updatedFiles = files;
                            let index = updatedFiles.indexOf(file);
                            updatedFiles.splice(index, 1);
                            updateFiles(updatedFiles);
                        }}
                    />
                );
            })}
        </div>
    );
}
