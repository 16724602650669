import { useRef } from 'react';
import {
    EdsTableDeleteButton,
    EdsTableImageButton,
    EdsTableEditButton,
    EdsTableLogButton,
    EdsTableUnlinkButton,
    EdsTableDownloadButton,
} from '../../';
import { debounce } from '../../../../features';
import './eds-table-actionbar.scss';
import _ from 'lodash';

export const EdsActionButtonTypes = Object.freeze({
    Edit: 'EDIT',
    Download: 'DOWNLOAD',
    Image: 'IMAGE',
    Delete: 'DELETE',
    Unlink: 'UNLINK',
    Log: 'LOG',
});

export function EdsTableActionbar(props) {
    const { row, actionButtons, hideActionButtons = [], size } = props;
    const buttonClickTimeoutId = useRef(null);

    const onHandleButtonClick = (btnOnClick, row) => {
        if (_.isFunction(btnOnClick)) {
            debounce(
                buttonClickTimeoutId,
                () => {
                    btnOnClick(row);
                },
                { delay: 500 }
            );
        }
    };

    const onHandleShowLabel = (btnOnShowLabel, row) => {
        if (_.isFunction(btnOnShowLabel)) {
            return btnOnShowLabel(row);
        }
    };

    const renderActionButton = (actionButton) => {
        const props = {
            onClick: () => {
                onHandleButtonClick(actionButton.onClick, row);
            },
            size: size,
            label: _.isFunction(actionButton.label)
                ? onHandleShowLabel(actionButton.label, row)
                : actionButton.label,
            align: actionButton.align,
        };

        if (hideActionButtons.includes(actionButton.type)) {
            return;
        }

        switch (actionButton.type) {
            case EdsActionButtonTypes.Edit:
                return (
                    <EdsTableEditButton key={actionButton.type} {...props} />
                );
            case EdsActionButtonTypes.Download:
                return (
                    <EdsTableDownloadButton
                        key={actionButton.type}
                        {...props}
                    />
                );
            case EdsActionButtonTypes.Image:
                return (
                    <EdsTableImageButton key={actionButton.type} {...props} />
                );
            case EdsActionButtonTypes.Delete:
                return (
                    <EdsTableDeleteButton key={actionButton.type} {...props} />
                );
            case EdsActionButtonTypes.Unlink:
                return (
                    <EdsTableUnlinkButton key={actionButton.type} {...props} />
                );
            case EdsActionButtonTypes.Log:
                return <EdsTableLogButton key={actionButton.type} {...props} />;
            default:
                return;
        }
    };

    if (actionButtons.length === 0) {
        return;
    }

    return (
        <div className="eds-table-actionbar">
            {actionButtons.map((actionButton) => {
                return renderActionButton(actionButton);
            })}
        </div>
    );
}
