import './assets/scss/app.scss';
import { useAuth } from './features';
import { Outlet } from 'react-router-dom';

import { useEffect } from 'react';
import { EdsLoading } from './components';

function App() {
    const { isLoading, isAuthenticated, checkAuth } = useAuth();

    useEffect(() => {
        checkAuth(isAuthenticated);
    }, [isAuthenticated, checkAuth]);

    if (isLoading) {
        return <EdsLoading wholePage={true} />;
    }

    return <Outlet />;
}

export default App;
