export const NotificationsActions = {
    ShowNotification: 'SHOW-NOTIFICATION',
    RemoveNotification: 'REMOVE-NOTIFICATION',
};

export const notificationsReducer = (state, action) => {
    switch (action.type) {
        case NotificationsActions.RemoveNotification: {
            return {
                ...state,
                notifications: state.notifications.filter(
                    (notification) => notification.id !== action.id
                ),
            };
        }
        case NotificationsActions.ShowNotification: {
            return {
                ...state,
                notifications: [...state.notifications, action.payload],
            };
        }
    }
};
