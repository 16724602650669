import { redirect } from 'react-router-dom';
import { getCareTeam } from '../../../../features';

export const pageLoader = async ({ params }) => {
    try {
        return await getCareTeam(params.careTeamId);
    } catch (ex) {
        return redirect('..');
    }
};
