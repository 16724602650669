import { redirect } from 'react-router-dom';
import { getMedicalDataCategory } from '../../../../../features';

export const pageLoader = async ({ params }) => {
    try {
        return await getMedicalDataCategory(params.medicalDataCategoryId);
    } catch (ex) {
        return redirect(
            `/crm/maintenance/medical/data-category/${params.medicalDataCategoryId}`
        );
    }
};
