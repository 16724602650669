import { validate as uuidValidate } from 'uuid';
import { getClient, getClientByUID } from '../../../../features';
import { redirect } from 'react-router-dom';

export const pageLoader = async ({ params }) => {
    try {
        if (uuidValidate(params.clientId)) {
            return await getClientByUID(params.clientId);
        } else {
            return await getClient(params.clientId);
        }
    } catch (ex) {
        return redirect('..');
    }
};
