import {
    createSearchFilterStoreFunctions,
    createStateManagementFunctions,
    getRequest,
    postRequest,
    putRequest,
} from '../api';

const basePath = '/peripherals';

export const getPeripheralSearch = async (search) => {
    return getRequest(`${basePath}/search`, search);
};

export const savePeripheral = async (peripheral) => {
    return postRequest(`${basePath}`, peripheral);
};

export const getPeripheral = async (id) => {
    return getRequest(`${basePath}/${id}`);
};

export const putPeripheral = async (peripheral) => {
    return putRequest(`${basePath}/${peripheral.id}`, peripheral);
};

export const getPeripheralStateHistory = async (peripheralId) => {
    return getRequest(`${basePath}/${peripheralId}/history/state`);
};

export const getPeripheralLocationHistory = async (peripheralId) => {
    return getRequest(`${basePath}/${peripheralId}/history/location`);
};

export const stateManagement = {
    ...createStateManagementFunctions(basePath, 'peripherals'),
};

export const searchFilterStore = {
    ...createSearchFilterStoreFunctions(basePath, 'peripherals'),
};
