import { Outlet } from 'react-router-dom';
import { EdsUiShell } from '../../components/';
import { Suspense } from 'react';

function CrmPage() {
    return (
        <EdsUiShell>
            <Suspense>
                <Outlet />
            </Suspense>
        </EdsUiShell>
    );
}

export default CrmPage;
