import { redirect } from 'react-router-dom';
import { getProject } from '../../../../../features';

export const pageLoader = async ({ params }) => {
    try {
        return await getProject(params.projectId);
    } catch (ex) {
        return redirect('..');
    }
};
