import { redirect } from 'react-router-dom';
import { getDevice, getDeviceByUID } from '../../../../features';
import { validate as uuidValidate } from 'uuid';

export const pageLoader = async ({ params }) => {
    try {
        if (uuidValidate(params.deviceId)) {
            return await getDeviceByUID(params.deviceId);
        } else {
            return await getDevice(params.deviceId);
        }
    } catch (ex) {
        return redirect('..');
    }
};
