import { Loading } from '@carbon/react';
import './eds-loading.scss';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function EdsLoading(props) {
    const { t } = useTranslation();
    const [label] = useState(
        props.label ?? t('13d494fd35f9f5b14309b84466cbddf2', 'Loading...')
    );
    const [small] = useState(props.small ?? false);
    const [delay] = useState(props.delay ?? false);

    const getStyle = () => {
        let classes = ['eds-loading'];
        if (delay) {
            classes.push('delay');
        }
        if (props.inline) {
            classes.push('inline');
        }
        return classes.join(' ');
    };

    if (props.wholePage) {
        return <Loading className={'eds-loading-spinner'} />;
    }

    return (
        <div className={getStyle()}>
            <Loading
                className={'eds-loading-spinner'}
                withOverlay={false}
                small={small}
                description={label}
            />

            {!props.hideLabel && <div>{label}</div>}
        </div>
    );
}
