import { createContext, useContext } from 'react';

const EdsTableFilterContext = createContext({
    filters: [],
    savedFilters: {},
    addFilter: () => null,
    addFilters: () => null,
    removeFilter: () => null,
    clearFilter: () => null,
    setSearchTerm: () => null,
    setSorting: () => null,
});

export default EdsTableFilterContext;

export const useTableFilter = () => useContext(EdsTableFilterContext);
