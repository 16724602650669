import { lazy } from 'react';
import { redirect } from 'react-router-dom';
import {
    CrmMaintenanceDevicePermissionSet,
    Restricted,
} from '../../../../features';

const DeviceSettingsPage = lazy(() => import('./device-settings-page'));
const DeviceTypePage = lazy(() => import('./device-type/device-type-page'));
const DeviceStatePage = lazy(() => import('./device-state/device-state-page'));
const DeviceManagerPage = lazy(() =>
    import('./device-manager/device-manager-page')
);
const DeviceLocationPage = lazy(() =>
    import('./device-location/device-location-page')
);

export const DeviceSettingsRoutes = {
    path: 'device',
    element: (
        <Restricted to={CrmMaintenanceDevicePermissionSet} redirect={true}>
            <DeviceSettingsPage />
        </Restricted>
    ),
    handle: {
        crumb: (t) => t('8fb99a69d10919fda9487809870ea7b0', 'Device settings'),
        documentTitleParent: true,
    },
    children: [
        {
            index: true,
            loader: () => redirect('type'),
        },
        {
            path: 'type',
            element: <DeviceTypePage />,
            handle: {
                crumb: (t) => t('599dcce2998a6b40b1e38e8c6006cb0a', 'Type'),
            },
        },
        {
            path: 'state',
            element: <DeviceStatePage />,
            handle: {
                crumb: (t) => t('9ed39e2ea931586b6a985a6942ef573e', 'State'),
            },
        },
        {
            path: 'manager',
            element: <DeviceManagerPage />,
            handle: {
                crumb: (t) => t('1d0258c2440a8d19e716292b231e3190', 'Manager'),
            },
        },
        {
            path: 'location',
            element: <DeviceLocationPage />,
            handle: {
                crumb: (t) => t('d5189de027922f81005951e6efe0efd5', 'Location'),
            },
        },
    ],
};
