import { Row } from '@carbon/react';
import {
    EdsDropdown,
    EdsDropdownType,
    EdsForm,
    EdsFormColumn,
    EdsFormGroup,
    EdsTextInput,
} from '../../../../../components';
import { forwardRef } from 'react';
import {
    getRegions,
    postCity,
    putCity,
    useNotifications,
} from '../../../../../features';
import { useTranslation } from 'react-i18next';

const CityForm = forwardRef((props, ref) => {
    const { showSuccess, showError } = useNotifications();
    const { t } = useTranslation();
    const formDefinition = {
        name: {
            value: '',
            validation: {
                required: true,
                max: 60,
            },
        },
        regionId: {
            value: {
                id: props?.region?.id ?? '',
                text: props?.region?.name ?? '',
            },
            validation: {
                type: 'dropdown',
                required: false,
            },
            mapping: 'id',
        },
    };

    const saveData = async (form) => {
        form.countryId = props.countryId;

        if (!form.id) {
            return await postCity(form);
        } else {
            return await putCity(form);
        }
    };

    const onSubmit = async (_event, form, isValid) => {
        if (!isValid) {
            throw false;
        }

        try {
            const response = await saveData(form);

            showSuccess({
                title: t('536a056b710e94b16169efd17a4a657b', 'Saved'),
                content: t(
                    '281aef713c8dc9007cabb1e65448917d',
                    "{{entity}} '{{name}}' is saved",
                    {
                        entity: t('4ed5d2eaed1a1fadcc41ad1d58ed603e', 'City'),
                        name: form.name,
                    }
                ),
            });

            return response;
        } catch (error) {
            showError({
                title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                content: t(
                    'df0a9d46baf7315909e4389a04786e3d',
                    'Oops something went wrong'
                ),
            });

            throw error;
        }
    };

    return (
        <EdsForm
            hideDefaultSubmit={props.hideDefaultSubmit}
            initValues={props.initValues}
            formDefinition={formDefinition}
            onSubmit={onSubmit}
            ref={ref}
        >
            <EdsFormGroup>
                <Row>
                    <EdsFormColumn>
                        <EdsTextInput
                            name="name"
                            label={t(
                                'b068931cc450442b63f5b3d276ea4297',
                                'Name'
                            )}
                        ></EdsTextInput>
                    </EdsFormColumn>
                    <EdsFormColumn>
                        <EdsDropdown
                            name={'regionId'}
                            label={t(
                                '960db2ed82202a9706b97775a4269378',
                                'Region'
                            )}
                            type={EdsDropdownType.ComboBox}
                            getDataCallback={async () =>
                                await getRegions(props.countryId)
                            }
                        ></EdsDropdown>
                    </EdsFormColumn>
                </Row>
            </EdsFormGroup>
        </EdsForm>
    );
});

CityForm.displayName = 'CityForm';

export default CityForm;
