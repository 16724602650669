import _ from 'lodash';
import { deleteRequest, getRequest, postRequest, putRequest } from '../api';

export const getAffixes = async () => {
    return getRequest('/general/affixes');
};

export const getValidationMethods = async () => {
    return getRequest('/general/validation-methods');
};

export const getGenericConsumerStates = async () => {
    return getRequest('/general/consumer-states');
};

export const getNote = async (id) => {
    return getRequest('/general/notes/' + id);
};

export const putNote = async (note) => {
    return putRequest('/general/notes/' + note.id, note);
};

export const deleteNote = async (id) => {
    return deleteRequest('/general/notes/' + id);
};

export const getTagLink = async (id) => {
    return getRequest('/general/tag-links/' + id);
};

export const putTagLink = async (tagLink) => {
    return putRequest('/general/tag-links/' + tagLink.id, tagLink);
};

export const deleteTagLink = async (id) => {
    return deleteRequest('/general/tag-links/' + id);
};

export const getPhoneNumber = async (id) => {
    return getRequest('/general/phone-numbers/' + id);
};

export const putPhoneNumber = async (number) => {
    return putRequest('/general/phone-numbers/' + number.id, number);
};

export const deletePhoneNumber = async (id) => {
    return deleteRequest('/general/phone-numbers/' + id);
};

export const getHolidayLink = async (id) => {
    return getRequest('/general/holiday-links/' + id);
};

export const putHolidayLink = async (holidayLink) => {
    return putRequest('/general/holiday-links/' + holidayLink.id, holidayLink);
};

export const deleteHolidayLink = async (id) => {
    return deleteRequest('/general/holiday-links/' + id);
};

export const getExternalIdentifier = async (id) => {
    return getRequest('/general/external-identifiers/' + id);
};

export const putExternalIdentifier = async (externalIdentifier) => {
    return putRequest(
        '/general/external-identifiers/' + externalIdentifier.id,
        externalIdentifier
    );
};

export const deleteExternalIdentifier = async (id) => {
    return deleteRequest('/general/external-identifiers/' + id);
};

export const validateExternalIdentifier = async (
    externalIdentifierId,
    value
) => {
    return postRequest(
        `/general/external-identifiers/${externalIdentifierId}/validate/${value}`
    );
};

export const testSocialSecurityNumber = async (value, config, t) => {
    if (!value || (!config?.isUnique && _.isNil(config?.validationMethodId))) {
        return '';
    }

    const validationResult = await validateExternalIdentifier(
        config.externalIdentifierTypeId,
        value
    );

    if (!validationResult?.isValid) {
        if (!_.isNull(config?.validationMethodId)) {
            return t(
                'd69f628f60f504b9ae63fdb3eb5e216e',
                '{{validationMethod}} failed',
                {
                    validationMethod: config?.validationMethodDescription,
                }
            );
        } else {
            return t(
                'cbef50980b4ddeffca9ea3f4dc7aeb9a',
                'This social security number is already in use'
            );
        }
    }

    return '';
};
