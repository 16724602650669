import { redirect } from 'react-router-dom';
import { getSubgroup } from '../../../../../features';

export const pageLoader = async ({ params }) => {
    try {
        return await getSubgroup(params.subgroupId);
    } catch (ex) {
        return redirect('..');
    }
};
