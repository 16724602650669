import { Stack } from '@carbon/react';
import { useNotifications } from '../../../../features';
import { EdsNotification } from '../eds-notification';
import './eds-notifications-wrapper.scss';

export const EdsNotificationsWrapper = () => {
    const { notifications } = useNotifications();

    return (
        <div className="eds-notifications-wrapper">
            <Stack gap={5}>
                {notifications.map((notification) => {
                    return (
                        <EdsNotification
                            key={notification.id}
                            notification={notification}
                        />
                    );
                })}
            </Stack>
        </div>
    );
};

export const EdsInlineNotificationsWrapper = ({ notifications }) => {
    return (
        <div className="eds-notifications-wrapper inline">
            <Stack gap={5}>
                {notifications.map((notification) => {
                    return (
                        <EdsNotification
                            key={notification.id}
                            notification={{ inline: true, ...notification }}
                        />
                    );
                })}
            </Stack>
        </div>
    );
};
