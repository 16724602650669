import './eds-ui-simple-container.scss';
import umoLogo from '../../../assets/img/umo-standing.png';
import { useState } from 'react';
import { EdsNotificationsWrapper } from '../';

export function EdsUISimpleContainer(props) {
    const [logo] = useState(props.logo ?? false);
    const [widthClassName] = useState(
        props?.largeWidth ? ' large-width' : ' small-width'
    );

    return (
        <div className="eds-ui-simple-container centered">
            <div className={'eds-ui-simple-container-box' + widthClassName}>
                {logo && <img src={umoLogo} id="platform-logo" alt="UMO" />}
                {props.children}
            </div>

            <EdsNotificationsWrapper />
        </div>
    );
}

export function EdsUISimpleContainerButtons(props) {
    return (
        <div className="eds-ui-simple-container-buttons">{props.children}</div>
    );
}
