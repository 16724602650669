import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getMedicalPriorities, useEffectOnMount } from '../../../../features';
import {
    EdsDropdown,
    EdsDropdownType,
    EdsFormColumn,
    EdsFormGroup,
    EdsWizardStep,
} from '../../../eds';
import { Row } from '@carbon/react';

const AdditionalInformationStep = forwardRef(({ id }, ref) => {
    const { t } = useTranslation();
    const [formDefinition, setFormDefinition] = useState([]);

    useEffectOnMount(async () => {
        setFormDefinition(await getFormDefinition());
    });

    const getFormDefinition = async () => {
        return {
            wizardStepId: id,
            medicalPriorityId: {
                validation: {
                    type: 'dropdown',
                    required: true,
                },
                mapping: 'id',
            },
        };
    };

    return (
        <EdsWizardStep
            id={id}
            label={t(
                '4eec452dee7d15acfb78a023b487bf19',
                'Additional information'
            )}
        >
            <EdsFormGroup formDefinition={formDefinition} inStep={true}>
                <Row>
                    <EdsFormColumn>
                        <EdsDropdown
                            ref={ref}
                            name={'medicalPriorityId'}
                            label={t(
                                '64df27ddb333384955109454e26e112f',
                                'Medical priority'
                            )}
                            type={EdsDropdownType.ComboBox}
                            getDataCallback={getMedicalPriorities}
                        ></EdsDropdown>
                    </EdsFormColumn>
                </Row>
            </EdsFormGroup>
        </EdsWizardStep>
    );
});
AdditionalInformationStep.displayName = 'AdditionalInformationStep';

export default AdditionalInformationStep;
