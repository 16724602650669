import { useModal } from '../../../../features';
import { EdsModal } from '../eds-modal';
import './eds-modal-wrapper.scss';

export const EdsModalWrapper = () => {
    const { modal } = useModal();

    if (modal.length === 0) {
        return null;
    }

    return (
        <div className="eds-modal-wrapper">
            {modal.map((modalBox) => {
                let open = modal.indexOf(modalBox) === modal.length - 1;
                return (
                    <EdsModal
                        key={modalBox.id}
                        open={open}
                        modal={modalBox}
                        {...modalBox}
                    />
                );
            })}
        </div>
    );
};
