import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { pageLoader as PeripheralPageLoader } from './details/loader';
import {
    DeviceCreatePermissionSet,
    DeviceReadPermissionSet,
    Restricted,
} from '../../../features';

const PeripheralPage = lazy(() => import('./peripheral-page'));
const PeripheralPageNew = lazy(() => import('./new'));
const PeripheralPageDetails = lazy(() => import('./details'));

const PeripheralTab = lazy(() => import('./details/peripheral-tab'));
const LogsTab = lazy(() => import('./details/logs-tab'));

const PeripheralPageRoutes = {
    path: 'peripheral',
    element: (
        <Restricted to={DeviceReadPermissionSet} redirect={true}>
            <Outlet />
        </Restricted>
    ),
    handle: {
        crumb: (t) => t('dfef988e5e51db5a86acc045d907c1c7', 'Peripheral'),
        documentTitleParent: true,
    },
    children: [
        {
            index: true,
            element: <PeripheralPage />,
        },
        {
            path: 'new',
            element: (
                <Restricted to={DeviceCreatePermissionSet} redirect={true}>
                    <PeripheralPageNew />
                </Restricted>
            ),
            handle: {
                crumb: (t) => t('22af645d1859cb5ca6da0c484f1f37ea', 'New'),
            },
        },
        {
            id: 'peripheral/id',
            path: ':peripheralId',
            element: <PeripheralPageDetails />,
            loader: PeripheralPageLoader,
            handle: {
                crumb: (_t, data) => data.id,
                documentTitleParent: true,
            },
            children: [
                {
                    index: true,
                    element: <PeripheralTab />,
                    handle: {
                        //for tabs to find that this route is the index
                        index: true,
                    },
                },
                {
                    path: 'logs',
                    element: <LogsTab />,
                    handle: {
                        crumb: (t) =>
                            t('2165e4fa5bddb65a31f6a0c495c2fa37', 'Logs'),
                    },
                },
            ],
        },
    ],
};

export default PeripheralPageRoutes;
