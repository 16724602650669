export const CrmMaintenanceAlarmPermissionSet = [
    'umo.alarmmasterdata.read',
    'umo.alarmmasterdata.create',
    'umo.alarmmasterdata.update',
    'umo.alarmmasterdata.delete',
];

export const CrmMaintenanceAccountPermissionSet = [
    'umo.accountmasterdata.read',
    'umo.accountmasterdata.create',
    'umo.accountmasterdata.update',
    'umo.accountmasterdata.delete',
];

export const CrmMaintenanceClientPermissionSet = [
    'umo.clientmasterdata.read',
    'umo.clientmasterdata.create',
    'umo.clientmasterdata.update',
    'umo.clientmasterdata.delete',
];

export const CrmMaintenanceDevicePermissionSet = [
    'umo.devicemasterdata.read',
    'umo.devicemasterdata.create',
    'umo.devicemasterdata.update',
    'umo.devicemasterdata.delete',
];

export const CrmMaintenanceResidencePermissionSet = [
    'umo.addressmasterdata.read',
    'umo.addressmasterdata.create',
    'umo.addressmasterdata.update',
    'umo.addressmasterdata.delete',
];

export const CrmMaintenanceMedicalPermissionSet = [
    'umo.medicalmasterdata.read',
    'umo.medicalmasterdata.create',
    'umo.medicalmasterdata.update',
    'umo.medicalmasterdata.delete',
];

export const CrmMaintenanceTenantPermissionSet = [
    'umo.tenant.settings.read',
    'umo.tenant.settings.update',
];

// One of these permissions is needed to access the Maintenance section of CRM
// Not including read permissions as they are also used to retrieve data to fill in normal entities
export const CrmMaintenancePermissionSet = [
    'umo.accountmasterdata.create',
    'umo.accountmasterdata.update',
    'umo.accountmasterdata.delete',

    'umo.addressmasterdata.create',
    'umo.addressmasterdata.delete',
    'umo.addressmasterdata.update',

    'umo.alarmmasterdata.create',
    'umo.alarmmasterdata.delete',
    'umo.alarmmasterdata.update',

    'umo.clientmasterdata.create',
    'umo.clientmasterdata.delete',
    'umo.clientmasterdata.update',

    'umo.devicemasterdata.create',
    'umo.devicemasterdata.delete',
    'umo.devicemasterdata.update',

    'umo.medicalmasterdata.create',
    'umo.medicalmasterdata.delete',
    'umo.medicalmasterdata.update',

    'umo.residencemasterdata.create',
    'umo.residencemasterdata.delete',
    'umo.residencemasterdata.update',

    'umo.tenant.settings.update',
];
