import { useEffect } from 'react';
import { computePosition } from '@floating-ui/dom';
import _ from 'lodash';

export const useMutationObserver = (
    ref,
    callback,
    options = {
        CharacterData: false,
        childList: false,
        subtree: true,
        attributes: true,
    }
) => {
    useEffect(() => {
        if (ref.current) {
            const observer = new MutationObserver(callback);
            observer.observe(ref.current, options);
            return () => observer.disconnect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);
};

export const setPositionOfDropdownElement = (
    referenceEl,
    floatingEl,
    offset = { x: 0, y: 0 }
) => {
    if (_.isUndefined(referenceEl) || _.isUndefined(floatingEl)) {
        return;
    }

    if (_.isEmpty(floatingEl.style.top)) {
        //only on init when top is not set
        floatingEl.style.display = 'none';
    }

    const referenceElRect = referenceEl.getBoundingClientRect();
    const referenceWidth = referenceElRect?.width ?? 200;

    computePosition(referenceEl, floatingEl, {
        placement: 'bottom-start',
        strategy: 'fixed',
    }).then(({ x, y }) => {
        if (offset) {
            Object.assign(floatingEl.style, {
                left: `${x + offset.x}px`,
                top: `${y + offset.y}px`,
                position: 'fixed',
                display: 'block',
                width: `${referenceWidth}px`,
            });
        }
    });
};

export const handleScroll = async (params) => {
    if (params.referenceEl === undefined || params.floatingEl === undefined) {
        return;
    }

    const visible = await isVisible(params.referenceEl);
    if (visible) {
        setPositionOfDropdownElement(
            params.referenceEl,
            params.floatingEl,
            params.dropdownOffset
        );
    } else {
        //close dropdown
        params.referenceEl?.offsetParent?.focus();
        params.referenceEl?.offsetParent?.blur();

        if (params.onCloseCallback) {
            params.onCloseCallback();
        }
    }
};

function isVisible(domElement) {
    return new Promise((resolve) => {
        const o = new IntersectionObserver(([entry]) => {
            resolve(entry.intersectionRatio === 1);
            o.disconnect();
        });
        o.observe(domElement);
    });
}
