import { forwardRef, useState } from 'react';
import { Row } from '@carbon/react';
import {
    EdsForm,
    EdsFormGroup,
    EdsFormColumn,
    EdsTextInput,
    EdsDropdown,
    EdsTableFilterOperator,
    EdsTableFilterType,
    EdsToggle,
    EdsDropdownType,
    EdsDatePicker,
    EdsDatePickerType,
    EdsPhoneNumber,
    EdsNumberInput,
    EdsFormGroupLocation,
    EdsFormGroupGroup,
} from '../..';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
    defaultDropdownMappingCallback,
    getDate,
    getLogger,
} from '../../../../features';

const logger = getLogger('EdsTableFilterForm');

export const EdsTableFilterForm = forwardRef((props, ref) => {
    const { t } = useTranslation();

    const fields = () => {
        let usedFilters = props.filters.map((item) => item.column);
        return props.availableFilters.filter(
            (item) => !usedFilters.includes(item?.id)
        );
    };

    const [selectedFilter, setSelectedFilter] = useState(
        _.head(fields()) ?? null
    );

    const operators = () => {
        return [
            {
                id: EdsTableFilterOperator.Equals,
                name: t('51c3f59625962b899c03595d6cdfb284', 'Equals'),
            },
            {
                id: EdsTableFilterOperator.NotEquals,
                name: t('62558f7b07c034e992ca179a1b9b7e68', 'Not equals'),
            },
            {
                id: EdsTableFilterOperator.Empty,
                name: t('bf943ad8b2ad1f26745e6236f04b74df', 'Is empty'),
            },
        ];
    };

    const [selectedOperator, setSelectedOperator] = useState(
        _.head(operators()) ?? null
    );

    const formDefinition = {
        column: {
            value: defaultDropdownMappingCallback(_.head(fields())),
            validation: {
                type: 'dropdown',
                required: true,
            },
        },
        operator: {
            value: defaultDropdownMappingCallback(_.head(operators())),
            validation: {
                type: 'dropdown',
                required: false,
            },
        },
    };

    const onSubmit = async (_event, form, isValid) => {
        if (!isValid) {
            throw false;
        }
        return form;
    };

    const showOperator = () => {
        if (
            selectedFilter.showOperator ||
            _.isUndefined(selectedFilter.showOperator)
        ) {
            return [
                EdsTableFilterType.Dropdown,
                EdsTableFilterType.Text,
                EdsTableFilterType.Number,
            ].includes(selectedFilter?.type);
        }
        return false;
    };

    const testValueRequired = async (value, testContext) => {
        if (value) {
            return true;
        }

        if (
            testContext?.options?.context?.form?.operator?.id ===
            EdsTableFilterOperator.Empty
        ) {
            return true;
        }
        return false;
    };

    const getInputField = () => {
        if (
            showOperator() &&
            selectedOperator.id === EdsTableFilterOperator.Empty
        ) {
            return null;
        }

        switch (selectedFilter?.type) {
            case EdsTableFilterType.Dropdown: {
                return (
                    <EdsFormGroup
                        formDefinition={{
                            value: {
                                validation: {
                                    type: 'dropdown',
                                    required: false,
                                    tests: [
                                        {
                                            name: 'value-mandatory-for-operator',
                                            message: t(
                                                '3f5f7f01887d47e3f71bf06e5f475e41',
                                                'Field is required'
                                            ),
                                            func: testValueRequired,
                                        },
                                    ],
                                },
                                resetOnSchemaChange: true,
                            },
                        }}
                        prefix="filter_value"
                        key={selectedFilter.id}
                    >
                        <EdsDropdown
                            type={EdsDropdownType.ComboBox}
                            name={'value'}
                            label={t(
                                '2063c1608d6e0baf80249c42e2be5804',
                                'Value'
                            )}
                            getDataCallback={selectedFilter.getDataCallback}
                            mappingCallback={
                                selectedFilter.mappingCallback ??
                                defaultDropdownMappingCallback
                            }
                        />
                    </EdsFormGroup>
                );
            }
            case EdsTableFilterType.MultiSelect: {
                return (
                    <EdsFormGroup
                        formDefinition={{
                            value: {
                                validation: {
                                    type: 'multiselect',
                                    required: true,
                                },
                                resetOnSchemaChange: true,
                            },
                        }}
                        prefix="filter_value"
                        key={selectedFilter.id}
                    >
                        <EdsDropdown
                            type={EdsDropdownType.MultiSelect}
                            name={'value'}
                            label={t(
                                '2063c1608d6e0baf80249c42e2be5804',
                                'Value'
                            )}
                            getDataCallback={selectedFilter.getDataCallback}
                            mappingCallback={
                                selectedFilter.mappingCallback ??
                                defaultDropdownMappingCallback
                            }
                        />
                    </EdsFormGroup>
                );
            }
            case EdsTableFilterType.Text: {
                return (
                    <EdsFormGroup
                        formDefinition={{
                            value: {
                                validation: {
                                    type: 'string',
                                    required: false,
                                    tests: [
                                        {
                                            name: 'value-mandatory-for-operator',
                                            message: t(
                                                '3f5f7f01887d47e3f71bf06e5f475e41',
                                                'Field is required'
                                            ),
                                            func: testValueRequired,
                                        },
                                    ],
                                },
                                resetOnSchemaChange: true,
                            },
                        }}
                        prefix="filter_value"
                        key={selectedFilter.id}
                    >
                        <EdsTextInput
                            name={'value'}
                            label={t(
                                '2063c1608d6e0baf80249c42e2be5804',
                                'Value'
                            )}
                        />
                    </EdsFormGroup>
                );
            }
            case EdsTableFilterType.Email: {
                return (
                    <EdsFormGroup
                        formDefinition={{
                            value: {
                                validation: {
                                    type: 'email',
                                    required: true,
                                },
                                resetOnSchemaChange: true,
                            },
                        }}
                        prefix="filter_value"
                        key={selectedFilter.id}
                    >
                        <EdsTextInput
                            name={'value'}
                            label={t(
                                '2063c1608d6e0baf80249c42e2be5804',
                                'Value'
                            )}
                        />
                    </EdsFormGroup>
                );
            }
            case EdsTableFilterType.PhoneNumber: {
                return (
                    <EdsFormGroup
                        formDefinition={{
                            value: {
                                validation: {
                                    type: 'text',
                                    required: true,
                                },
                                resetOnSchemaChange: true,
                            },
                        }}
                        prefix="filter_value"
                        key={selectedFilter.id}
                    >
                        <EdsPhoneNumber
                            name={'value'}
                            label={t(
                                '2063c1608d6e0baf80249c42e2be5804',
                                'Value'
                            )}
                        />
                    </EdsFormGroup>
                );
            }
            case EdsTableFilterType.Number: {
                return (
                    <EdsFormGroup
                        formDefinition={{
                            value: {
                                validation: {
                                    type: 'number',
                                    required: false,
                                    tests: [
                                        {
                                            name: 'value-mandatory-for-operator',
                                            message: t(
                                                '3f5f7f01887d47e3f71bf06e5f475e41',
                                                'Field is required'
                                            ),
                                            func: testValueRequired,
                                        },
                                    ],
                                },
                                resetOnSchemaChange: true,
                            },
                        }}
                        prefix="filter_value"
                        key={selectedFilter.id}
                    >
                        <EdsNumberInput
                            name={'value'}
                            label={t(
                                '2063c1608d6e0baf80249c42e2be5804',
                                'Value'
                            )}
                        />
                    </EdsFormGroup>
                );
            }
            case EdsTableFilterType.YesNo: {
                return (
                    <EdsFormGroup
                        formDefinition={{
                            value: {
                                value: true,
                                validation: {
                                    type: 'boolean',
                                    required: true,
                                },
                                resetOnSchemaChange: true,
                            },
                        }}
                        prefix="filter_value"
                        key={selectedFilter.id}
                    >
                        <EdsToggle
                            labelText={t(
                                '2063c1608d6e0baf80249c42e2be5804',
                                'Value'
                            )}
                            name={'value'}
                        />
                    </EdsFormGroup>
                );
            }
            case EdsTableFilterType.Range: {
                return (
                    <EdsFormGroup
                        formDefinition={{
                            value_from: {
                                validation: {
                                    type: 'string',
                                    required: true,
                                },
                                resetOnSchemaChange: true,
                            },
                            value_to: {
                                validation: {
                                    type: 'string',
                                    required: true,
                                },
                                resetOnSchemaChange: true,
                            },
                        }}
                        prefix="filter_value"
                        key={selectedFilter.id}
                    >
                        <EdsTextInput
                            name={'value_from'}
                            label={t(
                                'd98a07f84921b24ee30f86fd8cd85c3c',
                                'From'
                            )}
                        />
                        <EdsTextInput
                            name={'value_to'}
                            label={t('01b6e20344b68835c5ed1ddedf20d531', 'To')}
                        />
                    </EdsFormGroup>
                );
            }
            case EdsTableFilterType.Date: {
                return (
                    <EdsFormGroup
                        formDefinition={{
                            value: {
                                validation: {
                                    type: 'string',
                                    required: true,
                                },
                                resetOnSchemaChange: true,
                            },
                        }}
                        prefix="filter_value"
                        key={selectedFilter.id}
                    >
                        <EdsDatePicker
                            name={'value'}
                            label={t(
                                '2063c1608d6e0baf80249c42e2be5804',
                                'Value'
                            )}
                            minDate={getDate({ minusYears: 120 })}
                            maxDate={getDate()}
                        />
                    </EdsFormGroup>
                );
            }
            case EdsTableFilterType.DateRange: {
                return (
                    <EdsFormGroup
                        formDefinition={{
                            value_from: {
                                validation: {
                                    type: 'string',
                                    required: true,
                                },
                                resetOnSchemaChange: true,
                            },
                            value_to: {
                                validation: {
                                    type: 'string',
                                    required: true,
                                },
                                resetOnSchemaChange: true,
                            },
                        }}
                        prefix="filter_value"
                        key={selectedFilter.id}
                    >
                        <EdsDatePicker
                            fromName={'value_from'}
                            fromLabel={t(
                                'd98a07f84921b24ee30f86fd8cd85c3c',
                                'From'
                            )}
                            toName={'value_to'}
                            toLabel={t(
                                '01b6e20344b68835c5ed1ddedf20d531',
                                'To'
                            )}
                            type={EdsDatePickerType.DateRange}
                            minDate={getDate({ minusYears: 120 })}
                            maxDate={getDate()}
                        />
                    </EdsFormGroup>
                );
            }
            case EdsTableFilterType.Location: {
                return (
                    <EdsFormGroupLocation
                        prefix="filter_value"
                        key={selectedFilter.id}
                        label={''}
                    />
                );
            }
            case EdsTableFilterType.Group: {
                return (
                    <EdsFormGroupGroup
                        prefix="filter_value"
                        key={selectedFilter.id}
                        label={''}
                    />
                );
            }
            default:
                return null;
        }
    };
    logger.log('selectedFilter', selectedFilter);

    if (fields()?.length === 0) {
        return (
            <div>
                {t('01e1db6de2b78dbba1e79c073c023469', 'No filters available.')}
            </div>
        );
    }

    return (
        <EdsForm
            hideDefaultSubmit={props.hideDefaultSubmit ?? true}
            initValues={props.initValues}
            formDefinition={formDefinition}
            onSubmit={onSubmit}
            ref={ref}
        >
            <EdsFormGroup>
                <Row>
                    <EdsFormColumn fullWidth>
                        <EdsDropdown
                            name="column"
                            label={t(
                                '1afd32818d1c9525f82aff4c09efd254',
                                'Column'
                            )}
                            mappingCallback={defaultDropdownMappingCallback}
                            getDataCallback={() => fields()}
                            onChangeCallback={(event) => {
                                setSelectedFilter(
                                    props.availableFilters.find(
                                        (filter) =>
                                            filter.id === event.selectedItem.id
                                    )
                                );
                            }}
                        />
                    </EdsFormColumn>
                </Row>
                {showOperator() && (
                    <Row>
                        <EdsFormColumn fullWidth>
                            <EdsDropdown
                                name="operator"
                                label={t(
                                    '4b583376b2767b923c3e1da60d10de59',
                                    'Operator'
                                )}
                                mappingCallback={defaultDropdownMappingCallback}
                                getDataCallback={() => operators()}
                                onChangeCallback={(event) => {
                                    setSelectedOperator(event.selectedItem);
                                }}
                            />
                        </EdsFormColumn>
                    </Row>
                )}
                <Row>
                    <EdsFormColumn fullWidth>{getInputField()}</EdsFormColumn>
                </Row>
            </EdsFormGroup>
        </EdsForm>
    );
});

EdsTableFilterForm.displayName = 'EdsTableFilterForm';
