import { useRef, useState } from 'react';
import { EdsWizard, EdsWizardOrientation } from '../../../eds';
import {
    defaultDropdownMappingCallback,
    getAccountSettings,
    useModal,
} from '../../../../features';
import _ from 'lodash';
import SearchStep from './search-step';
import AdditionalInformationStep from '../umo-linked-device-table/additional-information-step';

export const UmoLinkClient = ({
    modalId,
    linkBaseFilter,
    linkToDevice = false,
    onSubmit,
}) => {
    const formRef = useRef(null);
    const wizardRef = useRef(null);
    const { remove } = useModal();
    const [selected, setSelected] = useState({});

    return (
        <EdsWizard
            orientation={EdsWizardOrientation.Horizontal}
            onCancelClick={() => {
                remove(modalId);
            }}
            onSubmit={async (_event, form, isValid) => {
                if (!isValid) {
                    return false;
                }

                if (_.isFunction(onSubmit)) {
                    const response = await onSubmit(
                        { ...form, selected: selected },
                        isValid
                    );
                    remove(modalId);
                    return response;
                } else {
                    remove(modalId);
                    return true;
                }
            }}
            ref={{
                formRef: formRef,
                wizardRef: wizardRef,
            }}
        >
            <SearchStep
                id="select-client"
                selectedRow={selected}
                selectedRowCallback={setSelected}
                baseFilter={linkBaseFilter}
            />

            {linkToDevice && (
                <AdditionalInformationStep
                    id="additional-information"
                    defaultDeviceState={async () => {
                        const accountSettings = await getAccountSettings();
                        return accountSettings?.linkDeviceDefaultState
                            ? defaultDropdownMappingCallback(
                                  accountSettings.linkDeviceDefaultState
                              )
                            : {};
                    }}
                />
            )}
        </EdsWizard>
    );
};
