import { getLogger, useAuth } from '../../features';
import { Button } from '@carbon/react';
import './scss/login-page.scss';
import { Navigate } from 'react-router-dom';
import {
    EdsUISimpleContainer,
    EdsUISimpleContainerButtons,
} from '../../components/';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from '../../features/react/hooks';
import { useEffect } from 'react';

const logger = getLogger('LoginPage');

function LoginPage() {
    const { login, isAuthenticated } = useAuth();
    const { t } = useTranslation();
    const documentTitle = useDocumentTitle();

    useEffect(() => {
        document.title = documentTitle;
    }, [documentTitle]);

    logger.log('isAuthenticated', isAuthenticated);
    if (isAuthenticated) {
        return <Navigate to="/" />;
    }

    return (
        <div id="login-page">
            <EdsUISimpleContainer logo={true}>
                <EdsUISimpleContainerButtons>
                    <Button onClick={() => login()}>
                        {t('d56b699830e77ba53855679cb1d252da', 'Login')}
                    </Button>
                </EdsUISimpleContainerButtons>
            </EdsUISimpleContainer>
        </div>
    );
}

export default LoginPage;
