import { Column } from '@carbon/react';

export function EdsFormColumn(props) {
    const { fullWidth } = props;

    const breakpoints = {
        sm: 4,
        md: fullWidth ? 8 : 4,
        lg: fullWidth ? 16 : 8,
    };

    return (
        <Column className="eds-form-column" {...breakpoints}>
            {props.children}
        </Column>
    );
}
