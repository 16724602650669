import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { toJSDate } from '../../../../features';
import _ from 'lodash';

export function EdsTableDatetime({ formatType = 'daytime', value = '' }) {
    const { t } = useTranslation();

    const getFormat = () => {
        if (formatType === 'day') {
            return t('418c23834fc746227d6ba6bbf181e8b5', 'MM/dd/yyyy');
        } else {
            return t('6eb1403d4ccd7c4731ffa90b6c01beef', 'MM/dd/yyyy h:mm a');
        }
    };

    const jsdate = toJSDate(value, true);
    if (jsdate instanceof Date) {
        return DateTime.fromJSDate(jsdate).toFormat(getFormat());
    }

    return _.isEmpty(value) ? '-' : value;
}

export function EdsTableDate(props) {
    return <EdsTableDatetime formatType="day" {...props} />;
}
