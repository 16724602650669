import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EdsContainerTable } from '../../..';
import _ from 'lodash';
import { UmoLinkMedicine } from './link-medicine';
import {
    setEmptyFieldToNull,
    useModal,
    useNotifications,
} from '../../../../features';

export const UmoLinkedMedicinesDataTable = forwardRef(
    ({ getDataCallback, postDataCallback, onUnlinkClick, ...props }, ref) => {
        const { t } = useTranslation();
        const { showSuccess, showError } = useNotifications();
        const { showLinkModal } = useModal();
        const [title] = useState(
            props.title ??
                t('63a950a740e25cef1f520db315db1a3a', 'Linked medicines')
        );

        const [hideWhenNoData] = useState(props.hideWhenNoData ?? true);
        const [hasData, setHasData] = useState();
        const [medicineIds, setMedicineIds] = useState([]);

        const getData = async () => {
            let data = await getDataCallback();
            let newMedicineIds = [];
            for (let index in data) {
                let item = data[index];
                if (_.isNumber(item.medicine?.id)) {
                    newMedicineIds.push(item.medicine.id);
                }
            }
            setMedicineIds(newMedicineIds);
            setHasData(!_.isEmpty(data));
            return data;
        };

        const linkNewMedicine = async (form) => {
            const postData = setEmptyFieldToNull(form);

            try {
                const response = await postDataCallback({
                    medicineId: postData.selected,
                    medicalPriorityId: postData.medicalPriorityId,
                });

                showSuccess({
                    title: t('536a056b710e94b16169efd17a4a657b', 'Saved'),
                    content: t(
                        '5be667d4a923c2575e183041beafed1e',
                        '{{entity}} is linked',
                        {
                            entity: t(
                                'd9e5d212320e7d96e921831554be696d',
                                'Medicine'
                            ),
                        }
                    ),
                });

                ref.current?.fetchInitData();

                return response;
            } catch (error) {
                showError({
                    title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                    content: t(
                        'df0a9d46baf7315909e4389a04786e3d',
                        'Oops something went wrong'
                    ),
                });

                throw error;
            }
        };

        if (
            hideWhenNoData &&
            !_.isUndefined(hasData) &&
            _.isBoolean(hasData) &&
            !hasData
        ) {
            return null;
        }

        return (
            <EdsContainerTable
                hidden={_.isUndefined(hasData)}
                title={title}
                ref={ref}
                headers={[
                    {
                        key: 'medicine',
                        header: t(
                            'd9e5d212320e7d96e921831554be696d',
                            'Medicine'
                        ),
                        sort: 'medicine',
                    },
                    {
                        key: 'priority',
                        header: t(
                            'b988295c268025b49dfb3df26171ddc3',
                            'Priority'
                        ),
                        sort: 'priority',
                    },
                ]}
                getDataCallback={getData}
                onHeaderLinkClick={
                    !_.isFunction(postDataCallback)
                        ? undefined
                        : () => {
                              showLinkModal({
                                  title: t(
                                      'c1e2a6335ef2de3def204f5f1a3e210b',
                                      'Link medicine'
                                  ),
                                  tableRef: ref,
                                  children: (
                                      <UmoLinkMedicine
                                          {...props}
                                          getDataCallback={getData}
                                          additionalLinkDataFilterCallback={(
                                              medicine
                                          ) => {
                                              return !medicineIds.includes(
                                                  medicine.id
                                              );
                                          }}
                                          onSubmit={async (form) => {
                                              if (
                                                  !_.isFunction(
                                                      postDataCallback
                                                  )
                                              ) {
                                                  return false;
                                              }
                                              return linkNewMedicine(form);
                                          }}
                                      />
                                  ),
                              });
                          }
                }
                onUnlinkClick={
                    !_.isFunction(onUnlinkClick)
                        ? undefined
                        : (row) => {
                              let data = ref.current?.getRowData(row.id);
                              return onUnlinkClick({ row: row, data: data });
                          }
                }
                mappingCallback={(responseData) => {
                    return {
                        id: responseData.id,
                        medicineId: responseData?.medicine?.id,
                        medicine: responseData?.medicine?.name,
                        priority: responseData?.medicalPriority?.name,
                    };
                }}
            />
        );
    }
);
UmoLinkedMedicinesDataTable.displayName = 'UmoLinkedMedicinesDataTable';
