// The object LastUsedTentants contains the most recently used tenant and for
// each tenant the most recently used accountId and is stored in localStorage.
//
// {
//     "lastUsedTenantId": "umoTenantIdentifier_2",
//     "lastUsedAccountIds": {
//         "umoTenantIdentifier_1": "accountId_1",
//         "umoTenantIdentifier_2": "accountId_2",
//         "umoTenantIdentifier_3": "accountId_3",
//         "umoTenantIdentifier_4": "accountId_4"
//     }
// }

import _ from 'lodash';
import { getLogger } from './logger';

const logger = getLogger('LastUsedTenants');

export const getLastUsedTenantId = () => {
    const lastUsedTenantId = getLastUsedTenants()?.lastUsedTenantId;

    logger.log(`lastUsedTenantId`, lastUsedTenantId);
    return lastUsedTenantId;
};

export const setLastUsedTenantId = (tenantId) => {
    if (_.isNil(tenantId)) {
        return;
    }

    let lastUsedTenants = getLastUsedTenants();
    lastUsedTenants.lastUsedTenantId = tenantId;

    setLastUsedTenants(lastUsedTenants);
};

export const getLastUsedAccountId = (tenantId) => {
    if (_.isNil(tenantId)) {
        return;
    }

    const lastUsedAccountIds = getLastUsedTenants()?.lastUsedAccountIds || {};
    const lastUsedAccountId = lastUsedAccountIds[tenantId];

    logger.log(
        `lastUsedAccountId for tenantId '${tenantId}'`,
        lastUsedAccountId
    );
    return lastUsedAccountId;
};

export const setLastUsedAccountId = (tenantId, accountId) => {
    if (_.isNil(tenantId) || _.isNil(accountId)) {
        return;
    }

    let lastUsedTenants = getLastUsedTenants();
    let lastUsedAccountIds = lastUsedTenants?.lastUsedAccountIds || {};
    lastUsedAccountIds[tenantId] = accountId;
    lastUsedTenants.lastUsedAccountIds = lastUsedAccountIds;

    setLastUsedTenants(lastUsedTenants);
};

const getLastUsedTenants = () => {
    const lastUsedTentants = localStorage.getItem('lastUsedTenants');
    return _.isNull(lastUsedTentants) ? {} : JSON.parse(lastUsedTentants);
};

const setLastUsedTenants = (lastUsedTenants) => {
    localStorage.setItem(
        'lastUsedTenants',
        _.isPlainObject(lastUsedTenants)
            ? JSON.stringify(lastUsedTenants)
            : lastUsedTenants
    );
};
