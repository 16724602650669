import {
    createSearchFilterStoreFunctions,
    createStateManagementFunctions,
    deleteRequest,
    getRequest,
    patchRequest,
    postRequest,
    putRequest,
} from '../api';

const basePath = '/care-teams';

export const getCareTeamSearch = async (search) => {
    return getRequest(`${basePath}/search`, search);
};

export const getCareTeam = async (id) => {
    return getRequest(`${basePath}/${id}`);
};

export const postCareTeam = async (data) => {
    return postRequest(`${basePath}`, data);
};

export const putCareTeam = async (data) => {
    return putRequest(`${basePath}/${data.id}`, data);
};

export const patchCareTeam = async (id, data) => {
    return patchRequest(`${basePath}/${id}`, data);
};

export const getCareTeamPhoneNumbers = async (id) => {
    return getRequest(`${basePath}/${id}/phone-numbers`);
};

export const postCareTeamPhoneNumber = async (id, number) => {
    return postRequest(`${basePath}/${id}/phone-numbers`, number);
};

export const getCareTeamNotes = async (careTeamId) => {
    return getRequest(`${basePath}/${careTeamId}/notes`);
};

export const postCareTeamNote = async (careTeamId, note) => {
    return postRequest(`${basePath}/${careTeamId}/notes`, note);
};

export const getCareTeamTags = async (careTeamId) => {
    return getRequest(`${basePath}/${careTeamId}/tags`);
};

export const postCareTeamTag = async (careTeamId, tag) => {
    return postRequest(`${basePath}/${careTeamId}/tags`, tag);
};

export const getCareTeamHolidayLinks = async (id, params) => {
    return getRequest(`${basePath}/${id}/holiday-links`, params);
};

export const postCareTeamHolidayLink = async (id, holidayLink) => {
    return postRequest(`${basePath}/${id}/holiday-links`, holidayLink);
};

export const getCareTeamClients = async (id) => {
    return getRequest(`${basePath}/${id}/clients`);
};

export const getCareTeamResidences = async (id) => {
    return getRequest(`${basePath}/${id}/residences`);
};

export const getCareTeamSchemes = async (id) => {
    return getRequest(`${basePath}/${id}/schemes`);
};

export const getCareTeamMembers = async (careTeamId) => {
    return getRequest(`${basePath}/${careTeamId}/members`);
};

export const getCareTeamMember = async (careTeamMemberId) => {
    return getRequest(`${basePath}/members/${careTeamMemberId}`);
};

export const postCareTeamMember = async (careTeamMember) => {
    return postRequest(`${basePath}/members`, careTeamMember);
};

export const putCareTeamMember = async (careTeamMember) => {
    return putRequest(
        `${basePath}/members/${careTeamMember.id}`,
        careTeamMember
    );
};

export const deleteCareTeamMember = async (careTeamMemberId) => {
    return deleteRequest(`${basePath}/members/${careTeamMemberId}`);
};

export const stateManagement = {
    ...createStateManagementFunctions(basePath, 'care-teams'),
};

export const searchFilterStore = {
    ...createSearchFilterStoreFunctions(basePath, 'care-teams'),
};
