import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { pageLoader as ProfessionalCaregiverPageLoader } from './details/loader';
import {
    CareProviderCreatePermissionSet,
    CareProviderReadPermissionSet,
    Restricted,
} from '../../../features';

const ProfessionalCaregiverPage = lazy(() =>
    import('./professional-caregiver-page')
);
const ProfessionalCaregiverPageNew = lazy(() => import('./new'));
const ProfessionalCaregiverPageDetails = lazy(() => import('./details'));

const ProfessionalCaregiverTab = lazy(() =>
    import('./details/professional-caregiver-tab')
);
const NotesTab = lazy(() => import('./details/notes-tab'));
const LinkedTab = lazy(() => import('./details/linked-tab'));
const AvailabilityTab = lazy(() => import('./details/availability-tab'));
const LogsTab = lazy(() => import('./details/logs-tab'));

const ProfessionalCaregiverPageRoutes = {
    path: 'professional-caregiver',
    element: (
        <Restricted to={CareProviderReadPermissionSet} redirect={true}>
            <Outlet />
        </Restricted>
    ),
    handle: {
        crumb: (t) =>
            t('599bfff4492f40356f6e8c9eb47f6996', 'Professional caregiver'),
        documentTitleParent: true,
    },
    children: [
        {
            index: true,
            element: <ProfessionalCaregiverPage />,
        },
        {
            path: 'new',
            element: (
                <Restricted
                    to={CareProviderCreatePermissionSet}
                    redirect={true}
                >
                    <ProfessionalCaregiverPageNew />
                </Restricted>
            ),
            handle: {
                crumb: (t) => t('22af645d1859cb5ca6da0c484f1f37ea', 'New'),
            },
        },
        {
            id: 'professional-caregiver/id',
            path: ':professionalCaregiverId',
            element: <ProfessionalCaregiverPageDetails />,
            loader: ProfessionalCaregiverPageLoader,
            handle: {
                crumb: (_t, data) => data?.person?.combinedName,
                documentTitleParent: true,
            },
            children: [
                {
                    index: true,
                    element: <ProfessionalCaregiverTab />,
                    handle: {
                        //for tabs to find that this route is the index
                        index: true,
                    },
                },
                {
                    path: 'notes',
                    element: <NotesTab />,
                    handle: {
                        crumb: (t) =>
                            t('4358b5009c67d0e31d7fbf1663fcd3bf', 'Notes'),
                    },
                },
                {
                    path: 'linked',
                    element: <LinkedTab />,
                    handle: {
                        crumb: (t) =>
                            t('d48e37bc19b9fe2c72923c30fd7a4152', 'Linked'),
                    },
                },
                {
                    path: 'availability',
                    element: <AvailabilityTab />,
                    handle: {
                        crumb: (t) =>
                            t(
                                'f24431ce9f1b8885678b1ed611c4c214',
                                'Availability'
                            ),
                    },
                },
                {
                    path: 'logs',
                    element: <LogsTab />,
                    handle: {
                        crumb: (t) =>
                            t('2165e4fa5bddb65a31f6a0c495c2fa37', 'Logs'),
                    },
                },
            ],
        },
    ],
};

export default ProfessionalCaregiverPageRoutes;
