import { useRef } from 'react';
import { EdsContainerTable } from '../../../eds';
import {
    deletePhoneNumber,
    getLogger,
    getPhoneNumber,
    useModal,
} from '../../../../features';
import { useTranslation } from 'react-i18next';
import UmoPhoneNumbersForm from './umo-phone-numbers-form';

let logger = getLogger('UmoPhoneNumbersTable');

export const UmoPhoneNumbersTable = (props) => {
    const { t } = useTranslation();
    const formRef = useRef(null);
    const tableRef = useRef(null);
    const { showDeleteModal, showFormModal } = useModal();

    const headers = [
        {
            key: 'sortIndex',
            header: '#',
            width: '100px',
        },
        {
            key: 'number',
            header: t('9b88e58612797d6b989681a16621ad63', 'Phone number'),
            width: '300px',
        },
        {
            key: 'type',
            header: t('e1ef131918144ec7adeb7ed253dec2ea', 'Phone number type'),
            width: '350px',
        },
        {
            key: 'remarks',
            header: t('911c185c8f3c475cdca2ef9cf12166da', 'Remark'),
        },
    ];

    const showDataModal = (number = {}) => {
        showFormModal({
            newTitle: t(
                '11721d97e30f2fb2b49c43e73d962f13',
                'Add new phone number'
            ),
            editTitle: t(
                '4678056dabffd913b3ad0d395cb68567',
                'Edit phone number'
            ),
            formData: number,
            formRef,
            tableRef,
            onSubmitFormResponse: async (response, removeModal) => {
                if (response) {
                    tableRef.current?.fetchInitData();
                }
                removeModal();
            },
            children: (
                <UmoPhoneNumbersForm
                    phoneTypeFormat={props.phoneTypeFormat}
                    postDataCallback={props.postDataCallback}
                    ref={formRef}
                    initValues={number}
                    hideDefaultSubmit={true}
                />
            ),
        });
    };

    const getDataRow = async (row) => {
        try {
            let number = await getPhoneNumber(row.id);
            showDataModal({
                id: {
                    value: number.id,
                },
                sortIndex: {
                    value: number.sortIndex,
                },
                number: {
                    value: number.number,
                },
                remarks: {
                    value: number.remarks,
                },
                phoneNumberTypeId: {
                    id: number?.phoneNumberType.id,
                    text: number?.phoneNumberType.name,
                    validateE164Format:
                        number?.phoneNumberType?.validateE164Format,
                },
            });
        } catch (ex) {
            logger.warn('getPhoneNumber UDS Exception:', ex);
        }
    };

    const mappingCallback = (responseData) => {
        return {
            id: responseData.id,
            sortIndex: responseData.sortIndex,
            number: responseData.number,
            type: responseData?.phoneNumberType.name,
            remarks: responseData.remarks,
        };
    };

    return (
        <EdsContainerTable
            title={t('608ae100f280b757a3a7483ff3a25905', 'Phone numbers')}
            ref={tableRef}
            headers={headers}
            getDataCallback={props.getDataCallback}
            mappingCallback={mappingCallback}
            onEditClick={(row) => {
                getDataRow(row);
            }}
            onDeleteClick={(row) => {
                let data = tableRef.current?.getRowData(row.id);
                showDeleteModal({
                    name: data?.number ?? '',
                    tableRef,
                    row,
                    deleteHandler: async () => {
                        await deletePhoneNumber(row.id);
                    },
                });
            }}
            onHeaderAddClick={() => showDataModal()}
            permissions={props.permissions}
        />
    );
};
