import { useRef, useState } from 'react';
import { EdsContainerTable, EdsTableDate } from '../../..';
import { useTranslation } from 'react-i18next';
import {
    deleteHolidayLink,
    getHolidayLink,
    getLogger,
    useModal,
} from '../../../../features';
import UmoHolidayLinksForm from './umo-holiday-links-form';

let logger = getLogger('UmoHolidayLinksTable');

export const UmoHolidayLinksTable = (props) => {
    const { t } = useTranslation();
    const formRef = useRef(null);
    const tableRef = useRef(null);
    const [title] = useState(
        props.title ?? t('351be24aa3154abeb916761294b6f8ad', 'Absence')
    );

    const { showDeleteModal, showFormModal } = useModal();

    const showDataModal = (holidayLink = {}) => {
        showFormModal({
            newTitle: t('d515f8740878ca87c4b945321db12e53', 'New absence'),
            editTitle: t('5b6e91b79315d688eb95f9314fb67424', 'Edit absence'),
            formData: holidayLink,
            formRef,
            tableRef,
            onSubmitFormResponse: async (response, removeModal) => {
                if (response) {
                    tableRef.current?.fetchInitData();
                }
                removeModal();
            },
            children: (
                <UmoHolidayLinksForm
                    postDataCallback={props.postDataCallback}
                    ref={formRef}
                    initValues={holidayLink}
                    hideDefaultSubmit={true}
                ></UmoHolidayLinksForm>
            ),
        });
    };

    const getDataRow = async (row) => {
        try {
            let holidayLink = await getHolidayLink(row.id);
            showDataModal({
                id: {
                    value: holidayLink.id,
                },
                absenceReasonId: {
                    id: holidayLink.absenceReasonId,
                    text: holidayLink?.absenceReason?.name,
                },
                fromDate: {
                    value: holidayLink.fromDate,
                },
                toDate: {
                    value: holidayLink.toDate,
                },
                description: {
                    value: holidayLink.description,
                },
            });
        } catch (ex) {
            logger.warn('getHolidayLink UDS Exception:', ex);
        }
    };

    return (
        <EdsContainerTable
            title={title}
            ref={tableRef}
            headers={[
                {
                    key: 'from',
                    header: t('d98a07f84921b24ee30f86fd8cd85c3c', 'From'),
                    width: '200px',
                },
                {
                    key: 'to',
                    header: t('01b6e20344b68835c5ed1ddedf20d531', 'To'),
                    width: '200px',
                },
                {
                    key: 'reason',
                    header: t('40bea8d637cdf2c1b07fcf0630482b73', 'Reason'),
                },

                {
                    key: 'description',
                    header: t(
                        '67daf92c833c41c95db874e18fcb2786',
                        'Description'
                    ),
                },
            ]}
            onEditClick={(row) => {
                getDataRow(row);
            }}
            getDataCallback={props.getDataCallback}
            mappingCallback={(responseData) => {
                return {
                    id: responseData.id,
                    from: <EdsTableDate value={responseData.fromDate} />,
                    to: <EdsTableDate value={responseData.toDate} />,
                    reason: responseData?.absenceReason?.name,
                    description: responseData.description,
                };
            }}
            onDeleteClick={(row) => {
                let data = tableRef.current?.getRowData(row.id);
                showDeleteModal({
                    name: data?.reason,
                    tableRef,
                    row,
                    deleteHandler: async () => {
                        await deleteHolidayLink(row.id);
                    },
                });
            }}
            onHeaderAddClick={() => showDataModal()}
            permissions={props.permissions}
        />
    );
};
