import { Add, Edit, Link, Unknown } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';
import { Button, IconButton } from '@carbon/react';
import _ from 'lodash';

export function EdsContainerButton({
    kind,
    label,
    showLabel,
    size,
    type,
    ...props
}) {
    const { t } = useTranslation();

    const getStyle = () => {
        let classes = ['eds-container-button'];
        return classes.join(' ');
    };

    const getIcon = (component) => {
        let Icon = Unknown;
        if (type === 'edit') {
            Icon = Edit;
        }
        if (type === 'add') {
            Icon = Add;
        }
        if (type === 'link') {
            Icon = Link;
        }
        if (component) {
            return <Icon size="16" />;
        }
        return Icon;
    };

    const getLabel = () => {
        if (!_.isUndefined(label)) {
            return label;
        }
        if (type === 'edit') {
            return t('de95b43bceeb4b998aed4aed5cef1ae7', 'Edit');
        }
        if (type === 'add') {
            return t('34ec78fcc91ffb1e54cd85e4a0924332', 'Add');
        }
        if (type === 'link') {
            return t('2a304a1348456ccd2234cd71a81bd338', 'Link');
        }

        return '';
    };

    const handleOnClick = (event) => {
        if (_.isFunction(props.onClick)) {
            props.onClick(event);
        }
        event.stopPropagation();
    };

    if (showLabel) {
        return (
            <Button
                className={getStyle()}
                kind={kind ?? 'tertiary'}
                size={size ?? 'sm'}
                renderIcon={getIcon()}
                onClick={handleOnClick}
                iconDescription={getLabel()}
            >
                {label}
            </Button>
        );
    }

    return (
        <IconButton
            className={getStyle()}
            label={getLabel()}
            kind={kind ?? 'tertiary'}
            size={size ?? 'sm'}
            onClick={handleOnClick}
        >
            {getIcon(true)}
        </IconButton>
    );
}
