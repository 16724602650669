import { forwardRef, useEffect, useRef, useState } from 'react';
import {
    EdsDatePicker,
    EdsDatePickerType,
    EdsDropdown,
    EdsDropdownType,
    EdsForm,
    EdsFormColumn,
    EdsFormGroup,
    EdsNumberInput,
    EdsTextArea,
} from '../../../eds';
import { useTranslation } from 'react-i18next';
import {
    debounce,
    getTagCategories,
    getTags,
    getDate,
    putTagLink,
    useNotifications,
} from '../../../../features';
import { Row } from '@carbon/react';
import _ from 'lodash';

const UmoTagsForm = forwardRef((props, ref) => {
    const { showSuccess, showError } = useNotifications();
    const { t } = useTranslation();
    const tagCategoriesRef = useRef(null);
    const tagsRef = useRef(null);
    const updateTagTimeoutId = useRef();

    const [tagCategoryId, setTagCategoryId] = useState();
    const [freeTextAllowed, setFreeTextAllowed] = useState(
        props.initValues?.freeTextAllowed?.value || false
    );

    useEffect(() => {
        updateTags();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tagCategoryId]);

    const tagsMappingCallback = (data) => {
        return {
            id: data.id,
            text: data.name,
            freeText: data?.freeTextAllowed ?? false,
        };
    };

    const updateTags = async () => {
        const tags = [];
        let tagsResponse = null;
        if (_.isNumber(tagCategoryId)) {
            tagsResponse = await getTags(tagCategoryId, props.tagParams);

            if (_.isArray(tagsResponse)) {
                tagsResponse.map((data) => {
                    tags.push(tagsMappingCallback(data));
                });
            }
        }

        tagsRef.current?.updateItems(tags);
    };

    const formDefinition = {
        sortIndex: {
            value: '',
            validation: {
                type: 'number',
                min: 0,
                required: false,
            },
        },
        tagCategoryId: {
            validation: {
                type: 'dropdown',
                required: true,
            },
            mapping: 'id',
        },
        tagId: {
            validation: {
                type: 'dropdown',
                required: true,
            },
            mapping: 'id',
        },
        validFrom: {
            value: getDate(),
            validation: {
                type: 'datepicker',
                required: false,
            },
        },
        validTo: {
            validation: {
                type: 'datepicker',
                required: false,
            },
        },
        freeText: {
            validation: {
                required: false,
            },
        },
    };

    const saveData = async (form) => {
        if (_.isEmpty(form.sortIndex)) {
            //sortIndex can be empty but when empty it must be set to 0 for UDS
            form.sortIndex = 0;
        }

        if (!freeTextAllowed) {
            form.freeText = '';
        }

        if (!form.id) {
            return await props.postDataCallback(form);
        } else {
            return await putTagLink(form);
        }
    };

    const onSubmit = async (_event, form, isValid) => {
        if (!isValid) {
            throw false;
        }
        try {
            const response = await saveData(form);
            showSuccess({
                title: t('536a056b710e94b16169efd17a4a657b', 'Saved'),
                content: t(
                    '281aef713c8dc9007cabb1e65448917d',
                    "{{entity}} '{{name}}' is saved",
                    {
                        entity: t('e4d23e841d8e8804190027bce3180fa5', 'Tag'),
                        name: form.subject,
                    }
                ),
            });

            return response;
        } catch (error) {
            showError({
                title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                content: t(
                    'df0a9d46baf7315909e4389a04786e3d',
                    'Oops something went wrong'
                ),
            });

            throw error;
        }
    };

    return (
        <EdsForm
            hideDefaultSubmit={props.hideDefaultSubmit}
            initValues={props.initValues}
            formDefinition={formDefinition}
            onSubmit={onSubmit}
            ref={ref}
        >
            <EdsFormGroup>
                <Row>
                    <EdsFormColumn>
                        <EdsNumberInput
                            name="sortIndex"
                            label={t(
                                '70a17ffa722a3985b86d30b034ad06d7',
                                'Order'
                            )}
                        ></EdsNumberInput>
                    </EdsFormColumn>
                </Row>
                <Row>
                    <EdsFormColumn>
                        <EdsDropdown
                            ref={tagCategoriesRef}
                            name="tagCategoryId"
                            label={t(
                                'e22cbb5177c24c788f7e5bcd54b5ed79',
                                'Tag category'
                            )}
                            type={EdsDropdownType.ComboBox}
                            getDataCallback={() =>
                                getTagCategories(props.tagCategoryParams)
                            }
                            onChangeCallback={(event) => {
                                if (tagCategoryId !== event.selectedItem?.id) {
                                    setTagCategoryId(event.selectedItem?.id);

                                    if (!_.isEmpty(tagCategoryId)) {
                                        debounce(
                                            updateTagTimeoutId,
                                            () => {
                                                tagsRef.current?.updateSelectedItem(
                                                    {}
                                                );
                                            },
                                            {
                                                trailing: true,
                                                delay: 300,
                                            }
                                        );
                                    }
                                }
                            }}
                        />
                    </EdsFormColumn>
                    <EdsFormColumn>
                        <EdsDropdown
                            ref={tagsRef}
                            name="tagId"
                            label={t('e4d23e841d8e8804190027bce3180fa5', 'Tag')}
                            type={EdsDropdownType.ComboBox}
                            disabledOnEmpty={true}
                            onChangeCallback={(event) => {
                                if (event?.name === 'tagId') {
                                    const freeText =
                                        event?.selectedItem?.freeText ?? false;
                                    setFreeTextAllowed(freeText);
                                }
                            }}
                        />
                    </EdsFormColumn>
                </Row>

                <Row>
                    <EdsFormColumn fullWidth>
                        <EdsDatePicker
                            type={EdsDatePickerType.DateRange}
                            fromName="validFrom"
                            fromLabel={t(
                                '167b2d7828511b4bdbb6d642029bdaff',
                                'Valid from'
                            )}
                            toName="validTo"
                            toLabel={t(
                                '6e1f5c87ac9e0d969abb679a0603b54d',
                                'Valid to'
                            )}
                        />
                    </EdsFormColumn>
                </Row>

                {freeTextAllowed && (
                    <Row>
                        <EdsFormColumn fullWidth>
                            <EdsTextArea
                                name="freeText"
                                label={t(
                                    '1cb251ec0d568de6a929b520c4aed8d1',
                                    'Text'
                                )}
                                rows={10}
                            ></EdsTextArea>
                        </EdsFormColumn>
                    </Row>
                )}
            </EdsFormGroup>
        </EdsForm>
    );
});
UmoTagsForm.displayName = 'TagsForm';

export default UmoTagsForm;
