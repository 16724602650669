import axios from '../utils/axios';
import { memo, useEffect } from 'react';
import _ from 'lodash';
import { useParams, useRouteLoaderData } from 'react-router-dom';
import { getLogger } from '../utils';

const logger = getLogger('AccountIdApiInterceptor');

export const interceptorFunction = (params) => (config) => {
    logger.log('call', config.url, params);
    return {
        ...config,
        headers: {
            'x-umo-account-id': params.accountId,
            ...config.headers,
        },
    };
};

export const AccountIdApiInterceptor = memo(({ children }) => {
    const params = useParams();
    const accountLoaderInterceptor = useRouteLoaderData('account/id');
    logger.log('accountLoaderInterceptor', accountLoaderInterceptor);

    useEffect(() => {
        if (!_.isUndefined(params.accountId)) {
            logger.log('set new interceptor', params);
            // remove the initial setup interceptor from the account/id loader
            if (!_.isUndefined(accountLoaderInterceptor)) {
                axios.interceptors.request.eject(accountLoaderInterceptor);
            }

            const requestInterceptor = axios.interceptors.request.use(
                interceptorFunction(params)
            );

            return () => {
                logger.log('remove interceptor', params);
                axios.interceptors.request.eject(requestInterceptor);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.accountId]);

    return children;
});
AccountIdApiInterceptor.displayName = 'AccountIdApiInterceptor';
