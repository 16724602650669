import { Help, Logout, Settings } from '@carbon/icons-react';
import {
    Content,
    Header,
    HeaderGlobalAction,
    HeaderGlobalBar,
    HeaderMenuButton,
    HeaderMenuItem,
    HeaderName,
    HeaderNavigation,
} from '@carbon/react';
import enovationLogo from '../../../assets/img/enovation.png';
import umoLogo from '../../../assets/img/umo.png';
import './eds-ui-shell.scss';
import {
    EdsNotificationsWrapper,
    EdsModalWrapper,
    EdsOrganizationSwitcher,
    EdsSideNav,
} from '../';
import { useEffect, useState } from 'react';
import {
    useAuth,
    useChannels,
    useNotifications,
    useModal,
    useNavigate,
} from '../../../features';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';

export function EdsUiShell({ children }) {
    const [isSideNavExpanded, setIsSideNavExpanded] = useState(true);
    const { logout, attributes, accountId, selectAccount } = useAuth();
    const { openSocket } = useChannels();
    const navigate = useNavigate();
    const { showInfo } = useNotifications();
    const { showIdleModal } = useModal();
    const { t } = useTranslation();
    const isSmallScreen = useIsSmallScreen(1054); //based on pixels that carbon uses to show/hide the sidebar toggle. eq around 66rem
    const location = useLocation();

    useEffect(() => {
        openSocket();
    }, [openSocket]);

    useEffect(() => {
        toggleSideNavExpandedIfNeeded();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useIdleTimer({
        onPrompt: () => {
            showIdleModal();
        },
        onIdle: () => {
            logout();
        },
        /*eslint no-undef: "off"*/
        timeout: idle_timeout,
        promptBeforeIdle: prompt_before_idle,
        throttle: throttle_idle,
    });

    const toggleSideNavExpanded = () => {
        setIsSideNavExpanded(!isSideNavExpanded);
    };

    const toggleSideNavExpandedIfNeeded = () => {
        //only when isSmallScreen
        if (isSmallScreen && isSideNavExpanded) {
            setIsSideNavExpanded(!isSideNavExpanded);
        }
    };

    useEffect(() => {
        setIsSideNavExpanded(!isSmallScreen);
    }, [isSmallScreen]);

    return (
        <div className="ui-shell-container">
            <Header
                aria-label={t('099fb995346f31c749f6e40db0f395e3', 'Header')}
                className="ui-shell-header"
            >
                <HeaderMenuButton
                    isActive={isSideNavExpanded}
                    aria-label={t(
                        '0ce85727152c0c7a54f5001e39a1b339',
                        'Header navigation'
                    )}
                    onClick={toggleSideNavExpanded}
                ></HeaderMenuButton>
                <HeaderName href="#" prefix="">
                    <img src={enovationLogo} id="enovation-logo" />
                    <img
                        src={umoLogo}
                        id="platform-logo"
                        alt={t('573f227afd00058e9cfd6e501c8cc29e', 'UMO logo')}
                    />
                </HeaderName>
                <HeaderNavigation
                    aria-label={t(
                        '0ce85727152c0c7a54f5001e39a1b339',
                        'Header navigation'
                    )}
                >
                    <HeaderMenuItem href="#">
                        {t('250e61c2451f36599839c80067fdcaf9', 'Agent Desktop')}
                    </HeaderMenuItem>
                    <HeaderMenuItem href="#" isActive={true}>
                        {t('95ffdefa64d418cd17ee38c3f467917e', 'CRM Search')}
                    </HeaderMenuItem>
                </HeaderNavigation>

                <HeaderGlobalBar>
                    <EdsOrganizationSwitcher
                        organizations={attributes?.accounts || []}
                        initialSelectedItem={
                            attributes?.accounts &&
                            attributes?.accounts?.find(
                                (item) => `${item.id}` === accountId
                            )
                        }
                        onChange={(e) => {
                            if (e?.selectedItem?.id) {
                                selectAccount(e.selectedItem.id);
                                showInfo({
                                    title: t(
                                        '4024503a04abfd12238b814aac13903e',
                                        'Account switched'
                                    ),
                                    content: t(
                                        '58befd7b18a12147bc7fa84a6a9ba7f4',
                                        'Account has been switched to {{accountName}}',
                                        { accountName: e.selectedItem.name }
                                    ),
                                    ttl: 2000,
                                });
                                navigate(`/account/${e.selectedItem.id}`);
                            }
                        }}
                    ></EdsOrganizationSwitcher>
                    <HeaderGlobalAction
                        aria-label={t(
                            '657f8b8da628ef83cf69101b6817150a',
                            'Help'
                        )}
                        onClick={() => {}}
                        tooltipAlignment="end"
                        as={NavLink}
                        to="https://docs.enovation.io"
                        target="_blank"
                    >
                        <Help size={20} />
                    </HeaderGlobalAction>
                    <HeaderGlobalAction
                        aria-label={t(
                            '2e5d8aa3dfa8ef34ca5131d20f9dad51',
                            'Settings'
                        )}
                        onClick={() => {}}
                        tooltipAlignment="end"
                    >
                        <Settings size={20} />
                    </HeaderGlobalAction>
                    <HeaderGlobalAction
                        aria-label={t(
                            '4236a440a662cc8253d7536e5aa17942',
                            'Logout'
                        )}
                        onClick={() => {
                            logout();
                        }}
                        tooltipAlignment="end"
                    >
                        <Logout size={20} />
                    </HeaderGlobalAction>
                </HeaderGlobalBar>
            </Header>

            <EdsSideNav
                expanded={isSideNavExpanded}
                onOverlayClick={toggleSideNavExpandedIfNeeded}
                /*eslint no-undef: "off"*/
                version={umo_version}
                isRail={false}
            ></EdsSideNav>

            <div className="content-wrap">
                <Content>{children}</Content>
                <div className="content-footer"></div>
            </div>

            <EdsNotificationsWrapper />
            <EdsModalWrapper />
        </div>
    );
}

export default function useIsSmallScreen(threshold) {
    const [isSmallScreen, setIsSmallScreen] = useState(
        window.innerWidth <= threshold
    );

    useEffect(() => {
        const onResize = () => {
            setIsSmallScreen(window.innerWidth <= threshold);
        };

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [threshold]);

    return isSmallScreen;
}
