import { redirect } from 'react-router-dom';
import { getPeripheral } from '../../../../features';

export const pageLoader = async ({ params }) => {
    try {
        return await getPeripheral(params.peripheralId);
    } catch (ex) {
        return redirect('..');
    }
};
