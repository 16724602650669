export * from './form-validation';
export * from './logger';
import _ from 'lodash';
import { DateTime } from 'luxon';

/*eslint no-undef: "off"*/
export const isDevMode =
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const defaultPageSize = 10;
export const availablePageSizes = [10, 20, 30, 40, 50];

export const consumerStates = Object.freeze({
    ContractRequest: 'ContractRequest',
    Started: 'Started',
    Ended: 'Ended',
});

export const genders = Object.freeze({
    Unknown: 'Unknown',
    Male: 'Male',
    Female: 'Female',
});

export const getGenderLabel = (t, value) => {
    if (value === 'male') {
        return t('07cf4f8f5d8b76282917320715dda2ad', 'Male');
    } else if (value === 'female') {
        return t('273b9ae535de53399c86a9b83148a8ed', 'Female');
    } else {
        return t('5da248ea6840aca1ae2b417b17982a89', 'Neutral');
    }
};

export const getAlarmStatusLabel = (t, value) => {
    if (value === 'received') {
        return t('c5946eb9400717fd6f40e26e36cdb498', 'Received');
    } else if (value === 'active') {
        return t('c76a5e84e4bdee527e274ea30c680d79', 'Active');
    } else if (value === 'followup') {
        return t('ff853246d679191f35c7c966204d0ec9', 'Follow-up');
    } else if (value === 'closed') {
        return t('349e686330723975502e9ef4f939a5ac', 'Closed');
    } else {
        return '-';
    }
};

export const addLeadingZeros = (value, length) => {
    let string = value.toString();
    let needZeros = length - string.length;
    if (needZeros > 0) {
        for (let i = 0; i < needZeros; i++) {
            string = '0' + string;
        }
    }
    return string;
};

export const getDeviceRange = (deviceCode, numberOfDevices) => {
    if (
        !_.isString(deviceCode) ||
        !_.isNumber(numberOfDevices) ||
        _.isNaN(numberOfDevices)
    ) {
        return null;
    }

    let deviceRangeNumberRegex = /(\d+)$/;
    let result = deviceRangeNumberRegex.exec(deviceCode);
    if (!_.isArray(result) || result.length < 2) {
        return null;
    }
    let numberString = result[1];

    let start = parseInt(numberString);
    let end = start + (numberOfDevices - 1);

    let prefix = deviceCode.replace(deviceRangeNumberRegex, '');
    let first = prefix + addLeadingZeros(start, numberString.length);
    let last = prefix + addLeadingZeros(end, numberString.length);
    return { prefix, numberString, start, end, first, last };
};

// Creates a debounced function that can invoke a function leading subsequent
// calls, or trailing subsequent calls.
//
// Setting option.trailing = false (default) creates a debounced function that
// invokes the func immediately and ignores subsequent calls for options.wait
// milliseconds.
//
// Setting option.trailing = true creates a debounced function that delays
// invoking func until after options.wait milliseconds have elapsed since the
// last time the debounce function was invoked.
//
// The items in the options.params array are added as parameters when invoking
// func.
//
// The parameter timeoutIdRef is a useRef React Hook storing the previous ID
// generarated by the setTimeout() call.
export const debounce = (timeoutIdRef, func, options) => {
    // default options values
    let trailing = false;
    let delay = 500;
    let params = [];

    if (options != null && _.isPlainObject(options)) {
        trailing = options?.trailing || trailing;
        delay = options?.delay || delay;
        params = options?.params || params;
    }

    if (trailing) {
        debounceTrailing(timeoutIdRef, func, delay, params);
    } else {
        debounceLeading(timeoutIdRef, func, delay, params);
    }
};

// Creates a debounced function that delays invoking func until after wait
// milliseconds have elapsed since the last time the debounce function was
// invoked.
const debounceTrailing = (timeoutIdRef, func, delay, params) => {
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(
        async () => {
            func(...params);
        },
        delay,
        func,
        params
    );
};

// Creates a debounced function that invokes the func immediately and ignores
// subsequent calls for timeout milliseconds.
const debounceLeading = (timeoutIdRef, func, timeout, params) => {
    if (_.isNull(timeoutIdRef.current)) {
        func(...params);
    }

    clearTimeout(timeoutIdRef.current);
    timeoutIdRef.current = setTimeout(
        () => {
            timeoutIdRef.current = null;
        },
        timeout,
        timeoutIdRef
    );
};

const setEmptyFieldToValue = (obj, value, removeKeys = []) => {
    return Object.keys(obj).reduce((acc, key) => {
        if (!removeKeys.includes(key)) {
            acc[key] = obj[key] === '' ? value : obj[key];
            if (_.isPlainObject(obj[key])) {
                acc[key] = setEmptyFieldToValue(obj[key], value, removeKeys);
            }
        }
        return acc;
    }, {});
};

export const setEmptyFieldToNull = (obj, removeKeys = []) => {
    return setEmptyFieldToValue(obj, null, removeKeys);
};

export const setEmptyFieldToUndefined = (obj) => {
    return setEmptyFieldToValue(obj, undefined);
};

const flattenObjectAndRemoveEmptyValues = (obj) => {
    return _.compact(
        _.flatMapDeep(obj, (value) => {
            if (_.isPlainObject(value)) {
                return flattenObjectAndRemoveEmptyValues(value);
            }
            return !_.isNil(value) ? value : [];
        })
    ).sort();
};

export const areObjectValuesEqual = (object1, object2) => {
    const flatObject1 = flattenObjectAndRemoveEmptyValues(object1);
    const flatObject2 = flattenObjectAndRemoveEmptyValues(object2);
    return _.isEqual(flatObject1, flatObject2);
};

export const getDate = (options = {}) => {
    const {
        plusDays = 0,
        minusDays = 0,
        plusYears = 0,
        minusYears = 0,
    } = options;

    return DateTime.now()
        .plus({ days: plusDays, years: plusYears })
        .minus({ days: minusDays, years: minusYears })
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toJSDate();
};

export const toJSDate = (date, allowEmpty = false) => {
    let dateTimeObject;
    if (date instanceof Date) {
        dateTimeObject = DateTime.fromJSDate(date);
    } else if (!_.isObject(date)) {
        dateTimeObject = DateTime.fromISO(date);
    }
    if (dateTimeObject && dateTimeObject.isValid) {
        return dateTimeObject.toJSDate();
    }
    return allowEmpty ? '' : getDate();
};

export const isValidDate = (date) => {
    return _.isDate(toJSDate(date, true));
};

export const defaultDropdownMappingCallback = (data) => {
    if (_.isUndefined(data)) {
        return {};
    }
    return { id: data.id, text: data.name };
};

export const estimatedRemainingUploadTime = (startTime, uploaded, fileSize) => {
    const currentTime = new Date().valueOf();
    const uploadTime = currentTime - startTime;

    let leftToUpload = fileSize - uploaded;
    if (leftToUpload < 0) {
        leftToUpload = 0;
    }

    let remainingTimeMs = (uploadTime / uploaded) * leftToUpload;
    return formatDuration(remainingTimeMs);
};

export const formatDuration = (ms) => {
    if (ms < 0) {
        ms = 0;
    }
    const time = {
        day: Math.floor(ms / 86400000),
        hour: Math.floor(ms / 3600000) % 24,
        minute: Math.floor(ms / 60000) % 60,
        second: Math.floor(ms / 1000) % 60,
    };
    return Object.entries(time)
        .filter((val) => val[1] !== 0)
        .map(
            (val) =>
                val[1] + ' {{' + (val[1] !== 1 ? val[0] + 's' : val[0]) + '}}'
        )
        .join(', ');
};

export function flattenObjectWithDivider(
    ob,
    prefix = false,
    result = null,
    divider = '/'
) {
    result = result || {};

    // Preserve empty objects and arrays, they are lost otherwise
    if (prefix && _.isObject(ob) && !_.isNull(ob) && _.isEmpty(ob)) {
        result[prefix] = _.isArray(ob) ? [] : {};
        return result;
    }

    prefix = prefix ? prefix + divider : '';

    for (const i in ob) {
        if (Object.prototype.hasOwnProperty.call(ob, i)) {
            // Only recurse on true objects and arrays, ignore custom classes like dates
            if (_.isObject(ob[i]) && !_.isNull(ob[i])) {
                // Recursion on deeper objects
                flattenObjectWithDivider(ob[i], prefix + i, result);
            } else {
                result[prefix + i] = ob[i];
            }
        }
    }
    return result;
}

export const getLink = (to, params) => {
    if (
        _.startsWith(to, '/') &&
        !_.startsWith(to, '/auth') &&
        !_.startsWith(to, '/account') &&
        !_.isUndefined(params.accountId)
    ) {
        return `/account/${params.accountId}${to}`;
    }
    return to;
};
