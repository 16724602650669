import { redirect } from 'react-router-dom';
import { getGroup } from '../../../../../features';

export const pageLoader = async ({ params }) => {
    try {
        return await getGroup(params.groupId);
    } catch (ex) {
        return redirect('..');
    }
};
