import { useTranslation } from 'react-i18next';
import { EdsContainer, EdsDataGrid } from '../../eds';
import { getDeviceByCode, getLogger, useModal } from '../../../features';
import { useRef } from 'react';
import UmoDefaultDeviceForm from './umo-default-device-form';
import _ from 'lodash';

let logger = getLogger('UmoDefaultDisplayedDeviceCode');

const UmoDefaultDisplayedDeviceCode = ({
    defaultDisplayedDeviceCode,
    hasDevices,
    getDevicesCallback,
    postDefaultDeviceCallback,
    onSubmitCallback,
}) => {
    const { t } = useTranslation();
    const { showFormModal } = useModal();
    const formRef = useRef(null);

    const editDefaultDevice = async () => {
        try {
            let defaultDevice = null;
            if (defaultDisplayedDeviceCode) {
                defaultDevice = await getDeviceByCode(
                    defaultDisplayedDeviceCode
                );
            }

            showDataModal({
                deviceId: {
                    id: defaultDevice?.id,
                    text: defaultDevice?.deviceCode,
                },
            });
        } catch (ex) {
            logger.warn('getDeviceByCode UDS Exception:', ex);
        }
    };

    const showDataModal = (defaultDeviceData = {}) => {
        showFormModal({
            newTitle: t(
                'a2f5f272265576aa5170d4580aa48564',
                'Change default device'
            ),
            formData: defaultDeviceData,
            formRef,
            onSubmitFormResponse: async (response, removeModal) => {
                if (response) {
                    if (_.isFunction(onSubmitCallback)) {
                        onSubmitCallback(response);
                    }
                }
                removeModal();
            },
            children: (
                <UmoDefaultDeviceForm
                    ref={formRef}
                    initValues={defaultDeviceData}
                    getDataCallback={getDevicesCallback}
                    postDataCallback={postDefaultDeviceCallback}
                />
            ),
        });
    };

    return (
        <EdsContainer
            title={t('6c4c3c1e466c26dca47ded5d993858ea', 'Default device')}
            onHeaderEditClick={hasDevices ? () => editDefaultDevice() : null}
        >
            <EdsDataGrid
                gridData={{
                    styles: {
                        left: {
                            key: {
                                width: '400px',
                            },
                        },
                    },
                    rows: {
                        left: [
                            {
                                key: t(
                                    '57d50ee4873d42049cc021a5df38b750',
                                    'Device code'
                                ),
                                value: defaultDisplayedDeviceCode
                                    ? defaultDisplayedDeviceCode
                                    : '-',
                            },
                        ],
                    },
                }}
            />
        </EdsContainer>
    );
};
UmoDefaultDisplayedDeviceCode.displayName = 'UmoDefaultDisplayedDeviceCode';
export default UmoDefaultDisplayedDeviceCode;
