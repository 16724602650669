import { useTranslation } from 'react-i18next';
import {
    getDefaultPhoneNumberType,
    getSelectablePhoneNumberTypes,
} from '../../../features';
import _ from 'lodash';
import { EdsDropdown, EdsDropdownType } from '../eds-dropdown/eds-dropdown';

export const EdsPhoneNumberTypeFormat = Object.freeze({
    E164: 'e164',
    Short: 'short',
    All: 'all',
});

const phoneNumberTypeMappingCallback = (data) => {
    let customName = data.name;
    return {
        id: data.id,
        text: customName,
        validateE164Format: data.validateE164Format,
        disabled: data.disabled ?? false,
    };
};

export const getAllFormatsDefaultPhoneNumberType = async (
    phoneTypeFormat = EdsPhoneNumberTypeFormat.All
) => {
    let requests = [];
    if (
        [EdsPhoneNumberTypeFormat.All, EdsPhoneNumberTypeFormat.E164].includes(
            phoneTypeFormat
        )
    ) {
        requests.push(getDefaultPhoneNumberType(true));
    }
    if (
        [EdsPhoneNumberTypeFormat.All, EdsPhoneNumberTypeFormat.Short].includes(
            phoneTypeFormat
        )
    ) {
        requests.push(getDefaultPhoneNumberType(false));
    }

    const phoneNumberTypes = await Promise.all(requests);
    const filteredTypes = phoneNumberTypes
        .filter((type) => !_.isEmpty(type))
        .flat();

    return phoneNumberTypeMappingCallback(filteredTypes[0] ?? {});
};

export function EdsPhoneNumberType({
    phoneTypeFormat = EdsPhoneNumberTypeFormat.All,
    ...props
}) {
    const { t } = useTranslation();

    const getAllPhoneNumberTypes = async () => {
        let requests = [];

        if (
            [
                EdsPhoneNumberTypeFormat.All,
                EdsPhoneNumberTypeFormat.E164,
            ].includes(phoneTypeFormat)
        ) {
            requests.push(getSelectablePhoneNumberTypes(true));
        }

        if (
            [
                EdsPhoneNumberTypeFormat.All,
                EdsPhoneNumberTypeFormat.Short,
            ].includes(phoneTypeFormat)
        ) {
            requests.push(getDivider());
            requests.push(getSelectablePhoneNumberTypes(false));
        }

        let phoneNumberTypes = await Promise.all(requests);
        if (_.isEmpty(_.last(phoneNumberTypes))) {
            //remove diver from result if lat result (shortNumbers) is empty
            phoneNumberTypes = _.head(phoneNumberTypes);
        }

        phoneNumberTypes = phoneNumberTypes.flat();
        return phoneNumberTypes;
    };

    const getDivider = async () => {
        return [
            {
                name: `--- ${t(
                    '753b2e0131a71cbbbb29a8a20ff0b7e0',
                    'Service numbers'
                )} ---`,
                disabled: true,
            },
        ];
    };

    return (
        <EdsDropdown
            name="phoneNumberTypeId"
            label={t('e1ef131918144ec7adeb7ed253dec2ea', 'Phone number type')}
            type={EdsDropdownType.ComboBox}
            mappingCallback={phoneNumberTypeMappingCallback}
            getDataCallback={getAllPhoneNumberTypes}
            {...props}
        />
    );
}
