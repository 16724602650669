import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatches, useParams } from 'react-router-dom';
import { useNavigate as useNavigateNative } from 'react-router-dom';
import { getLink } from '../utils';

/**
 * useEffect hook to only fire on updates after component mount
 *
 * @param {function} func Function body of the useEffect hook
 * @param {array} deps dependencies to listen on
 */
export const useEffectOnUpdate = (func, deps) => {
    const mounted = useRef(false);
    useEffect(() => {
        if (mounted.current) {
            func();
        } else {
            mounted.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};

/**
 * useEffect hook to only fire on mount of component
 *
 * @param {function} func Function body of the useEffect hook
 */
export const useEffectOnMount = (func) => {
    const mounted = useRef(false);
    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            func();
        }
    }, [func]);
};

/**
 *  Hook to fire when docment title changes based on the current route
 *
 * @param {string} title page title to add to the document title
 */
export function useDocumentTitle(title) {
    const { t } = useTranslation();
    const matches = useMatches();
    const [documentTitle, setDocumentTitle] = useState('');

    useEffect(() => {
        let titles = getSubTitles();

        if (!_.isUndefined(title)) {
            titles.push(title);
        }

        titles.push(
            t('9817dc02b06ab3ce8ee7c573a43e3a74', 'UMOcx Agent Portal')
        );
        setDocumentTitle(titles.join(' - '));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matches]);

    const getSubTitles = () => {
        const subTitles = matches
            .filter((match) => {
                return Boolean(match?.handle?.crumb);
            })
            .toReversed()
            .map((match) => {
                return {
                    title: match.handle.crumb(t, match.data),
                    documentTitleParent:
                        match?.handle?.documentTitleParent || false,
                };
            });

        const parentIndex = subTitles.findIndex((item) => {
            return item.documentTitleParent;
        });

        return subTitles.slice(0, parentIndex).map((item) => {
            return item.title;
        });
    };

    return documentTitle;
}

export const useNavigate = () => {
    const navigate = useNavigateNative();
    const params = useParams();

    return (to, ...other) => {
        return navigate(getLink(to, params), ...other);
    };
};
