import { Tooltip } from '@carbon/react';
import './eds-tooltip.scss';
import { Information } from '@carbon/icons-react';

export function EdsTooltip({ align, label, children, ...props }) {
    return (
        <Tooltip
            className="eds-tooltip eds-tooltip-icon"
            align={align}
            label={label}
            {...props}
        >
            <button className="eds-tooltip-trigger" type="button">
                {children}
            </button>
        </Tooltip>
    );
}

export function EdsTooltipIcon(props) {
    return (
        <EdsTooltip {...props}>
            <Information />
        </EdsTooltip>
    );
}

export function EdsTooltipCheckbox(props) {
    const { align, hidden, label, children } = props;

    if (hidden) {
        return children;
    }

    return (
        <div className="eds-tooltip eds-tooltip-checkbox">
            {children}
            <EdsTooltipIcon align={align} label={label}></EdsTooltipIcon>
        </div>
    );
}
