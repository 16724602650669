import { CheckmarkFilled } from '@carbon/icons-react';
import './eds-table-checkmark.scss';
import { EdsTooltip } from '../../';
import { useTranslation } from 'react-i18next';

export function EdsTableCheckmark(props) {
    const { t } = useTranslation();

    if (!props.value) {
        return null;
    }

    return (
        <EdsTooltip label={t('a6105c0a611b41b08f1209506350279e', 'Yes')}>
            <CheckmarkFilled
                className="eds-table-checkmark eds-success"
                size="20"
            />
        </EdsTooltip>
    );
}
